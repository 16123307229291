import React, { useCallback, useEffect, useRef } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, CloseButton } from "reactstrap";
import { makeGetImageRequest } from "../services/mediaService";

export default function ImageViewModal({ isOpen, imageUrl, onModalCloseClick }) {
  const imgRef = useRef();

  const fetchData = useCallback(async (url) => {
    const base64 = await makeGetImageRequest(url);
    imgRef.current.src = base64;
  }, []);

  useEffect(() => {
    if (isOpen) fetchData(imageUrl);
  }, [isOpen, imageUrl]);

  return (
    <Modal zIndex={10050} isOpen={isOpen} size='lg'>
      <ModalHeader
        close={
          <CloseButton
            onClick={() => {
              if (onModalCloseClick) {
                onModalCloseClick(false);
              }
            }}
          />
        }
      ></ModalHeader>
      <ModalBody style={{display: "flex", justifyContent: 'center'}}>
        <img ref={imgRef} alt='Current' />
      </ModalBody>
          <ModalFooter>
              <button className="btn btn-primary" onClick={() => onModalCloseClick(true)}>
                  Update
              </button>
              <button className="btn btn-danger" onClick={() => onModalCloseClick(false)}>
                  Close
              </button>
      </ModalFooter>
    </Modal>
  );
}
