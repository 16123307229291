import React, { useReducer, useCallback } from "react";
import basketStore, { initialState } from "./basketStore";
import { useUserStore } from "../user/userContext";
import {
  makeGetBasketWithOptionsRequest,
  makeUpdateQuantityRequest,
  makeDeleteBasketItemRequest,
    makeGetBasketRequest,
    makeSaveBasketItemRequest
} from "../../services/basketService";
import { toJSON } from "../../utility/convert";
import { toast } from "react-toastify";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import * as googleEvents from "../../utility/googleEvents";
import { showLoading, hideLoading } from "../loading/loadingActions";
import { useLoadingStore } from "../loading/loadingContext";
import { useElementStore } from "../element/elementContext";
import useMessenger from "../../components/Messenger";
import { addProductToBasketAnnonymousAction, updateProductAnnonymousAction } from "./basketActions";

export const BasketContext = React.createContext();

export const useBasketStore = () => React.useContext(BasketContext);

export const useBasketService = () => {
  const [{ user }] = useUserStore();
  const [{ selectedShipmentOptionId, promotionCode }, basketDispatch] =
    useBasketStore();
  const { t } = useTranslation();
    const [, loadingDispatch] = useLoadingStore();
    const [{ loginRef }] = useElementStore();
    const { showAddedToBasketMessage } = useMessenger();

  const updateQuantity = useCallback(
    async (item, quantity) => {
      if (user) {
        if (quantity > 0) {
          await updateQuantityUser(item, quantity);
        } else {
          await deleteBasketItemUser(item);
        }
      } else {
        if (quantity > 0) {
          await updateQuantityAnonymous(item, quantity);
        } else {
          await deleteBasketItemAnonymous(item);
        }
      }
    },
    [user, selectedShipmentOptionId, promotionCode]
  );

  const updateQuantityUser = useCallback(
    async (item, quantity) => {
      await makeUpdateQuantityRequest(
        basketDispatch,
        item.id,
        quantity,
        selectedShipmentOptionId,
        promotionCode
      );
    },
    [selectedShipmentOptionId, promotionCode]
  );

  const updateQuantityAnonymous = useCallback(
    async (item, quantity) => {
      const basketLocalStr = localStorage.getItem("basket");
      const basketLocal = toJSON(basketLocalStr, []);
      const basketItem = basketLocal.find((x) => x.id === item.id && x.sizeId == item.sizeId);
      basketItem.quantity = quantity;
      const basketParamStr = JSON.stringify(basketLocal);
      localStorage.setItem("basket", basketParamStr);
      await makeGetBasketWithOptionsRequest(
        basketDispatch,
        selectedShipmentOptionId,
        promotionCode,
        basketLocal
      );
    },
    [selectedShipmentOptionId, promotionCode]
  );

  const deleteBasketItem = useCallback(
    async (item) => {
      if (user) {
        await deleteBasketItemUser(item);
      } else {
        await deleteBasketItemAnonymous(item);
      }
      googleEvents.removeFromCart([item]);
    },
    [user, selectedShipmentOptionId, promotionCode]
  );

  const deleteBasketItemUser = useCallback(
    async (item) => {
      const response = await makeDeleteBasketItemRequest(
        basketDispatch,
        item.id,
        selectedShipmentOptionId,
        promotionCode
      );
      if (response?.success) toast.success(t("Removed successfully"));
    },
    [selectedShipmentOptionId, promotionCode]
  );

  const deleteBasketItemAnonymous = useCallback(
    async (item) => {
      try {
        const basketLocal = getBasketLocal();
        _.remove(basketLocal, (x) => x.id === item.id && x.sizeId == item.sizeId);
        const basketParamStr = JSON.stringify(basketLocal);
        localStorage.setItem("basket", basketParamStr);
        const response = await makeGetBasketWithOptionsRequest(
          basketDispatch,
          selectedShipmentOptionId,
          promotionCode,
          basketLocal
        );
        if (response?.success) toast.success(t("Removed successfully"));
      } catch (error) {
        console.error(error);
        toast.error(t("An error occurred"));
      }
    },
    [selectedShipmentOptionId, promotionCode]
  );

  const initializeBasket = useCallback(async () => {
    let strItems = null;
    if (!user) {
      const basketLocal = getBasketLocal();
      strItems = JSON.stringify(basketLocal);
    }
    const response = await makeGetBasketRequest(basketDispatch, strItems);

    return response;
  }, [user]);

  const getBasketLocal = useCallback(() => {
    const basketLocalStr = localStorage.getItem("basket");
    const basketLocal = toJSON(basketLocalStr, []).filter((x) => x.id != null);

    return basketLocal;
  }, []);

  const addToBasket = useCallback(
    async (product, uploadImageDictionary = null) => {
      loadingDispatch(showLoading());
      try {
        if (user || uploadImageDictionary?.length > 0) {
          await addToBasketUser(product, uploadImageDictionary);
        } else {
          addToBasketAnnonymous(product);
        }
      } finally {
        loadingDispatch(hideLoading());
        googleEvents.addToCart(product, 1);
      }
    },
    [user]
  );

  const addToBasketUser = useCallback(
    async (product, uploadImageDictionary) => {
      if (!user) {
        loginRef?.current?.open(`/product/${product.urlName}`);
        return;
      }
      const quantity = 1;

      const formData = new FormData();
      formData.append(
        "request",
        JSON.stringify({
          productId: product.id,
          variantId: product.variantId,
          sizeId: product.sizeId,
          quantity,
        })
      );

      for (const [key, value] of Object.entries(uploadImageDictionary ?? {})) {
        formData.append("files", value, key + ".png");
      }

      const response = await makeSaveBasketItemRequest(
        basketDispatch,
        formData
      );
      if (response?.success) {
        showAddedToBasketMessage();
      }
    },
    [user]
  );

  const addToBasketAnnonymous = useCallback((product) => {
    const quantity = 1;
    const basket = getBasketLocal();
    let basketItem = basket.find((x) =>
      (product.variantId ? x.id === product.variantId : x.id === product.id) && x.sizeId === product.sizeId
    );
    if (basketItem) {
      basketItem.quantity += quantity;

      if (basketItem.quantity > product.maxQuantity) {
        toast.warn(
          "Du kan inte lägga till fler än max antal produkter i varukorgen"
        );
        return;
      }

      basketDispatch(updateProductAnnonymousAction({ basketItem, quantity }));
    } else {
      basketItem = {
        id: product.variantId ?? product.id,
        productId: product.id,
        variantId: product.variantId,
        quantity,
        uploadImages: null,
        sizeId: product.sizeId,
      };
      basket.push(basketItem);

      basketDispatch(
        addProductToBasketAnnonymousAction({ basketItem, quantity })
      );
    }

    localStorage.setItem("basket", JSON.stringify(basket));
    showAddedToBasketMessage();
  }, []);

  return {
    updateQuantity,
    deleteBasketItem,
    initializeBasket,
    addToBasket,
  };
};

export default function BasketProvider(props) {
  const [state, dispatch] = useReducer(basketStore, initialState);
  return (
    <BasketContext.Provider value={[state, dispatch]}>
      {props.children}
    </BasketContext.Provider>
  );
}
