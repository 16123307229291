import { makeApiRequest } from "../api";

export const makeSearchRequest = async (keyword) => {
  try {
    const response = await makeApiRequest(
      `/search?q=${keyword}`,
      "GET",
      null,
      false
    );

    return response;
  } catch (e) {
    return e;
  }
};
