import React, { Suspense, lazy, useEffect } from "react";
import ProductSlider from "./ProductSlider";
import "react-multi-carousel/lib/styles.css";
import { makeGetProductsRequest } from "../services/productService";
import { useProductStore } from "../store/product/productContext";
import _ from "lodash";
import { BrowserView, MobileView } from "react-device-detect";
import MainCategoriesWithImage from "./MainCategoriesWithImage";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";

const Home = () => {
  const [, productDispatch] = useProductStore();
  const CarouselWrapper = lazy(() => import("./CarouselWrapper"));

  useEffect(() => {
    async function fetchData() {
      await makeGetProductsRequest(productDispatch);
    }

    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Buket Present</title>
      </Helmet>
      <div className='pb-3'>
        <BrowserView>
          <div className='row'>
            <div className='col-12'>
              <ProductSlider />
            </div>
          </div>
        </BrowserView>

        <div className='row mt-3 mt-lg-2'>
          <div className='col-12'>
            <MainCategoriesWithImage />
          </div>
        </div>

        <BrowserView>
          <div className='row mt-2 mb-4'>
            <div className='col-12'>
              <img
                className='w-100'
                src={require(`../images/banner_bottom_sv.gif`)}
                alt='Advice'
              />
            </div>
          </div>
        </BrowserView>
        <MobileView>
          <div className='row mt-2 mb-4'>
            <div className='col-12'>
              <img
                className='w-100'
                src={require(`../images/banner_bottom_mobile_sv.gif`)}
                alt='Advice'
              />
            </div>
          </div>
        </MobileView>
        <Suspense fallback={<Skeleton height={400} />}>
          <CarouselWrapper />
        </Suspense>
      </div>
    </>
  );
}

export default React.memo(Home);