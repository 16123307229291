import React, {
  useLayoutEffect,
  useCallback,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import { Helmet } from "react-helmet";
import { useUserStore } from "../../store/user/userContext";
import { useBasketStore } from "../../store/basket/basketContext";
import { makeCreateKlarnaSessionRequest, makeUpdateKlarnaSessionRequest } from "../../services/klarnaService";
import {
  makeCreateKlarnaOrderRequest,
  makeUpdateKlarnaOrderRequest,
} from "../../services/klarnaService";


const Klarna = ({ orderDataSource, onCancel }, ref) => {
  const [{ user }] = useUserStore();
  const [{ basket }, basketDispatch] = useBasketStore();
  const [sessionId, setSessionId] = useState(null);


  const createSession = useCallback(async () => {
    let basketItems = null;
    if (!user) {
      basketItems = basket;
    }

    const klarnaSession = await makeCreateKlarnaSessionRequest({
      ...orderDataSource,
      basketItems
    });

    if (klarnaSession?.success) {
      setSessionId(klarnaSession.sessionId);
      return klarnaSession;
    } else {
      return null;
    }
  }, [basket, user]);

  const createOrder = useCallback(async () => {
    window.Klarna.Payments.authorize({}, {}, async res => {
      if (res.approved === false) {
        onCancel();
        return;
      }
      const klarnaOrder = await makeCreateKlarnaOrderRequest(basketDispatch, {
        ...orderDataSource,
        basketItems: basket,
        authorizationToken: res.authorization_token,
      });
      window.location.replace(klarnaOrder.redirectUrl);
    });
  }, [orderDataSource, basket]);


  const destroy = useCallback(() => {

  }, []);

  useImperativeHandle(ref, () => ({
    createSession: createSession,
    createOrder: createOrder,
    destroy: destroy,
  }));

  useEffect(() => {
    const initAsync = async () => {
      const session = await createSession();
      setTimeout(() => {
        window.klarnaAsyncCallback = async function () {


          window.Klarna.Payments.init({
            client_token: session.clientToken,
          });
  
          window.Klarna.Payments.load(
            {
              container: "#klarna-payments-container",
              payment_method_categories: session.paymentMethodCategories.map(
                (x) => x.identifier
              ),
              instance_id: "klarna-widget",
            },
            {},
            () => { }
          );
        };
  
        window.klarnaAsyncCallback();
      }, 1000);
    };
    initAsync();
  }, []);

  useEffect(() => {
    if (sessionId) {
      makeUpdateKlarnaSessionRequest(sessionId, {
        shipmentOptionId: orderDataSource.shipmentOptionId,
        promotionCode: orderDataSource.promotionCode,
        basketItems: basket,
      });
    }
  }, [basket, user]);

  return (
    <>
      <Helmet>
        <script src='https://x.klarnacdn.net/kp/lib/v1/api.js' async></script>
      </Helmet>
      <div id='klarna-payments-container'></div>
    </>
  );
};

export default React.memo(React.forwardRef(Klarna));
