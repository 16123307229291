/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import {
  useBasketService,
  useBasketStore,
} from "../store/basket/basketContext";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useUserStore } from "../store/user/userContext";
import { Helmet } from "react-helmet";
import { Row, Col, FormGroup, Input, Button } from "reactstrap";
import ShippingMethods from "./Payment/ShippingMethods";
import { useLoadingStore } from "../store/loading/loadingContext";
import { showLoading, hideLoading } from "../store/loading/loadingActions";
import { makeGetBasketWithOptionsRequest } from "../services/basketService";
import PaymentMethods from "./Payment/PaymentMethods";
import { clearBasketAction } from "../store/basket/basketActions";
import PaymentConfirm from "./Payment/PaymentConfirm";
import { beginCheckout } from "../utility/googleEvents";
import BasketItems from "./BasketItems";
import { MobileOnlyView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import UserAlerts from "./UserAlerts";

export default function Basket() {
  const [{ user }] = useUserStore();
  const [, loadingDispatch] = useLoadingStore();
  const [
    {
      basket,
      totalPrice,
      itemCount,
      messages,
      subtotalPrice,
      shipmentPrice,
      shipmentDiscountPrice,
      vat,
      selectedShipmentOptionId,
    },
    basketDispatch,
  ] = useBasketStore();
  const { initializeBasket } = useBasketService();
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [userNote, setUserNote] = useState(null);
  const [isSameAsBillingAddress] = useState(true);
  const [promotionCode, setPromotionCode] = useState(null);
  const [order, setOrder] = useState(null);
  const [isNoteVisible, setIsNoteVisible] = useState(false);
  const [selectedAddress] = useState(null);
  const [initializationComplete, setInitializationComplete] = useState(false);
  const orderDataSource = useMemo(
    () => ({
      addressId: selectedAddress?.id,
      note: userNote,
      shipmentOptionId: selectedShipmentOptionId,
      promotionCode: promotionCode,
      isDeliveryAddressSame: isSameAsBillingAddress,
    }),
    [
      selectedShipmentOptionId,
      selectedAddress,
      promotionCode,
      userNote,
      isSameAsBillingAddress,
    ]
  );

  const refPaymentContent = useRef();
  const refPaymentMethods = useRef();
  const navigate = useNavigate();

  const onUserNoteChangeHandler = useCallback((e) => {
    setUserNote(e.target.value);
  }, []);

  //#region Functions

  const onShipmentOptionSelectHandler = useCallback(
    async (shipmentOptionId) => {
      loadingDispatch(showLoading());
      try {
        await makeGetBasketWithOptionsRequest(
          basketDispatch,
          shipmentOptionId,
          promotionCode,
          basket
        );
      } finally {
        loadingDispatch(hideLoading());
      }
    },
    [promotionCode, basket, refPaymentContent]
  );

  const btnProceedIsEnabled = useMemo(
    () => selectedShipmentOptionId != null,
    [selectedShipmentOptionId]
  );

  const onApplyPromotionCodeClickHandler = useCallback(async () => {
    if (!promotionCode || promotionCode.length < 8) return;

    loadingDispatch(showLoading());
    try {
      const response = await makeGetBasketWithOptionsRequest(
        basketDispatch,
        selectedShipmentOptionId,
        promotionCode,
        basket
      );

      if (!response?.success) setPromotionCode("");
    } finally {
      loadingDispatch(hideLoading());
    }
  }, [promotionCode, selectedShipmentOptionId, basket]);

  const onPaymentSuccessHandler = useCallback((orderResponse) => {
    setOrder(orderResponse);
    basketDispatch(clearBasketAction());
    setStep(1);
  }, []);
  //#endregion Functions

  const paymentMethods = useMemo(() => {
    if (basket?.every((x) => !(x.hasError ?? true))) {
      return (
        <PaymentMethods
          ref={refPaymentMethods}
          orderDataSource={orderDataSource}
          onPaymentSuccess={onPaymentSuccessHandler}
        />
      );
    } else {
      return <></>;
    }
  }, [basket, orderDataSource]);

  useLayoutEffect(() => {
    async function fetchData() {
      const response = await initializeBasket();
      setInitializationComplete(true);

      if (response?.success && response.itemCount > 0) {
        beginCheckout(response.basket);
      }
    }
    fetchData();
  }, [user]);

  return (
    <>
      <Helmet>
        <title>Varukorg - Buket Present</title>
        <meta name='robots' content='noindex, nofollow' />
        <meta name='googlebot' content='noindex, nofollow' />
      </Helmet>
      {step === 0 && initializationComplete && (
        <div>
          <MobileOnlyView>
            <div style={{ marginTop: -100 }}></div>
          </MobileOnlyView>
          <section
            class='card h-100 pt-3 border-0'
            style={{
              backgroundColor: "rgb(251, 251, 251)",
              minHeight: "80vh",
              overflowX: "hidden",
              marginTop: -70,
            }}
          >
            <div class='container h-100' style={{ maxWidth: 800 }}>
              <div
                class='row d-flex justify-content-center align-items-center h-100'
                style={{ marginBottom: 80 }}
              >
                <UserAlerts />
                <div class='col-12 col-md-10 mb-5'>
                  <div class='d-flex justify-content-between align-items-center'>
                    <h3 class='fw-normal mb-0 text-black'>
                      I din varukorg just nu
                    </h3>
                  </div>

                  {basket?.length > 0 && (
                    <>
                      <BasketItems promotionCode={promotionCode} />
                      <div
                        style={{ maxWidth: 650 }}
                        className='basket-sub-container col-12 pt-2 pb-4'
                      >
                        <div id='basket-sub'>
                          <div className='w-100'>
                            <div id='total-price' className='row m-0'>
                              <div className='col'>
                                <table>
                                  <tr>
                                    <td className='px-3' width={"90%"}>
                                      <span className='fw-bold font-size-1-1'>{`${t(
                                        "Subtotal"
                                      )} (${itemCount} ${t(
                                        "item(s)"
                                      )}):`}</span>
                                    </td>
                                    <td width={"10%"}>
                                      <span className='text-nowrap  font-size-1-4 fw-bold my-0'>
                                        {totalPrice.toFixed(2)} kr
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className='px-3' width={"90%"}>
                                      <span>{t("Goods")}:</span>
                                    </td>
                                    <td width={"10%"}>
                                      <span>{subtotalPrice.toFixed(2)} kr</span>
                                    </td>
                                  </tr>
                                  {shipmentPrice && (
                                    <tr>
                                      <td className='px-3' width={"90%"}>
                                        <span>Leverans:</span>
                                      </td>
                                      <td width={"10%"}>
                                        <span>
                                          {shipmentPrice.toFixed(2)} kr
                                        </span>
                                      </td>
                                    </tr>
                                  )}

                                  {shipmentDiscountPrice && (
                                    <tr>
                                      <td className='px-3' width={"90%"}>
                                        <span className='text-color-alert'>
                                          Leverans Rabatt:
                                        </span>
                                      </td>
                                      <td width={"10%"}>
                                        <span className='text-color-alert'>
                                          -{shipmentDiscountPrice.toFixed(2)} kr
                                        </span>
                                      </td>
                                    </tr>
                                  )}

                                  <tr>
                                    <td className='px-3' width={"90%"}>
                                      <span>{t("VAT")} (%25):</span>
                                    </td>
                                    <td width={"10%"}>
                                      <span>{vat.toFixed(2)} kr</span>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Row>
                        <Col>
                          <FormGroup>
                            <h5>Presentmeddelande</h5>
                            {isNoteVisible ? (
                              <>
                                <Input
                                  id='txtNote'
                                  type='textarea'
                                  onChange={onUserNoteChangeHandler}
                                  value={userNote}
                                  maxLength={200}
                                  onBlur={() =>
                                    refPaymentMethods.current?.reload()
                                  }
                                />
                              </>
                            ) : (
                              <Row>
                                <FormGroup>
                                  <Input
                                    type='checkbox'
                                    onChange={() =>
                                      setIsNoteVisible(!isNoteVisible)
                                    }
                                  />{" "}
                                  Jag vill lägga till ett presentmeddelande
                                </FormGroup>
                              </Row>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      {btnProceedIsEnabled && (
                        <Row>
                          <Col>
                            <FormGroup>
                              <h5>Kampanjkod</h5>
                              {!shipmentDiscountPrice && (
                                <div className='row col-12 col-lg-6'>
                                  <div className='col-9'>
                                    <Input
                                      id='txtPromotionCode'
                                      type='text'
                                      onChange={(e) =>
                                        setPromotionCode(e.target.value)
                                      }
                                      maxLength={8}
                                      minLength={8}
                                      value={promotionCode}
                                    />
                                  </div>

                                  <div className='col-3'>
                                    <Button
                                      disabled={promotionCode?.length != 8}
                                      onClick={onApplyPromotionCodeClickHandler}
                                      className='btn-primary'
                                    >
                                      Tillämpa
                                    </Button>
                                  </div>
                                </div>
                              )}
                              {shipmentDiscountPrice &&
                                messages?.map((message) => (
                                  <div
                                    className='alert alert-success mt-2 py-1 border-0 rounded-0'
                                    role='alert'
                                  >
                                    {message}
                                  </div>
                                ))}
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <FormGroup>
                            <ShippingMethods
                              onShipmentOptionSelect={
                                onShipmentOptionSelectHandler
                              }
                              selectedShipmentOption={selectedShipmentOptionId}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {paymentMethods}
                    </>
                  )}
                  {(basket?.length ?? 0) === 0 && (
                    <div className='card'>
                      <div
                        className='card-body'
                        style={{ textAlign: "center" }}
                      >
                        <span>Din varukorg är tom!</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      {step === 1 && <PaymentConfirm orderResponse={order} />}
    </>
  );
}
