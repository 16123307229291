import React, {
  useEffect,
  useState,
  useImperativeHandle,
  useCallback,
} from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  CloseButton,
} from "reactstrap";
import { makeGetOrderDetailRequest } from "../services/orderService";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Link } from "react-router-dom";
import ImageContainer from "./ImageContainer";

const OrderDetail = ({ orderId }, ref) => {
  const [order, setOrder] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();

  const onOpenModalHandler = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const onCloseModalHandler = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  useImperativeHandle(ref, () => ({
    open: onOpenModalHandler,
    close: onCloseModalHandler,
  }));

  useEffect(() => {
    async function fetchData() {
      const response = await makeGetOrderDetailRequest(orderId);
      setOrder(response?.order);
    }

    if (orderId != null) {
      fetchData();
    }
  }, [orderId]);
  return (
    <Modal zIndex={10050} size='xl' isOpen={isModalVisible}>
      <ModalHeader
        close={<CloseButton onClick={() => onCloseModalHandler()} />}
      ></ModalHeader>
      <ModalBody>
        {order?.orderItems?.map((x) => (
          <div class='card rounded-3 mb-4'>
            <div class='card-body p-4'>
              <div class='row d-flex justify-content-between align-items-center'>
                <div class='d-block d-md-none col-12'>
                  <ImageContainer
                    src={`${
                      _.first(x.product?.imageUrls)?.imageUrl
                      }_medium.webp`}
                    alt={x.product?.name}
                  />
                </div>
                <div class='d-none d-md-block col-md-4 col-lg-2 col-xl-2'>
                <ImageContainer
                    src={`${
                      _.first(x.product?.imageUrls)?.imageUrl
                      }_small.webp`}
                    alt={x.product?.name}
                  />
                </div>
                <div class='d-block d-md-none d-lg-block text-md-start text-center col-12 col-lg-2'>
                  <p class='lead fw-normal mb-2 d-none d-md-inline text-nowrap'>
                    {t("Product")}
                  </p>
                  <p
                    className='d-none d-md-block'
                    style={{
                      maxHeight: 50,
                    }}
                  >
                    <Link
                      className='text-break'
                      to={`/product/${x.product?.urlName}${
                        x.product?.hasVariants
                          ? `?variant=${x.product?.variant?.toLowerCase()}`
                          : ""
                      }`}
                    >
                      {x.product?.name}
                    </Link>
                  </p>
                  <p
                    className='d-block d-md-none display-6'
                    style={{
                      maxHeight: 50,
                    }}
                  >
                    <Link
                      className='text-break'
                      to={`/product/${x.product?.urlName}${
                        x.product?.hasVariants
                          ? `?variant=${x.product?.variant?.toLowerCase()}`
                          : ""
                      }`}
                    >
                      {x.product?.name}
                    </Link>
                  </p>
                </div>
                <div class='d-none d-md-block col-md-4 col-lg-2 col-xl-2'>
                  <p class='lead fw-normal mb-2'>{t("Quantity")}</p>
                  <p
                    style={{
                      maxHeight: 50,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {x.quantity}
                  </p>
                </div>
                <div class='d-flex d-md-none col-6 mt-5'>
                  <p class='lead fw-bold mb-0 col-6'>{t("Quantity")}:</p>

                  <p style={{ fontSize: 17 }} className='col-6 mb-0'>
                    {x.quantity}
                  </p>
                </div>
                <div class='d-none d-md-block col-md-4 col-lg-2 col-xl-2'>
                  <p class='lead fw-normal mb-2'>{t("Amount")}</p>
                  <p>kr. {x.price.toFixed(2)}</p>
                </div>
                <div class='d-flex d-md-none col-6 mt-5'>
                  <p class='lead fw-bold mb-2 col-6'>{t("Amount")}:</p>
                  <p style={{ fontSize: 17 }} className='col-6 mb-0'>
                    kr. {x.price.toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </ModalBody>
      <ModalFooter>
        <h3>kr {order?.amount.toFixed(2)}</h3>
      </ModalFooter>
    </Modal>
  );
};

export default React.forwardRef(OrderDetail);
