import React, { useEffect, useRef } from "react";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import Layout from "./components/Layout";
import "./custom.css";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import UserProvider from "./store/user/userContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SessionMiddleware from "./middleware/SessionMiddleware";
import { registerResponseInterceptorCallback } from "./api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ProductProvider from "./store/product/productContext";
import BasketProvider from "./store/basket/basketContext";
import LoginModal from "./components/LoginModal";
import MainCategoryMiddleware from "./middleware/MainCategoryMiddleware";
import LoadingModal from "./components/Loading";
import LoadingProvider from "./store/loading/loadingContext";
import Home from "./components/Home";
import { useElementStore } from "./store/element/elementContext";
import { setLoginRefAction } from "./store/element/elementActions";
import CookieConsent from "./components/CookieConsent";
import "react-loading-skeleton/dist/skeleton.css";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "sv", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    debug: false,
    backend: {
      loadPath: "/locales/{{lng}}/translation.json?v=4",
    },
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default function App() {
  const [, elementDispatch] = useElementStore();
  const { t } = useTranslation();
  const refLogin = useRef();

  useEffect(() => {
    registerResponseInterceptorCallback((response) => {
      if (response?.status === 401) {
        if (
          response.config.url === "/users/current/basket" &&
          response.config.method === "post"
        ) {
          refLogin.current.open();
        } else if (
          response.config.url === "/users/auth" &&
          response.config.method === "get"
        ) {
          //do nothing
        } else {
          localStorage.removeItem("user");
          localStorage.removeItem("authInfo");

          if (response.data.errorMessage === undefined) window.location = "/";
        }
      } else if (response?.status === 400) {
        toast.warn(t(response.data.errorMessage));
      } else if (response?.status === 500) {
        toast.error(t(response.data.errorMessage));
      }
    });

    elementDispatch(setLoginRefAction(refLogin));
  }, []);

  return (
    <LoadingProvider>
      <BasketProvider>
        <ProductProvider>
          <UserProvider>
            <SessionMiddleware>
              <MainCategoryMiddleware>
                <Routes>
                  {AppRoutes.map((route, index) => {
                    const { element, footer, ...rest } = route;
                    return (
                      <Route
                        key={index}
                        {...rest}
                        element={
                          <Layout isFooterHidden={!footer}>{element}</Layout>
                        }
                      />
                    );
                  })}
                  <Route
                    path="*"
                    element={
                      <Layout>
                        <Home />
                      </Layout>
                    }
                  />
                </Routes>
                <CookieConsent />
                <ToastContainer
                  theme="light"
                  closeButton
                  limit={5}
                  newestOnTop
                  closeOnClick
                  pauseOnHover
                  hideProgressBar
                  autoClose={3000}
                  position="bottom-left"
                  pauseOnFocusLoss={false}
                />
                <LoadingModal />
              </MainCategoryMiddleware>
            </SessionMiddleware>
            <LoginModal ref={refLogin} />
          </UserProvider>
        </ProductProvider>
      </BasketProvider>
    </LoadingProvider>
  );
}
