import React, { useLayoutEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";
import { makeConfirmKlarnaOrderRequest } from "../../services/klarnaService";
import { useLoadingStore } from "../../store/loading/loadingContext";
import { hideLoading, showLoading } from "../../store/loading/loadingActions";
import { useBasketStore } from "../../store/basket/basketContext";
import { clearBasketAction } from "../../store/basket/basketActions";
import PaypalLogo from "../PaypalLogo";
import KlarnaLogo from "../KlarnaLogo";
import * as googleEvents from "../../utility/googleEvents";

export default function PaymentConfirm({ orderResponse }) {
  const [, basketDispatch] = useBasketStore();
  const [, loadingDispatch] = useLoadingStore();
  const [orderInternal, setOrderInternal] = useState(null);
  const arrayAmount = useMemo(
    () =>
      orderInternal?.amount?.toFixed(2).split(".") ??
      ["0", "00"][orderInternal?.amount]
  );
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const klarnaOrderId = searchParams.get("klarna_order_id");
  const navigate = useNavigate();

  useLayoutEffect(() => {
    loadingDispatch(showLoading());
    const init = async () => {
      try {
        if (orderResponse == null && klarnaOrderId?.length > 0) {
          const response = await makeConfirmKlarnaOrderRequest({
            orderId: klarnaOrderId,
          });
          if (response?.success) {
            setOrderInternal({
              amount: response.amount,
              paymentMethod: response.paymentMethod,
              trackingCode: response.trackingCode,
            });
            basketDispatch(clearBasketAction());
            googleEvents.purchase(
              response.orderId,
              response.amount,
              response.tax,
              response.shipmentAmount,
              response.products
            );
          } else {
            navigate("/");
          }
        } else if (orderResponse != null) {
          basketDispatch(clearBasketAction());
          setOrderInternal(orderResponse.order);
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }, 200);
          googleEvents.purchase(
            orderResponse.order.id,
            orderResponse.order.amount,
            orderResponse.order.tax,
            orderResponse.order.shipmentAmount,
            orderResponse.products
          );        
        } else {
          navigate("/");
        }
      } catch (error) {
        navigate("/");
      } finally {
        loadingDispatch(hideLoading());
      }
    };

    init();
  }, [klarnaOrderId, orderResponse]);

  return (
    <>
      {orderInternal && (
        <div class='pc-bg'>
          <div class='pc-card'>
            <span class='pc-card-success'>
              <FontAwesomeIcon style={{ height: "2em" }} icon={faCheck} />
            </span>

            <h1 class='pc-card-msg'>{t("Payment Complete")}</h1>
            <h2 class='pc-card-submsg'>{t("Thank you for your purchase")}</h2>

            <div class='pc-card-body'>
              <h1 class='pc-card-price'>
                <span>Kr</span>
                {_.first(arrayAmount)}
                <span>{`.${_.last(arrayAmount)}`}</span>
              </h1>

              <p class='pc-card-method'>{t("Payment method")}</p>
              {orderInternal?.paymentMethod === "klarna" && (
                <div class='pc-card-payment'>
                  <div style={{ width: 100 }}>
                    <KlarnaLogo />
                  </div>
                  <div class='pc-card-card-details'>
                    <p class='pc-card-card-type' hidden>
                      Klarna
                    </p>
                  </div>
                </div>
              )}
              {orderInternal?.paymentMethod !== "klarna" && (
                <div class='pc-card-payment'>
                  <div style={{ width: 100 }}>
                    <PaypalLogo />
                  </div>
                  <div class='pc-card-card-details'>
                    <p class='pc-card-card-type' hidden>
                      Paypal
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div class='pc-card-tags'>
              <span class='pc-card-tag'>{t("completed")}:</span>
              <span class='pc-card-tag'>{orderInternal?.trackingCode}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
