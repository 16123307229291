import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";
const agent = new BrowserAgent({
  init: {
    distributed_tracing: { enabled: false },
    privacy: { cookies_enabled: true },
    ajax: { autoStart: false, enabled: false },
    jserrors: { autoStart: false },
    metrics: { autoStart: false },
    generic_events: { autoStart: false, enabled: false },
    page_view_event: { autoStart: false },
    page_view_timing: { autoStart: false },
    session_replay: { autoStart: false, enabled: false },
    session_trace: { autoStart: false, enabled: false },
    spa: { autoStart: false },
  },
  loader_config: {
    accountID: "3753989",
    trustKey: "3753989",
    agentID: "538625738",
    licenseKey: "NRJS-c37623e6af13eec2a1c",
    applicationID: "538625738",
  },
  info: {
    beacon: "bam.eu01.nr-data.net",
    errorBeacon: "bam.eu01.nr-data.net",
    licenseKey: "NRJS-c37623e6af13eec2a1c",
    applicationID: "538625738",
    sa: 1,
  },
});
export const NewRelicAgent = {
  use: () => {
    if (process.env.REACT_APP_NEW_RELIC_ENABLED === "false") return;
    agent.setApplicationVersion(process.env.REACT_APP_VERSION);
    agent.start();
  },
  destroy: () => {
    if (process.env.REACT_APP_NEW_RELIC_ENABLED === "false") return;
    if (window.NREUM) {
      window.NREUM = null;
    }
  },
};
