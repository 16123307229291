import {
  faClock,
  faEnvelope,
  faHome,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useImperativeHandle } from "react";
import { Modal, ModalBody, ModalHeader, CloseButton } from "reactstrap";
import { useTranslation } from "react-i18next";

const ContactUs = (props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  return (
    <Modal zIndex={10050} isOpen={isOpen} centered>
      <ModalHeader close={<CloseButton onClick={() => close()} />}>
        <h5 className='m-0 p-0'>{t("Contact Us")}</h5>
      </ModalHeader>
      <ModalBody>
        <div class='col-12 mt-10'>
          <div class='row justify-content-xl-center'>
            <div class='col-12'>
              <div class='row mb-sm-4 mb-md-5'>
                <div class='col-6 my-3'>
                  <div class='mb-4 mb-sm-0'>
                    <div>
                      <div className='d-flex'>
                        <FontAwesomeIcon
                          style={{ width: 24, height: 24, color: "#be7575" }}
                          icon={faEnvelope}
                          className='px-1'
                        />
                        <h4 className='px-1'>{t("E-Mail")}</h4>
                      </div>
                      <hr class='w-75 m-1 border-dark-subtle' />
                      <p>
                        <a
                          rel='nofollow'
                          class='link-secondary text-decoration-none'
                          href='mailto:info@buketpresent.se'
                        >
                          info@buketpresent.se
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-12 my-3'>
                  <div className='mb-4 mb-sm-0'>
                    <div class='d-flex'>
                      <FontAwesomeIcon
                        style={{ width: 24, height: 24, color: "#be7575" }}
                        icon={faClock}
                        className='px-1'
                      />
                      <h4 className='px-1'>{t("Opening Hours")}</h4>
                    </div>
                    <div>
                      <hr class='w-50 m-1 border-dark-subtle' />
                      <div class='d-flex mb-1'>
                        <p class='text-secondary fw-bold mb-0 me-5'>
                          {t("Mon")} - {t("Fri")}
                        </p>
                        <p class='text-secondary mb-0'>09:00 - 17:00</p>
                      </div>
                      <div class='d-flex'>
                        <p class='text-secondary fw-bold mb-0 me-5'>
                          {t("Sat")} - {t("Sun")}
                        </p>
                        <p class='text-secondary mb-0'>{t("Closed")}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 my-3'>
                  <div className='mb-4 mb-sm-0'>
                    <div class='d-flex'>
                      <FontAwesomeIcon
                        style={{ width: 24, height: 24, color: "#be7575" }}
                        icon={faHome}
                        className='px-1'
                      />
                      <h4 className='px-1'>Adress</h4>
                    </div>
                    <div>
                      <hr class='w-50 m-1 border-dark-subtle' />
                      <p>
                        Pantbrevsgatan 1A
                        <br />
                        22643 Lund
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="organization-number text-muted"><span className="fw-bold">Moms Nr: </span>SE950324634101</p>
      </ModalBody>
    </Modal>
  );
};

export default React.forwardRef(ContactUs);
