import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { useLoadingStore } from "../store/loading/loadingContext";

export default function LoadingModal() {
  const [{ isLoadingVisible }, loadingDispatch] = useLoadingStore();

  return (
    <Modal
      isOpen={isLoadingVisible}
      contentClassName='transparent-modal'
      centered
      unselectable='on'
      size='sm'
      zIndex={10050}
    >
      <ModalBody>
        <Loading />
      </ModalBody>
    </Modal>
  );
}

export function Loading({ dark = false }) {

  return (
    <div className='d-flex justify-content-center'>
      <div className={`spinner-border ${dark ? 'text-dark' : 'text-light'}`} role='status'>
        <span className='sr-only'>Loading...</span>
      </div>
    </div>
  );
}
