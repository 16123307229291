import * as actionTypes from "./userActionTypes";

export const loginAction = (payload) => {
  localStorage.setItem("user", JSON.stringify(payload.user));
  localStorage.setItem("authInfo", JSON.stringify(payload.authInfo));
  sessionStorage.setItem("isSessionVerified", "true");
  return {
    type: actionTypes.LOGIN,
    payload:{ ...payload, isSessionVerified: true },
  };
};

export const logoutAction = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("authInfo");
  localStorage.removeItem("specialDays");
  sessionStorage.removeItem("isSessionVerified");
  return {
    type: actionTypes.LOGIN,
    payload: { user: null, authInfo: null, specialDays: null, isSessionVerified: false },
  };
};

export const reloadAuthAction = () => {
  const strUser = localStorage.getItem("user");
  const strAuthInfo = localStorage.getItem("authInfo");
  const strSpecialDays = localStorage.getItem("specialDays");
  const isSessionVerified = sessionStorage.getItem("isSessionVerified") === "true";

  let user = null,
    authInfo = null,
    specialDays = null;

  if ((strUser?.length > 0 && strUser !== 'undefined') && (strAuthInfo?.length > 0 && strAuthInfo !== 'undefined') && (strSpecialDays?.length > 0 && strSpecialDays !== 'undefined')) {
    user = JSON.parse(strUser);
    authInfo = JSON.parse(strAuthInfo);
    specialDays = JSON.parse(strSpecialDays);
  }

  return {
    type: actionTypes.RELOAD_AUTH,
    payload: { user, authInfo, specialDays, isSessionVerified },
  };
};

export const getUserAddressesAction = (payload) => ({
  type: actionTypes.GET_USER_ADDRESSES,
  payload,
});

export const getSpecialDaysAction = (payload) => {
  localStorage.setItem("specialDays", JSON.stringify(payload.specialDays));

  return {
    type: actionTypes.GET_SPECIAL_DAYS,
    payload,
  }
};

export const switchLanguageAction = (lng) => {
  localStorage.setItem("lng", lng);

  return {
    type: actionTypes.SWITCH_LANGUAGE,
    payload: { language: lng },
  }
};

export const updateUserAction = (payload) => {
  localStorage.setItem("user", JSON.stringify(payload.user));
  return {
    type: actionTypes.UPDATE_USER,
    payload,
  };
};