import React, { useEffect } from "react";
import { useBasketStore } from "../../store/basket/basketContext";
import PostnordLogo from "../PostnordLogo";
import { addShippingInfo } from "../../utility/googleEvents";
import { toDiscountPercentage } from "../../utility/convert";

const ShippingMethods = ({
  onShipmentOptionSelect,
  selectedShipmentOption,
}) => {
  const [{ shipmentOptions, basket, isBasketInProgress }] = useBasketStore();
  useEffect(() => {
    const shipmentOptionName =
      shipmentOptions.find((x) => x.id === selectedShipmentOption)?.name ??
      selectedShipmentOption;
    addShippingInfo(shipmentOptionName, basket);
  }, [selectedShipmentOption]);
  return (
    <>
      <h5>Leverans</h5>
      <section class='radio-section'>
        <div class='radio-list'>
          {shipmentOptions?.map((x) => (
            <div class='radio-item card mt-1'>
              <fieldset
                className='shipment-option-fieldset d-flex flex-column flex-wrap flex-md-nowrap'
                disabled={isBasketInProgress}
                onClick={async () => {
                  await onShipmentOptionSelect(x.id);
                }}
              >
                <div>
                  <input
                    name={"rship"}
                    id={x.id}
                    type='radio'
                    value={x.id}
                    disabled
                    checked={selectedShipmentOption === x.id}
                  />
                  <label
                    for={x.id}
                    className='d-flex flex-column flex-lg-row'
                    style={{
                      gap: 10,
                      fontSize: "1em",
                    }}
                  >
                    <div>
                      <PostnordLogo width={110} height={20} />
                    </div>
                    <span className='fw-bold'>{x.name}</span>
                  </label>
                </div>
                <div className='d-inline-flex pb-3 px-3 justify-content-between align-items-center w-100'>
                  <small className='opacity-0-6 font-size-0-9'>
                    1-3 arbetsdagar
                  </small>
                  <div className='d-flex gap-3'>
                    {x.discountPrice != null ? (
                      <div className='fw-bold text-color-alert font-size-1-1'>
                        {x.discountPrice > 0 ? (
                          <span>{`${x.discountPrice.toFixed(2)} kr`}</span>
                        ) : (
                          <span className='text-uppercase'>Gratis!</span>
                        )}
                      </div>
                    ) : (
                      <span className='fw-bold'>{x.price.toFixed(2)} kr</span>
                    )}
                    {x.discountPrice != null && (
                      <div className='d-flex gap-1'>
                        <span className='font-size-0-8 d-none d-sm-inline'>Ord.pris</span>
                        <span className='shipping-old-price font-size-0-9'>
                          {x.price.toFixed(2)} kr
                        </span>
                        <span className='text-color-alert font-size-0-9'>
                          <small>
                            {`(-${toDiscountPercentage(
                              x.price,
                              x.discountPrice
                            )}%)`}
                          </small>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </fieldset>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default React.memo(ShippingMethods);
