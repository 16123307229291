import React from "react";

const PostnordLogo = ({ width, height }) => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 600 114'
      enable-background='new 0 0 600 114'
      width={width}
      height={height}
    >
      <g>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#0498BA'
          d='M0.5,23c8.159-0.027,16.317-0.026,24.476-0.11
		c1.395-0.014,1.69,0.555,1.625,1.773c-0.076,1.408-0.018,2.824-0.018,4.321c0.306-0.057,0.597-0.022,0.742-0.151
		c17.247-15.289,43.533-9.186,51.751,12.423c5.855,15.397,4.752,30.333-6.376,43.337c-11.739,13.717-34.058,14.373-45.195,5.514
		c-0.173-0.138-0.425-0.176-1.005-0.404c0,8.232,0,16.264,0,24.297c-7.9,0-15.8-0.057-23.699,0.044
		c-1.772,0.022-2.363-0.238-2.358-2.233C0.525,82.207,0.5,52.604,0.5,23z M25.926,58.078c-0.111,8.506,5.778,14.645,14.196,14.797
		c8.028,0.145,14.247-5.814,14.377-13.776c0.149-9.208-5.378-15.385-13.883-15.515C32.008,43.452,26.041,49.34,25.926,58.078z'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#0498BA'
          d='M600.5,94c-7.313-0.483-14.635-0.077-21.951-0.209
		c-0.749-0.013-1.509-0.079-2.246,0.016c-1.623,0.209-2.021-0.555-1.909-2.022c0.106-1.39,0.022-2.795,0.022-4.542
		c-1.484,1.232-2.677,2.336-3.98,3.287c-12.225,8.908-31.617,6.816-41.996-4.505c-12.975-14.155-12.913-39.895-0.158-54.186
		c11.564-12.955,33.688-14.569,45.262-5.357c0.167,0.133,0.427,0.149,0.873,0.296c0-6.274,0.096-12.406-0.057-18.531
		c-0.046-1.872,0.67-2.394,2.293-2.696c6.945-1.293,13.872-2.685,20.807-4.037c0.398-0.078,0.84-0.046,1.041-0.515
		c1.711-0.378,2.053,0.288,2.049,2C600.481,33.333,600.5,63.666,600.5,94z M546.501,58.162c-0.007,8.704,5.763,14.765,14.097,14.809
		c8.521,0.047,14.443-5.975,14.477-14.719c0.031-8.51-5.903-14.465-14.411-14.461C552.249,43.795,546.507,49.622,546.501,58.162z'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#0498BA'
          d='M418.68,20.469c7.956,0.122,14.641,1.237,21.007,3.964
		c14.999,6.423,22.888,20.112,21.583,36.413c-1.56,19.49-14.82,30.803-32.305,34.098c-10.387,1.959-20.588,1.152-30.359-3.009
		c-15.441-6.573-22.246-19.692-21.436-35.479c1.156-22.514,17.57-33.092,35.115-35.436C414.754,20.69,417.254,20.588,418.68,20.469z
		 M404.795,58.289c0.002,8.482,6.156,14.709,14.416,14.588c8.381-0.123,14.197-6.057,14.205-14.492
		c0.008-8.794-5.865-14.836-14.389-14.802C410.632,43.617,404.791,49.65,404.795,58.289z'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#0498BA'
          d='M129.176,20.482c10.908,0.271,21.103,2.735,29.691,9.914
		c7.15,5.977,10.82,13.792,11.622,22.978c0.916,10.492-1.016,20.253-8.051,28.488c-6.028,7.055-13.9,10.938-22.862,12.817
		c-10.625,2.228-21.037,1.558-31.112-2.567c-16.718-6.844-23.339-21.486-21.72-37.907c1.813-18.375,14.729-29.348,31.757-32.67
		C122.021,20.848,125.578,20.472,129.176,20.482z M128.742,43.584c-8.521-0.068-14.478,5.886-14.648,14.643
		c-0.16,8.229,6.154,14.627,14.443,14.633c8.247,0.006,14.273-6.037,14.366-14.404C142.997,49.93,137.017,43.65,128.742,43.584z'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#0598BA'
          d='M324.639,28.651c8.594-8.386,18.7-9.742,29.544-6.832
		c9.833,2.639,14.446,10.021,16.273,19.528c0.489,2.543,0.64,5.113,0.637,7.703c-0.019,14.081-0.042,28.161,0.029,42.241
		c0.01,1.875-0.365,2.59-2.431,2.553c-7.162-0.133-14.33-0.115-21.493-0.008c-1.939,0.029-2.548-0.473-2.538-2.49
		c0.053-11.744-0.033-23.49-0.104-35.235c-0.007-1.157-0.137-2.329-0.354-3.466c-1.214-6.333-3.75-8.44-9.795-8.213
		c-5.817,0.218-9.371,3.372-9.884,9.181c-0.579,6.562-0.163,13.155-0.221,19.734c-0.054,6.081-0.073,12.164,0.026,18.244
		c0.029,1.776-0.547,2.259-2.273,2.238c-7.331-0.083-14.664-0.077-21.995-0.002c-1.607,0.016-2.106-0.403-2.102-2.082
		c0.058-22.246,0.063-44.491-0.005-66.735c-0.005-1.857,0.645-2.147,2.286-2.129c7.165,0.082,14.332,0.114,21.494-0.014
		c2.094-0.038,2.843,0.564,2.594,2.642C324.215,26.448,324.074,27.473,324.639,28.651z'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#0598BA'
          d='M211.215,20.546c8.705,0.298,16.646,0.971,24.469,2.806
		c1.644,0.386,1.959,0.893,1.548,2.508c-1.332,5.236-2.582,10.498-3.658,15.792c-0.402,1.981-1.126,2.04-2.841,1.527
		c-8.105-2.422-16.334-3.978-24.853-2.736c-0.653,0.095-1.295,0.284-1.93,0.472c-1.563,0.46-2.785,1.376-2.767,3.105
		c0.019,1.79,1.407,2.568,2.948,2.917c5.601,1.269,11.371,1.593,16.944,3.038c2.986,0.774,5.918,1.682,8.708,3.009
		c7.723,3.672,11.162,9.681,10.907,18.218c-0.302,10.111-5.477,16.606-14.466,20.478c-7.246,3.119-14.946,3.904-22.702,4.075
		c-9.706,0.214-19.249-1.075-28.61-3.718c-1.525-0.431-1.939-0.91-1.485-2.545c1.534-5.527,2.936-11.093,4.245-16.678
		c0.423-1.804,1.067-1.934,2.69-1.309c8.626,3.319,17.51,5.265,26.834,4.569c1.261-0.095,2.486-0.319,3.63-0.831
		c1.287-0.574,2.195-1.458,2.18-3.033c-0.015-1.588-0.941-2.426-2.275-2.937c-2.905-1.114-5.99-1.359-9.034-1.76
		c-5.292-0.696-10.524-1.649-15.497-3.649c-14.374-5.781-17.364-24.28-5.58-34.483c6.473-5.604,14.377-7.417,22.58-8.323
		C206.092,20.74,209.012,20.681,211.215,20.546z'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#0598BA'
          d='M245.499,50.268c0-13.667,0.042-27.333-0.047-41
		c-0.013-1.896,0.495-2.345,2.345-2.316c7.248,0.112,14.501,0.127,21.748-0.007c1.991-0.037,2.341,0.654,2.285,2.421
		c-0.121,3.83,0.048,7.668-0.075,11.498c-0.056,1.717,0.579,2.098,2.161,2.081c6.583-0.072,13.167,0.01,19.75-0.057
		c1.734-0.018,2.28,0.252,1.311,2.007c-3.419,6.189-6.769,12.417-10.077,18.667c-0.596,1.125-1.319,1.502-2.556,1.464
		c-2.748-0.084-5.507,0.099-8.247-0.068c-1.89-0.115-2.359,0.479-2.349,2.35c0.081,14.416,0.045,28.833,0.044,43.25
		c0,3.23-0.001,3.231-3.204,3.232c-6.833,0-13.668-0.084-20.499,0.051c-2.051,0.041-2.656-0.46-2.641-2.574
		C245.546,77.602,245.499,63.935,245.499,50.268z'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#0498BA'
          d='M493.583,30.506c5.988-8.059,13.937-9.128,22.504-8.469
		c1.558,0.119,2.31,0.717,2.241,2.489c-0.261,6.734-0.4,13.473-0.518,20.211c-0.027,1.597-0.529,2.004-2.111,1.595
		c-2.996-0.774-6.07-1.013-9.166-0.75c-8.641,0.735-12.903,5.295-12.938,13.97c-0.043,10.494-0.067,20.988,0.035,31.48
		c0.021,2.093-0.354,2.877-2.683,2.82c-7.074-0.174-14.156-0.104-21.234-0.033c-1.608,0.016-2.344-0.229-2.338-2.125
		c0.066-22.236,0.058-44.473,0.01-66.708c-0.004-1.605,0.406-2.127,2.068-2.108c7.41,0.086,14.824,0.084,22.234,0.001
		c1.592-0.019,2.025,0.507,1.922,1.999C493.494,26.526,493.583,28.188,493.583,30.506z'
        />
      </g>
    </svg>
  );
};

export default React.memo(PostnordLogo);
