/* eslint-disable import/no-anonymous-default-export */
import * as actionTypes from "./userActionTypes";

export const initialState = {
  user: null,
  authInfo: null,
  userAddresses: null,
  specialDays: null,
  language: "sv",
  systemLanguages: ["en", "sv"],
  userReloading: true,
  isSessionVerified: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.LOGIN:
      return { ...state, ...payload };
    case actionTypes.RELOAD_AUTH:
      return { ...state, ...payload, userReloading: false };
    case actionTypes.GET_USER_ADDRESSES:
      return { ...state, ...payload };
    case actionTypes.GET_SPECIAL_DAYS:
      return { ...state, ...payload };
    case actionTypes.SWITCH_LANGUAGE:
      return { ...state, ...payload };
    case actionTypes.UPDATE_USER:
      return { ...state, ...payload };

    default:
      return state;
  }
};
