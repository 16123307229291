import React, { useLayoutEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  Button,
  Form,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  makeGetCurrentUserRequest,
  makeChangePasswordRequest,
  makeUpdateUserRequest,
} from "../services/userService";
import { useForm } from "react-hook-form";
import * as validationConsts from "../constants/validationConstants";
import { toast } from "react-toastify";
import { useUserStore } from "../store/user/userContext";


export default function Profile() {
  const [userInfo, userDispatch] = useUserStore();
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState({});
  const {
    register: registerF1,
    handleSubmit: handleSubmitF1,
    setValue: setValueF1,
    formState: { errors: errorsF1 }
  
  } = useForm({
    resetOptions: {
      keepErrors: false,
    },
    values: {
      ...currentUser
    },
    
  });

  const {
    register: registerF2,
    handleSubmit: handleSubmitF2,
    getValues: getValuesF2,
    setValue: setValueF2,
    formState: { errors: errorsF2 }
  } = useForm({
    resetOptions: {
      keepErrors: false,
      keepValues: false
    },
  });

  const onUpdateInformationSubmitHandler = async (data) => {
    const response = await makeUpdateUserRequest(userDispatch, data);
    if (response?.success) {
      toast.success(t("Updated successfully"));
    }
  };

  const onChangePasswordSubmitHandler = async (data) => {
    const response = await makeChangePasswordRequest(data);
    if (response?.success) {
      toast.success(t("Updated successfully"));
    }
  };

  useLayoutEffect(() => {
    async function fetchData() {
      const response = await makeGetCurrentUserRequest();
      const user = response?.user;
      if (user) {
        setCurrentUser(response?.user);
      }
    }
    fetchData();
  }, []);
  return (
    <Container style={{ marginTop: 30 }}>
      <Row>
        <Col md={5}>
          <Form onSubmit={handleSubmitF1(onUpdateInformationSubmitHandler)}>
            <Row>
              <Col style={{ textAlign: "center" }}>
                <FormGroup>
                  <h4>{t("My Personal Information")}</h4>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for='txtFirstName'>{t("First Name")}</Label>
                  <Input
                    type='text'
                    name='firstName'
                    id='txtFirstName'
                    {...registerF1("firstName", {
                      required: {
                        value: true,
                        message: t("First name is required"),
                      },
                      maxLength: {
                        value: 20,
                        message: t("First name can be maximum 20 characters"),
                      },
                      onChange: (x) => setValueF1("firstName", x.target.value),
                    })}
                    defaultValue={currentUser?.firstName}
                  />
                  {errorsF1.firstName?.message && (
                    <p class='alert'>{errorsF1.firstName?.message}</p>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for='txtLastName'>{t("Last Name")}</Label>
                  <Input
                    type='text'
                    id='txtLastName'
                    {...registerF1("lastName", {
                      required: {
                        value: true,
                        message: t("Last name is required"),
                      },
                      maxLength: {
                        value: 20,
                        message: t("Last name can be maximum 20 characters"),
                      },
                    })}
                    onChange={(x) => setValueF1("lastName", x.target.value)}
                    defaultValue={currentUser?.lastName}
                  />
                  {errorsF1.lastName?.message && (
                    <p class='alert'>{errorsF1.lastName?.message}</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for='txtEmail'>{t("E-Mail")}</Label>
                  <Input
                    type='email'
                    id='txtEmail'
                    {...registerF1("email", {
                      required: {
                        value: true,
                        message: t("E-Mail is required"),
                      },
                      pattern: {
                        value: validationConsts.EMAIL_REGEX,
                        message: t("E-Mail is invalid"),
                      },
                    })}
                    onChange={(x) => setValueF1("email", x.target.value)}
                    defaultValue={currentUser?.email}
                  />
                  {errorsF1.email?.message && (
                    <p class='alert'>{errorsF1.email?.message}</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for='txtPhone'>{t("Phone")}</Label>
                  <Input
                    type='text'
                    id='txtPhone'
                    placeholder="0799988877"
                    {...registerF1("phone", {
                      required: {
                        value: true,
                        message: t("Phone number is required"),
                      },
                      pattern: {
                        value: validationConsts.PHONE_REGEX,
                        message: t("Phone number is invalid"),
                      },
                    })}
                    onChange={(x) => setValueF1("phone", x.target.value)}
                    defaultValue={currentUser?.phone}
                  />
                  {errorsF1.phone?.message && (
                    <p class='alert'>{errorsF1.phone?.message}</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for='txtBirthday'>{t("Birthday")}</Label>
                  <Input
                    type='date'
                    id='txtBirthday'
                    {...registerF1("birthday", {
                      required: {
                        value: true,
                        message: t("Birthday is required"),
                      },
                    })}
                    onChange={(x) => setValueF1("birthday", x.target.value)}
                    defaultValue={currentUser?.birthday?.split("T")[0]}
                  />
                  {errorsF1.birthday?.message && (
                    <p class='alert'>{errorsF1.birthday?.message}</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Button type='submit' className='btn-primary'>
                    {t("Update")}
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col
          md={2}
          style={{
            background: "linear-gradient(#000, #000) no-repeat center/2px 100%",
          }}
        ></Col>
        <Col md={5}>
          <Form id="frmPwdChange" autoComplete="off" onSubmit={handleSubmitF2(onChangePasswordSubmitHandler)}>
            <Row>
              <Col style={{ textAlign: "center" }}>
                <FormGroup>
                  <h4>{t("Change Password")}</h4>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for='txtCurrentPassword'>
                    {t("Current Password")}
                  </Label>
                  <Input
                    type='password'
                    name="txtCurrentPassword"
                    autoComplete="off"
                    id='txtCurrentPassword'
                    {...registerF2("currentPassword", {
                      required: {
                        value: true,
                        message: t("Current password is required"),
                      },
                      minLength: {
                        value: 8,
                        message: t(
                          "Your current password must be at least 8 characters long, contain at least one number and have a mixture of uppercase and lowercase letters"
                        ),
                      },
                      maxLength: {
                        value: 16,
                        message: t(
                          "Your current password must be maximum 16 characters long, contain at least one number and have a mixture of uppercase and lowercase letters"
                        ),
                      },
                      onChange: (x) =>
                        setValueF2("currentPassword", x.target.value),
                    })}
                  />
                  {errorsF2.currentPassword?.message && (
                    <p class='alert'>{errorsF2.currentPassword?.message}</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for='txtNewPassword'>{t("New Password")}</Label>
                  <Input
                    type='password'
                    id='txtNewPassword'
                    autoComplete="off"
                    {...registerF2("newPassword", {
                      required: {
                        value: true,
                        message: t("New password is required"),
                      },
                      minLength: {
                        value: 8,
                        message: t(
                          "Your new password must be at least 8 characters long, contain at least one number and have a mixture of uppercase and lowercase letters"
                        ),
                      },
                      maxLength: {
                        value: 16,
                        message: t(
                          "Your new password must be maximum 16 characters long, contain at least one number and have a mixture of uppercase and lowercase letters"
                        ),
                      },
                      onChange: (x) => setValueF2("newPassword", x.target.value),
                    })}
                  />
                  {errorsF2.newPassword?.message && (
                    <p class='alert'>{errorsF2.newPassword?.message}</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for='txtNewPasswordAgain'>
                    {t("New Password (Again)")}
                  </Label>
                  <Input
                    type='password'
                    id='txtNewPasswordAgain'
                    autoComplete="off"
                    {...registerF2("passwordAgain", {
                      required: {
                        value: true,
                        message: t("Password again is required"),
                      },

                      validate: (value) => {
                        const { newPassword } = getValuesF2();
                        return (
                          value === newPassword || t("Passwords don't match")
                        );
                      },
                    })}
                    onChange={(x) => setValueF2("passwordAgain", x.target.value)}
                  />
                  {errorsF2.passwordAgain?.message && (
                    <p class='alert'>{errorsF2.passwordAgain?.message}</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Button type='submit' className='btn-primary'>
                    {t("Update")}
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
