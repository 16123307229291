import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CloseButton,
} from "reactstrap";
import { useTranslation } from "react-i18next";

export default function ConfirmModal({
  show,
  message,
  onYesClick,
  onNoClick,
  onCloseClick,
}) {
  const { t } = useTranslation();
  return (
    <Modal zIndex={10050} isOpen={show}>
      <ModalHeader close={<CloseButton onClick={onCloseClick} />}>
        <h5>{t("Confirm")}</h5>
      </ModalHeader>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <Button onClick={onYesClick}>{t("Yes")}</Button>
        <Button onClick={onNoClick}>{t("No")}</Button>
      </ModalFooter>
    </Modal>
  );
}
