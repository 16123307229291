import { useEffect, useLayoutEffect } from "react";
import { reloadAuthAction } from "../store/user/userActions";
import { useUserStore } from "../store/user/userContext";
import { useBasketStore } from "../store/basket/basketContext";
import { reloadBasketAction } from "../store/basket/basketActions";
import { useProductStore } from "../store/product/productContext";
import { reloadFavoriteProductsAction } from "../store/product/productActions";
import { makeGetSessionRequest } from "../services/userService";

export default function SessionMiddleware({ children }) {
  const [{ user, isSessionVerified }, userDispatch] = useUserStore();
  const [, basketDispatch] = useBasketStore();
  const [, productDispatch] = useProductStore();

  useLayoutEffect(() => {
    userDispatch(reloadAuthAction());
    basketDispatch(reloadBasketAction());
    productDispatch(reloadFavoriteProductsAction());
  }, []);

  useEffect(() => {
    const checkSession = async () => {
     if (!isSessionVerified && user) {
      await makeGetSessionRequest(userDispatch, basketDispatch);
     }
   };
    
    checkSession();
  }, [user]); 

  return children;
}