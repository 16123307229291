import React, { useReducer } from 'react';
import elementStore, { initialState } from './elementStore';

export const ElementContext = React.createContext();

export const useElementStore = () => React.useContext(ElementContext);

export default function ElementProvider(props) {
    const [state, dispatch] = useReducer(elementStore, initialState);
    return <ElementContext.Provider value={[state, dispatch]}>{props.children}</ElementContext.Provider>;
}