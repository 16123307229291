import React from "react";
import { Helmet } from "react-helmet";

export default function ReturnPolicy() {
  return (
    <>
      <Helmet>
        <title>Återbetalningpolicy - Buket Present</title>
        <meta rel="robots" content="noindex, nofollow" />
      </Helmet>
      <div>
        <h2>ÅTERBETALNINGSPOLICY</h2>
        <ul>
          <h5>Återbetalningsvillkor</h5>
          <li>
            Du har rätt att returnera din beställning inom 14 dagar från dagen
            ditt paket levererades till ditt ombud eller hem till dig.
          </li>
          <li>
            Personliga produkter kan ej returneras då de är individuellt
            utformade för varje kund.
          </li>
          <li>
            Kunden är ansvarig för de fraktkostnader som uppstår vid returnering
            av produkten.
          </li>
        </ul>
        <ul>
          <h5>Återbetalningsavgift</h5>
          <li>Kunden ansvarar för returfraktkostnaderna.</li>
          <li>
            De fraktkostnader som betalats för leveransen av beställningen
            återbetalas ej; endast kostnaden för produkten återbetalas.
          </li>
        </ul>
        <ul>
          <h5>Återbetalningsprocess</h5>
          <li>
            Vänligen kontakta vår kundtjänst via e-post på{" "}
            <a className='email' href='mailto:info@buketpresent.se'>
              info@buketpresent.se
            </a>{" "}
            för att lämna in en returansökan.
          </li>
          <li>
            Efter att en returansökan har mottagits kommer vår kundtjänst att
            assistera dig med returprocessen och ge nödvändiga anvisningar.
          </li>
          <li>
            Den returnerade produkten ska skickas till den adress som
            tillhandahålls av vår kundtjänst.
          </li>
          <li>
            Efter att produkten har granskats vid mottagande av adressen, kommer
            en återbetalningsanvisning att ges till banken.
          </li>
          <li>
            Returer av använda produkter kommer inte att accepteras och
            produkten kommer att returneras till dig.
          </li>
          <li>
            Återbetalningen kan ta upp till 14 dagar beroende på din bank.
          </li>
        </ul>
        <ul>
          <h5>Återbetalningsvillkor</h5>
          <li>
            Den returnerade produkten måste vara i sitt ursprungliga skick,
            oanvänd och oskadad.
          </li>
          <li>
            Produkten måste vara förpackad i sitt originalförpackning; förpackad
            på ett sätt som skyddar den under transporten och inte utsätter den
            för skador som kan uppstå under leveransen.
          </li>
          <li>
            Returer av produkter som inte skickas i sitt originalförpackning
            kommer inte att accepteras.
          </li>
        </ul>
        <ul>
          <h5>Kontaktinformation</h5>
          <li>
            För returprocessen och eventuella frågor, vänligen kontakta vår
            kundtjänst via{" "}
            <a className='email' href='mailto:info@buketpresent.se'>
              info@buketpresent.se
            </a>
          </li>
        </ul>
        <ul>
          <h5>Defekta Produkter</h5>
          <li>
            Om du anser att produkten är defekt eller felaktig, vänligen skicka
            en bild av produkten till info@buketpresent.se och bifoga din
            klagomålsmail för att begära en retur. Om begäran godkänns kommer
            Buket Present att täcka fraktkostnaderna för returen och återbetala
            beloppet.
          </li>
        </ul>
      </div>
    </>
  );
}
