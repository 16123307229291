/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  makeGetProductByIdRequest,
  makeInsertFavoriteProductRequest,
  makeDeleteFavoriteProductRequest,
  makeProductVisitLogRequest,
} from "../services/productService";
import {
  useParams,
  useNavigate,
  Link,
  useSearchParams,
} from "react-router-dom";
import ImageEditModal from "./ImageEditModal";
import { useBasketService } from "../store/basket/basketContext";
import {
  Card,
  CardBody,
  CloseButton,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { toast } from "react-toastify";
import { rawStringToUploadImage } from "../utility/convert";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faHeart,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import "./Product.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageContainer from "./ImageContainer";
import { useUserStore } from "../store/user/userContext";
import { useProductStore } from "../store/product/productContext";
import { Helmet } from "react-helmet";
import { useElementStore } from "../store/element/elementContext";
import * as googleEvents from "../utility/googleEvents";
import { toDiscountPercentage } from "../utility/convert";
import ProductCard from "./ProductCard";
import Skeleton from "react-loading-skeleton";
import { isMobile } from "react-device-detect";
import $ from "jquery";

const Product = () => {
  const [{ user }] = useUserStore();
  const [{ favoriteProducts }, productDispatch] = useProductStore();
  const [{ loginRef }] = useElementStore();
  const { addToBasket } = useBasketService();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [product, setProduct] = useState(null);
  const [variantOptions, setVariantOptions] = useState(null);
  const [uploadImages, setUploadImages] = useState(null);
  const [isImageEditModalOpen, setIsImageEditModalOpen] = useState(false);
  const [currentUploadImage, setCurrentUploadImage] = useState(null);
  const [uploadImageDictionary, setUploadImageDictionary] = useState({});
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [hoveredVariantName, setHoveredVariantName] = useState(null);
  const [selectedSizeId, setSelectedSizeId] = useState(null);
  const { t } = useTranslation();
  const refImgDiv = useRef();

  const images = useMemo(
    () =>
      product?.imageUrls?.length > 0
        ? product.imageUrls.map((x) => ({
            original: `${x.imageUrl}_original.webp`,
            thumbnail: `${x.imageUrl}_medium.webp`,
            loading: "lazy",
            thumbnailLoading: "lazy",
            renderItem: (x) => (
              <ImageContainer src={x.original} shimmerStyle={{ height: 500 }} />
            ),
            renderThumbInner: (x) => (
              <ImageContainer
                src={x.thumbnail}
                shimmerStyle={{ height: 93, width: 93 }}
              />
            ),
          }))
        : [
            {
              original: `error_original.webp`,
              loading: "lazy",
              renderItem: (x) => (
                <ImageContainer
                  src={x.original}
                  shimmerStyle={{ height: 500 }}
                />
              ),
            },
          ],
    [product]
  );
  const isImagesRequired = useMemo(
    () =>
      (uploadImages?.length ?? 0) === 0 ||
      uploadImages.length ===
        Object.keys(uploadImageDictionary).length[
          (uploadImages, uploadImageDictionary)
        ]
  );
  const isSizesRequired = useMemo(
    () =>
      product?.sizes.length === 0 || selectedSizeId !== null,
    [product?.sizes, selectedSizeId]
  );

  const FavoriteButton = useMemo(() => {
    if (user && product) {
      if (favoriteProducts.some((productId) => productId === product.id)) {
        return (
          <a
            title='i din favoritlista'
            className='btn-favorite float-end text-dark display-6 liked'
            onClick={async () =>
              await makeDeleteFavoriteProductRequest(
                productDispatch,
                product.id
              )
            }
          >
            <FontAwesomeIcon icon={faHeart} />
          </a>
        );
      } else {
        return (
          <a
            title='Lägg till din favoritlista'
            className='btn-favorite float-end text-dark display-6'
            onClick={async () =>
              await makeInsertFavoriteProductRequest(productDispatch, {
                productId: product.id,
              })
            }
          >
            <FontAwesomeIcon icon={faHeart} />
          </a>
        );
      }
    } else {
      return null;
    }
  }, [user, product, favoriteProducts]);

  const PriceSection = useMemo(
    () =>
      product ? (
        <div className='mb-3'>
          <div class='fs-5 d-inline'>
            {product?.isStockAvailable ? (
              <>
                {product.discountPrice && (
                  <span className='product-discount-price font-size-1-5'>
                    {product.discountPrice.toFixed(2) + " kr"}
                  </span>
                )}
                {!product.discountPrice && (
                  <span className='font-size-1-5'>
                    {product.price.toFixed(2) + " kr"}
                  </span>
                )}
              </>
            ) : (
              <>
                <span className='font-size-1-5'>
                  {product?.price.toFixed(2) + " kr"}
                </span>
              </>
            )}
            <span className='px-2 font-size-1 opacity-0-6'>Inkl. moms</span>
          </div>

          <div className='fs-6 d-inline'>
            {product?.isStockAvailable && product?.discountPrice && (
              <div className='product-old-price'>
                <span className='opacity-0-7 font-size-0-8'>Ord.pris</span>
                <span class='text-decoration-line-through px-1 margin-right-5'>
                  {`${product.price.toFixed(2)} kr`}
                </span>
                <span>
                  <small>
                    {`(-${toDiscountPercentage(
                      product.price,
                      product.discountPrice
                    )}%)`}
                  </small>
                </span>
              </div>
            )}
          </div>

          {
            <>
              {product.isStockAvailable ? (
                <div className='font-size-1 opacity-0-6 pt-3'>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ color: "#25cb15", marginRight: 2 }}
                  />
                  <span>Finns i lager, hos dig inom 2-4 vardagar</span>
                </div>
              ) : (
                <div className='font-size-1 opacity-0-8 pt-3'>
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{ color: "#ff0000", marginRight: 2 }}
                  />
                  <span>
                    Slutsåld, du kan ta reda på när den är tillbaka. Vänligen{" "}
                    <a
                      rel='nofollow'
                      href={`mailto:info@buketpresent.se?subject=F%C3%B6rfr%C3%A5gan%20om%20produktens%20lagerstatus&body=Hej%2C%0D%0A%0D%0AJag%20skulle%20vilja%20f%C3%A5%20information%20om%20lagerstatus%20f%C3%B6r%20produkten%20%5B${product.name}%5D.%20Kan%20ni%20meddela%20mig%20n%C3%A4r%20den%20kommer%20att%20finnas%20i%20lager%20igen%3F%0D%0A%0D%0ATack`}
                    >
                      kontakta oss!
                    </a>
                  </span>
                </div>
              )}
            </>
          }
        </div>
      ) : (
        <Skeleton count={10} />
      ),
    [product]
  );

  const BreadcrumbSection = useMemo(
    () =>
      product ? (
        <Breadcrumb className='mt-4 mt-md-0'>
          {product && (
            <BreadcrumbItem>
              <Link className='text-decoration-none' to={"/"}>
                Hem
              </Link>
            </BreadcrumbItem>
          )}
          {product?.breadcrumbs?.map((x) =>
            x.link ? (
              <BreadcrumbItem>
                <Link className='text-decoration-none' to={x.link}>
                  {x.displayName}
                </Link>
              </BreadcrumbItem>
            ) : (
              <BreadcrumbItem>{x.displayName}</BreadcrumbItem>
            )
          )}
        </Breadcrumb>
      ) : (
        <Skeleton width={"30%"} />
      ),
    [product]
  );

  const RelatedProductsSection = useMemo(() => {
    if (relatedProducts.length === 0) return null;

    return (
      <div className='py-4'>
        <h4 className='text-center'>Liknande produkter</h4>
        <section className='section-products d-flex justify-content-evenly justify-content-md-center'>
          <div className='row w-100'>
            {relatedProducts.map((product) => (
              <ProductCard
                className={"col-12 col-md-6 col-lg-3"}
                productId={product.id}
                productTitle={product.name}
                productOldPrice={product.discountPrice?.toFixed(2)}
                productPrice={product.price.toFixed(2)}
                productImage={_.first(product.imageUrls)?.imageUrl}
                productFavoriteId={product.favoriteId}
                productUrlName={product.urlName}
                soldOut={!product.isStockAvailable}
                hasImageUpload={product.uploadImages?.length > 0}
                compact={true}
              />
            ))}
          </div>
        </section>
      </div>
    );
  }, [relatedProducts]);

  const onImageFilePickHandler = (files, uploadImage) => {
    if (files.length === 0) return;

    if (files[0].size > 1024 * 1024 * 2) {
      toast.error("Bilden får inte vara större än 2 MB", {
        autoClose: 3000,
        position: "bottom-center",
      });
      return;
    }

    setCurrentUploadImage({
      id: uploadImage.id,
      filePath: URL.createObjectURL(files[0]),
      width: uploadImage.width,
      height: uploadImage.height,
    });
    setIsImageEditModalOpen(true);
  };

  const onImageFilePickCompleteHandler = (blob) => {
    setUploadImageDictionary((x) => {
      x[currentUploadImage.id] = blob;
      return _.clone(x);
    });
    setIsImageEditModalOpen(false);
  };

  const onImageEditModalCloseClickHandler = () => {
    setIsImageEditModalOpen(false);
  };

  const onAddToBasketClickHandler = async () => {
    if (!isImagesRequired) {
      toast.warning(
        t(
          "You must choose the required image(s) to add this product to the basket."
        ),
        { autoClose: 3000 }
      );
      return;
    }

    if (!isSizesRequired) {
      toast.warning(
        "Du måste välja storlek för att lägga till produkten i korgen.",
        { autoClose: 3000 }
      );
      return;
    }

    await addToBasket(
      { ...product, sizeId: selectedSizeId },
      uploadImageDictionary
    );
    setUploadImageDictionary((x) => {
      Object.keys(x).forEach((id) => {
        delete x[id];
      });
      return _.clone(x);
    });
  };

  const onImageFileRemoveClickHandler = (id) => {
    setUploadImageDictionary((x) => {
      delete x[id];
      return _.clone(x);
    });
  };

  const loadProduct = useCallback(
    async (variant = null) => {
      if (params.urlName) {
        try {
          const response = await makeGetProductByIdRequest(
            params.urlName,
            variant
          );

          if (!response?.success) {
            navigate("/");
            return;
          }

          setRelatedProducts(response.relatedProducts ?? []);
          setProduct(response.product);
          setVariantOptions(response.variants);
          if (response.product.uploadImages) {
            setUploadImages(
              response.product.uploadImages.map(rawStringToUploadImage)
            );
          }

          googleEvents.viewProduct(response.product);
          makeProductVisitLogRequest(params.urlName);
        } catch {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    },
    [params.urlName]
  );

  const onVariantSelectHandler = useCallback(
    (variant) => {
      if (!variant.isStockAvailable) return;
      navigate(
        `/product/${params.urlName}?variant=${variant.name.toLowerCase()}`
      );

      if (isMobile) {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 200);
      }
    },
    [params.urlName, isMobile]
  );

  useEffect(() => {
    var variant = searchParams.get("variant");
    loadProduct(variant);
    setSelectedSizeId(null);
    $(".btn-check").prop("checked", false);
  }, [params.urlName, searchParams]);

  return (
    <>
      {product && (
        <Helmet>
          <title>{product.name} - Buket Present</title>
          <meta name='description' content={product.shortDescription} />
          <meta
            rel='canonical'
            href={`https://buketpresent.se/product/${product.urlName}`}
          />
        </Helmet>
      )}
      <section>
        <div class='row px-lg-5 mb-5'>
          {BreadcrumbSection}
          <div className='card product-detail'>
            <div className='container-fliud'>
              <div className='wrapper row gx-4 gx-lg-5'>
                <div ref={refImgDiv} className='preview col-12 col-lg-6'>
                  {product && (
                    <div className='position-relative'>
                      {product.isStockAvailable && product.discountPrice && (
                        <div className='discount-icon'>
                          <span>{`-${toDiscountPercentage(
                            product.price,
                            product.discountPrice
                          )}%`}</span>
                        </div>
                      )}
                      <ImageGallery
                        useBrowserFullscreen={isMobile}
                        showPlayButton={false}
                        lazyLoad={true}
                        items={images}
                        showNav={true}
                        showThumbnails={!isMobile}
                        showFullscreenButton={
                          product?.imageUrls?.length > 0 ?? false
                        }
                      />
                    </div>
                  )}
                  {!product && (
                    <>
                      <Skeleton height={540} />
                      <Skeleton height={110} />
                    </>
                  )}
                </div>
                <div class='col-12 col-lg-6 align-items-center'>
                  {FavoriteButton}
                  <h1 class='display-5 fw-bolder'>
                    {product?.name ? product.name : <Skeleton />}
                  </h1>

                  {PriceSection}

                  {product?.isStockAvailable && (
                    <div className='w-100'>
                      {uploadImages?.map((x) => (
                        <div className='d-flex' style={{ marginBottom: 20 }}>
                          {!(x.id in uploadImageDictionary) && (
                            <>
                              <button
                                className='btn btn-primary w-100'
                                onClick={() => {
                                  if (!user) {
                                    loginRef?.current?.open(
                                      `/product/${product.urlName}`
                                    );
                                    return;
                                  }
                                  document.getElementById(x.id)?.click();
                                }}
                              >
                                <span>{`${t("Add")} ${x.label}`}</span>
                              </button>

                              <input
                                id={x.id}
                                type='file'
                                onChange={(y) =>
                                  onImageFilePickHandler(y.target.files, x)
                                }
                                hidden
                                accept='image/png,image/jpeg'
                              />
                            </>
                          )}
                          {x.id in uploadImageDictionary && (
                            <>
                              <Card className='w-100'>
                                <CardBody style={{ padding: 10 }}>
                                  <a
                                    href={URL.createObjectURL(
                                      uploadImageDictionary[x.id]
                                    )}
                                    target='_blank'
                                  >
                                    <span>{`${x.label}`}</span>
                                  </a>
                                  <FontAwesomeIcon
                                    style={{ marginLeft: 20, color: "#25cb15" }}
                                    icon={faCheckCircle}
                                  />
                                  <CloseButton
                                    style={{ float: "right" }}
                                    onClick={() =>
                                      onImageFileRemoveClickHandler(x.id)
                                    }
                                  />
                                </CardBody>
                              </Card>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  )}

                  <div className='mb-5 d-flex flex-column gap-2'>
                    {variantOptions?.length > 0 && (
                      <div className='container'>
                        <div className='row'>
                          <div className='col-12 px-1'>
                            <span
                              style={{ minHeight: 24 }}
                              className='fw-bold px-0 font-size-1-1 d-block'
                            >
                              {hoveredVariantName ?? product?.variant}
                            </span>
                          </div>
                        </div>

                        <div className='row'>
                          {variantOptions.map((variant) => (
                            <div
                              className={`col-3 px-1 variant-option${
                                !variant.isStockAvailable ? " disabled" : ""
                              }${
                                product?.variantId === variant.id
                                  ? " active"
                                  : ""
                              }`}
                              title={variant.name}
                              role='button'
                              onClick={() => onVariantSelectHandler(variant)}
                              onMouseOver={() =>
                                setHoveredVariantName(variant.name)
                              }
                              onMouseOut={() => setHoveredVariantName(null)}
                            >
                              <div className='ratio ratio-1x1 border border-2'>
                                <ImageContainer
                                  src={`${variant.image.imageUrl}_small.webp`}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {product?.sizes.length > 0 && (
                      <div className='row px-1 mt-2 mb-5'>
                        <h6 class='fw-bold'>Storlek</h6>
                        <div
                          className='btn-group'
                          role='group'
                          aria-label='Basic radio toggle button group'
                        >
                          {product?.sizes.map((size) => (
                            <>
                              <input
                                type='radio'
                                class='btn-check'
                                name='btnradio'
                                id={size.id}
                                disabled={!size.isStockAvailable}
                                autocomplete='off'
                                onClick={() => setSelectedSizeId(size.id)}
                              />
                              <label
                                className={"btn btn-outline-dark"}
                                for={size.id}
                              >
                                {size.isStockAvailable ? (
                                  size.name
                                ) : (
                                  <del>
                                    &nbsp;&nbsp;&nbsp;{size.name}
                                    &nbsp;&nbsp;&nbsp;
                                  </del>
                                )}
                              </label>
                            </>
                          ))}
                        </div>
                      </div>
                    )}

                    {product?.isStockAvailable && (
                      <>
                        {(product?.variant != null || !product.hasVariants) && (
                          <div className='row'>
                            <div className='col-12'>
                              <button
                                id='btnAddBasket'
                                className='btn btn-outline-primary flex-shrink-0 w-100'
                                type='button'
                                onClick={onAddToBasketClickHandler}
                              >
                                <i class='bi-cart-fill me-1'></i>
                                Köp
                              </button>
                            </div>
                          </div>
                        )}

                        {!isImagesRequired && (
                          <Tooltip
                            autohide
                            flip
                            isOpen={tooltipOpen}
                            target='btnAddBasket'
                            toggle={toggle}
                          >
                            {t(
                              "You must choose the required image(s) to add this product to the basket."
                            )}
                          </Tooltip>
                        )}
                        {!isSizesRequired && (
                          <Tooltip
                            autohide
                            flip
                            isOpen={tooltipOpen}
                            target='btnAddBasket'
                            toggle={toggle}
                          >
                            Du måste välja storlek för att lägga till produkten i korgen.
                          </Tooltip>
                        )}
                      </>
                    )}
                  </div>
                  <p
                    class='lead'
                    dangerouslySetInnerHTML={{
                      __html: product?.description,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
          {RelatedProductsSection}
        </div>
      </section>
      <ImageEditModal
        isOpen={isImageEditModalOpen}
        imagePath={currentUploadImage?.filePath}
        width={currentUploadImage?.width}
        height={currentUploadImage?.height}
        onModalCloseClick={onImageEditModalCloseClickHandler}
        onImagePickComplete={onImageFilePickCompleteHandler}
      />
    </>
  );
};

export default React.memo(Product);
