/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import {
  makeGetOrdersRequest,
  makeCancelOrderRequest,
} from "../services/orderService";
import moment from "moment";
import {
  faExternalLink,
  faFile,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OrderDetail from "./OrderDetail";
import ConfirmModal from "./ConfirmModal";
import { useTranslation } from "react-i18next";

export default function Orders() {
  const [orders, setOrders] = useState(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const refOrderDetail = useRef();
  const { t } = useTranslation();

  const onOrderDetailClickHandler = useCallback((orderId) => {
    setSelectedOrderId(orderId);
    refOrderDetail.current.open();
  }, []);

  const onCancelOrderClickHandler = useCallback((orderId) => {
    setSelectedOrderId(orderId);
    setIsCancelModalVisible(true);
  }, []);

  const onCancelConfirmYesClickHandler = useCallback(async () => {
    const response = await makeCancelOrderRequest(selectedOrderId);
    setOrders(response?.orders);
    setSelectedOrderId(null);
    setIsCancelModalVisible(false);
  }, [selectedOrderId]);

  const onCancelConfirmNoClickHandler = useCallback(() => {
    setIsCancelModalVisible(false);
  }, []);

  useLayoutEffect(() => {
    async function fetchData() {
      const response = await makeGetOrdersRequest();
      setOrders(response?.orders);
    }
    fetchData();
  }, []);

  return (
    <>
      {
        <>
          <section class='h-100' style={{ backgroundColor: "#eee" }}>
            <div class='container h-100 py-4 py-md-5'>
              <div
                class='row d-flex justify-content-center align-items-center h-100'
              >
                <div class='col-12 col-md-10'>
                  <div class='d-flex justify-content-between align-items-center mb-4'>
                    <h3 class='fw-normal mb-0 text-black'>{t("Orders")}</h3>
                  </div>
                  {orders?.length > 0 && (
                    <>
                      {orders?.map((x) => {
                        return (
                          <div class='card rounded-3 mb-4'>
                            <div class='card-body p-2 p-md-4'>
                              <div class='row d-flex justify-content-between align-items-center'>
                                <div className='d-flex col-12 d-xl-none d-xxl-none'>
                                  <div className='col-9 d-flex'>
                                    <div className='col-6'>
                                      <p class='lead fw-normal mb-2'>
                                        <h5 class='mb-0'>
                                          <strong>{t(x.orderStatusName === 'Cancel' ? 'Cancelled' : x.orderStatusName)}</strong>
                                        </h5>
                                      </p>
                                    </div>
                                    <div className='col-6 text-end'>
                                      {x.shipmentCompany &&
                                        x.orderStatus === 16 && (
                                          <p>
                                            <a href='#'>
                                              <span>{x.shipmentCompany}</span>{" "}
                                              <FontAwesomeIcon
                                                icon={faExternalLink}
                                              />
                                            </a>
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                  <div className='d-flex col-3 justify-content-end'>
                                    <div className='text-end col-9'>
                                      <a
                                        href='javascript:;'
                                        title='Details'
                                        onClick={(e) =>
                                          onOrderDetailClickHandler(x.id)
                                        }
                                      >
                                        <FontAwesomeIcon icon={faFile} />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div class='d-none d-xl-block d-xxl-block col-xl-2 col-xxl-2 order-image'>
                                  {x.imageUrls?.map((i) => (
                                    <img
                                      src={`${i}_small.webp`}
                                      class='img-fluid'
                                      alt=''
                                    />
                                  ))}
                                </div>
                                <div class='d-flex d-xl-block col-12 col-xl-2'>
                                  <p class='lead fw-normal mb-2 col-5 col-xl-12 text-nowrap'>
                                    {t("Order Number")}
                                  </p>
                                  <p
                                    className='col-5 col-xl-12 text-xl-start text-end'
                                    style={{
                                      maxHeight: 50,
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {x.trackingCode}
                                  </p>
                                </div>
                                <div class='d-flex d-xl-block col-12 col-xl-2'>
                                  <p class='lead fw-normal mb-2 col-5 col-xl-12 text-nowrap'>
                                    {t("Order Time")}
                                  </p>
                                  <p
                                    className='col-5 col-xl-12 text-end text-xl-start'
                                    style={{
                                      maxHeight: 50,
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {moment(x.creationTime).format(
                                      "YYYY-MM-DD HH:mm"
                                    )}
                                  </p>
                                </div>
                                <div class='d-flex d-xl-block col-12 col-xl-2'>
                                  <p class='lead fw-normal mb-2 col-5 col-xl-12'>
                                    {t("Amount")}
                                  </p>
                                  <p
                                    className='col-5 col-xl-12 text-end text-xl-start'
                                    style={{
                                      maxHeight: 50,
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    kr. {x.amount.toFixed(2)}
                                  </p>
                                </div>
                                <div  class='d-none d-xl-block col-xl-2'>
                                  <p class='lead fw-normal mb-2'>
                                    <h5 class='mb-0'>{t(x.orderStatusName === 'Cancel' ? 'Cancelled' : x.orderStatusName)}</h5>
                                  </p>
                                  {x.shipmentCompany &&
                                    x.orderStatus === 16 && (
                                      <p>
                                        <a style={{whiteSpace: 'nowrap'}} target="_blank" href={`https://www.postnord.se/vara-verktyg/spara-brev-paket-och-pall/?shipmentId=${x.shipmentTrackingCode}`}>
                                          <span style={{marginRight: 3}}>{`${x.shipmentCompany} (${x.shipmentOption})`}</span>
                                          <FontAwesomeIcon
                                            icon={faExternalLink}
                                          />
                                        </a>
                                      </p>
                                    )}
                                </div>
                                <div class='d-none d-xl-flex d-xxl-flex col-xl-1'>
                                  <a
                                    href='javascript:;'
                                    title='Details'
                                    style={{ width: "100%" }}
                                    onClick={(e) =>
                                      onOrderDetailClickHandler(x.id)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faFile} />
                                  </a>
                     
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                  {(orders?.length ?? 0) === 0 && (
                    <div className='card'>
                      <div
                        className='card-body'
                        style={{ textAlign: "center" }}
                      >
                        You have no order.
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      }
      <OrderDetail ref={refOrderDetail} orderId={selectedOrderId} />
      <ConfirmModal
        onNoClick={onCancelConfirmNoClickHandler}
        onCancelClick={onCancelConfirmNoClickHandler}
        onYesClick={onCancelConfirmYesClickHandler}
        show={isCancelModalVisible}
        message={t("Are you really sure to cancel the order ?")}
      />
    </>
  );
}
