import { makeApiRequest } from "../api";

export const makeGetOrdersRequest = async () => {
    try {
      const response = await makeApiRequest(
        "/users/current/orders",
        "GET",
        null,
        true
      );  
  
      return response;
    } catch (e) {
      return e;
    }
};
  
export const makeGetOrderDetailRequest = async (id) => {
  try {
    const response = await makeApiRequest(
      `/users/current/orders/${id}`,
      "GET",
      null,
      true
    );  

    return response;
  } catch (e) {
    return e;
  }
};

export const makeCancelOrderRequest = async (id) => {
  try {
    const response = await makeApiRequest(
      `/users/current/orders/${id}/cancel`,
      "PATCH",
      null,
      true
    );  

    return response;
  } catch (e) {
    return e;
  }
};

export const makeTrackOrderRequest = async (code) => {
  try {
    const response = await makeApiRequest(
      `/users/current/orders/${code}/track`,
      "GET",
      null,
      true
    );  

    return response;
  } catch (e) {
    return e;
  }
};