import { useLayoutEffect } from 'react';
import { useProductStore } from '../store/product/productContext';
import { makeGetMainCategoriesRequest } from '../services/productService';

export default function MainCategoryMiddleware({ children }) {
    const [{ mainCategories }, productDispatch] = useProductStore();

    useLayoutEffect(() => {
        if (!mainCategories) {
            makeGetMainCategoriesRequest(productDispatch);
        }
    }, []);
    return children;
}