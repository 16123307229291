/* eslint-disable import/no-anonymous-default-export */
import * as actionTypes from "./basketActionTypes";
import _ from "lodash";
import { basketInProgressAction } from "./basketActions";

export const initialState = {
  basket: null,
  itemCount: 0,
  totalPrice: 0,
  subtotalPrice: 0,
  vat: 0,
  shipmentPrice: null,
  shipmentDiscountPrice: null,
  messages: [],
  shipmentOptions: [],
  selectedShipmentOptionId: null,
  isBasketInProgress: false,
  promotionCode: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_BASKET:
      localStorage.setItem("itemCount", payload.itemCount);
      return { ...state, ...payload, isBasketInProgress: false};

    case actionTypes.CLEAR_BASKET:
      return { ...state, ...payload };

    case actionTypes.RELOAD_BASKET:
      return { ...state, ...payload };

    case actionTypes.UPDATE_QUANTITY:
      return {
        ...state,
        ...payload,
        isBasketInProgress: false,
      };

    case actionTypes.DELETE_PRODUCT_FROM_BASKET:
      return {
        ...state,
        ...payload,
        isBasketInProgress: false,
      };

    case actionTypes.ADD_PRODUCT_TO_BASKET:
      if (!state.basket) state.basket = [];

      state.basket.push(payload.basketItem);

      return {
        ...state,
        ...payload,
      };

    case actionTypes.ADD_PRODUCT_TO_BASKET_ANONYMOUS:
      if (!state.basket) state.basket = [];

      state.basket.push(payload.basketItem);
      state.itemCount = state.basket.reduce(
        (acc, item) => acc + item.quantity,
        0
      );
      localStorage.setItem("basketCount", state.itemCount);
      return {
        ...state,
        ...payload,
      };

    case actionTypes.UPDATE_PRODUCT_ANONYMOUS:
      const basketLocalStr = localStorage.getItem("basket");
      const basketLocal =
        basketLocalStr?.length > 0 ? JSON.parse(basketLocalStr) : [];
      let index3 =
        basketLocal.findIndex((x) => x.productId === payload.basketItem.productId) ?? -1;

      if (index3 > -1) {
        basketLocal[index3] = payload.basketItem;
        state.itemCount = basketLocal.reduce(
          (acc, item) => acc + item.quantity,
          0
        );
        localStorage.setItem("basketCount", state.itemCount);
      }

      return {
        ...state,
        basket: basketLocal,
        isBasketInProgress: false,
      };

    case actionTypes.GET_SHIPMENT_OPTIONS:
      const shipmentOptionsLocal =
        _.first(payload?.shipmentOptions?.shipmentCompanies)?.shipmentOptions ?? [];
      return {
        ...state,
        shipmentOptions: shipmentOptionsLocal,
        selectedShipmentOptionId: payload?.selectedShipmentOptionId,
        isBasketInProgress: false,
      };
    
    case actionTypes.BASKET_IN_PROGRESS:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};
