import React from "react";
import { useBasketStore } from "../store/basket/basketContext";

const UserAlerts = () => {
  const [{ messages }] = useBasketStore();
  return (
    <div>
      {messages?.map((message) => (
        <div
          dangerouslySetInnerHTML={{ __html: message }}
          className='alert alert-primary py-1 border-0 rounded-0 text-center'
          role='alert'
        ></div>
      ))}
    </div>
  );
};

export default React.memo(UserAlerts);
