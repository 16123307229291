import React, { useCallback, useRef } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
const AddedToBasketMessageId = "added-to-basket-message";
const useMessenger = () => {
  const AddedToBasketMessageRef = useRef();
  const navigate = useNavigate();

  const showAddedToBasketMessage = useCallback(() => {
    if (toast.isActive(AddedToBasketMessageRef.current)) {
      toast.update(AddedToBasketMessageId);
    }

    AddedToBasketMessageRef.current = toast.success(
      <Link className="text-decoration-none" to={"/basket"}>
        <div className='d-flex flex-column'>
          <span>Produkten har lagts till i din varukorg</span>
          <span
            className='btn btn-link btn-buketpresent-link'
          >
            Gå till varukorgen
          </span>
        </div>
      </Link>,
      {
        autoClose: 5000,
        hideProgressBar: false,
        progressStyle: { backgroundColor: "rgb(190, 117, 117)" },
        toastId: AddedToBasketMessageId,
      }
    );
  }, [AddedToBasketMessageRef.current]);

  return {
    showAddedToBasketMessage,
  };
};

export default useMessenger;
