export const hexToBase64 = (str) => {
  return btoa(
    String.fromCharCode.apply(
      null,
      str
        .replace(/\r|\n/g, "")
        .replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
        .replace(/ +$/, "")
        .split(" ")
    )
  );
};

export const rawStringToUploadImage = (str) => {
  const uploadImageprops = str.split("|");
  return {
    id: uploadImageprops[0],
    order: parseInt(uploadImageprops[1]),
    label: uploadImageprops[2],
    width: parseInt(uploadImageprops[3]) * 38,
    height: parseInt(uploadImageprops[4]) * 38,
  };
};

export const toCamelCase = (key, value) => {
  if (value && typeof value === 'object'){
    for (var k in value) {
      if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
        value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
        delete value[k];
      }
    }
  }
  return value;
}

export const titleToUrl = (str) => str?.toLowerCase().replaceAll(' ', '-');

export const toJSON = (str, fallbackValue = null) => {
  if (!str || str.length === 0) return fallbackValue;
  
  try {
    const json = JSON.parse(str);
    return json;
  } catch (e) {
    return fallbackValue;
  }
};

export const toDiscountPercentage = (productPrice, productDiscountPrice) => {
  const percentage = 100 - (productDiscountPrice / (productPrice / 100));
  const roundedPercentage = Math.floor(percentage / 5) * 5;
  return roundedPercentage;
}