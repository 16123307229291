import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function TopBanner() {
  return (
    <section id='top-strip' className='flex-md-row mt-md-0 mb-md-1'>
      <span>
        <FontAwesomeIcon className='top-strip-icon' icon={faCheck} />
        leverans 2-4 arbetsdagar
      </span>
      <span>
        <FontAwesomeIcon className='top-strip-icon' icon={faCheck} />
        14 dagars returrätt
      </span>
      <span>
        <FontAwesomeIcon className='top-strip-icon' icon={faCheck} />
        fri frakt vid köp över 500kr
      </span>
    </section>
  );
}
