import React, {
  useState,
  useImperativeHandle,
  useCallback,
  forwardRef,
} from "react";
import { Button, CloseButton, Modal, ModalBody } from "reactstrap";
import { useBasketStore } from "../store/basket/basketContext";
import { useUserStore } from "../store/user/userContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import BasketItems from "./BasketItems";
import { useNavigate } from "react-router-dom";
import UserAlerts from "./UserAlerts";
import { useProductStore } from "../store/product/productContext";
import { Link } from "react-router-dom";
import _ from "lodash";
import { useBasketService } from "../store/basket/basketContext";

const ShoppingCart = (props, ref) => {
  const [{ basket, subtotalPrice, itemCount }] =
    useBasketStore();
  const [{ categories, productGroups }] = useProductStore();
  const [{ user }] = useUserStore();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { initializeBasket } = useBasketService();

  const onGoToBasketClickHandler = useCallback(() => {
    setIsOpen(false);
    navigate("/basket");
  }, []);

  const open = useCallback(async () => {
    setIsOpen(true);
    await initializeBasket();
  }, [user]);

  useImperativeHandle(ref, () => ({
    open: open,
    close: () => setIsOpen(false),
  }));

  return (
    <Modal
      zIndex={10040}
      className={"right"}
      toggle={() => setIsOpen((x) => !x)}
      isOpen={isOpen}
      fullscreen='md'
    >
      <ModalBody>
        <div className='d-flex flex-column justify-content-between h-100'>
          <div className='d-flex justify-content-between flex-grow-0 flex-shrink-0'>
            <div className='d-flex gap-2'>
              <h4>Varukorg</h4>
              <div className='button d-inline' style={{ paddingLeft: 0 }}>
                <FontAwesomeIcon icon={faShoppingBasket} />
                <span class='button__badge'>{itemCount}</span>
              </div>
            </div>
            <CloseButton onClick={() => setIsOpen(false)} />
          </div>
          <div
            className='overflow-auto h-100 flex-grow-1 flex-shrink-1 overflow-x-hidden'
            style={{ flexBasis: 150 }}
          >
            <div className='position-fixed left-0 w-100 px-3 z-index-999'>
              <UserAlerts />
            </div>
            {basket?.length > 0 && (
              <div className=''>
                <BasketItems onProductClickCallback={() => setIsOpen(false)} />
              </div>
            )}
            {basket?.length === 0 && (
              <div className='row text-center pt-5'>
                <h4>Din varukorg är tom!</h4>
                <span>Utforska några av våra populära kategorier</span>
                <div className='d-flex justify-content-evenly flex-column'>
                  {categories
                    ?.filter((x) => productGroups[x.id]?.length > 0)
                    .map((category) => (
                      <Link
                        onClick={() => setIsOpen(false)}
                        to={`/product/${
                          _.first(productGroups[category.id])?.urlName
                        }`}
                      >
                        <div className='card'>
                          <div className='card-body'>{category.name}</div>
                        </div>
                      </Link>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className='container d-flex w-100  flex-column justify-content-center gap-3 pt-1 pb-4'>
            <div className='row justify-content-between'>
              <h5 className='align-content-center' style={{ width: "auto" }}>
                Totalt att betala
              </h5>
              <h5 className='font-size-1-6' style={{ width: "auto" }}>
                {`${subtotalPrice.toFixed(2)} kr`}
              </h5>
            </div>
            <div className='row'>
              <Button
                onClick={onGoToBasketClickHandler}
                className='btn-primary col-12'
                disabled={!basket || basket.length === 0}
              >
                Till kassan
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default forwardRef(ShoppingCart);
