import React, { useEffect } from "react";
import { useUserStore } from "../store/user/userContext";
import { useElementStore } from "../store/element/elementContext";
import { useNavigate } from "react-router-dom";

export default function AuthorizeMiddleware({ children, showLoginModal = false, redirectUrl = '/' }) {
  const [{ user, authInfo }] = useUserStore();
  const [{ loginRef }] = useElementStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || !authInfo) {
      if (showLoginModal === true && loginRef?.current) {
        loginRef?.current?.open(redirectUrl);
      } else {
        navigate(redirectUrl ?? '/');
      }
    }
  }, [user, authInfo]);

  return (
    <>
      {(() => {
        if (user && authInfo) {
          return children;
        } else {
          return null;
        }
      })()}
    </>
  );
}
