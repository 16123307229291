import React, { useCallback, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";
import KlarnaLogo from "./KlarnaLogo";
import { useUserStore } from "../store/user/userContext";
import {
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  CloseButton,
} from "reactstrap";
import { makeTrackOrderRequest } from "../services/orderService";
import moment from "moment";
import ContactUs from "./ContactUs";
import { useTranslation } from "react-i18next";
import PostnordLogo from "./PostnordLogo";
import MastercardLogo from "./MastercardLogo";
import VisaLogo from "./VisaLogo";
import PaypalLogo from "./PaypalLogo";
import { Link } from "react-router-dom";

const Footer = () => {
  const [{ user }] = useUserStore();
  const [trackingCode, setTrackingCode] = useState("");
  const [isOrderTrackingOpen, setIsOrderTrackingOpen] = useState(false);
  const [order, setOrder] = useState(null);

  const refContactUs = useRef();
  const { t } = useTranslation();
  const onTrackOrderClickHandler = useCallback(async () => {
    if (!trackingCode || trackingCode.length !== 8) return;

    const response = await makeTrackOrderRequest(trackingCode);
    if (response?.order) {
      setOrder(response.order);
      setIsOrderTrackingOpen(true);
    }
    setTrackingCode("");
  }, [trackingCode]);

  return (
    <>
      <Container className='bg-white' tag='footer'>
        <footer class='py-5'>
          <div class='row'>
            <div class='col-6 col-lg-2 mb-3'>
              <h5>KUNDSERVICE</h5>
              <ul class='nav flex-column'>
                <li class='nav-item mb-2'>
                  <Link
                    title='Återbetalningpolicy'
                    className={"btn btn-link p-0 text-body-secondary"}
                    to='/returnpolicy'
                  >
                    Återbetalningpolicy
                  </Link>
                </li>
              </ul>
            </div>

            <div class='col-6 col-lg-2 mb-3'>
              <h5>INFORMATION</h5>
              <ul class='nav flex-column'>
                <li className='nav-item mb-2'>
                  <button
                    onClick={() => refContactUs.current.open()}
                    title='Kontakta oss'
                    className='btn btn-link p-0 text-body-secondary'
                  >
                    Kontakta oss
                  </button>
                </li>
                <li className='nav-item mb-2'>
                  <Link
                    title='Cookies'
                    className={"btn btn-link p-0 text-body-secondary"}
                    to='/cookie-policy'
                  >
                    Cookies
                  </Link>
                </li>
              </ul>
            </div>
            {!user && (
              <div class='col-12 col-lg-8 mb-3'>
                <form onSubmit={(e) => e.preventDefault()}>
                  <h5>Spåra din order</h5>
                  <div class='d-flex flex-column flex-sm-row w-100 gap-2'>
                    <label for='txtTrackingCode' class='visually-hidden'>
                      Spårningskod
                    </label>
                    <input
                      id='txtTrackingCode'
                      type='text'
                      class='form-control'
                      placeholder='Spårningskod'
                      value={trackingCode}
                      onChange={(x) => setTrackingCode(x.target.value)}
                      maxLength={8}
                      minLength={8}
                      autoComplete='off'
                    />
                    <button
                      class='btn btn-primary'
                      type='submit'
                      onClick={onTrackOrderClickHandler}
                    >
                      Inkomma
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
          <div className='row'>
            <div className='footer-logo-container d-flex flex-column flex-lg-row col-12 justify-content-center'>
              <div
                title='klarna'
                className='col-12 col-lg-2 d-flex justify-content-center'
              >
                <KlarnaLogo width={120} />
              </div>
              <div
                title='paypal'
                className='col-12 col-lg-2 d-flex justify-content-center'
              >
                <PaypalLogo width={120} />
              </div>
              <div
                title='postnord'
                className='col-12 col-lg-2 d-flex justify-content-center'
              >
                <PostnordLogo width={120} />
              </div>
              <div
                title='master card'
                className='col-12 col-lg-2 d-flex justify-content-center'
              >
                <MastercardLogo width={120} />
              </div>
              <div
                title='visa'
                className='col-12 col-lg-2 d-flex justify-content-center'
              >
                <VisaLogo width={80} />
              </div>
            </div>
          </div>

          <div class='d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top'>
            <p>© {new Date().getUTCFullYear()} Buket Present</p>
            <ul class='list-unstyled d-flex'>
              <li class='ms-3'>
                <a
                  class='link-body-emphasis'
                  title='Instagram'
                  rel='noreferrer'
                  target='_blank'
                  href='https://www.instagram.com/buketpresentsverige'
                >
                  <FontAwesomeIcon
                    style={{ width: 24, height: 24 }}
                    icon={faInstagram}
                  />
                </a>
              </li>
              <li class='ms-3'>
                <a
                  class='link-body-emphasis'
                  title='Tiktok'
                  rel='noreferrer'
                  target='_blank'
                  href='https://www.tiktok.com/@buketpresent'
                >
                  <FontAwesomeIcon
                    style={{ width: 24, height: 24 }}
                    icon={faTiktok}
                  />
                </a>
              </li>
            </ul>
          </div>
        </footer>
      </Container>
      <Modal zIndex={10050} isOpen={isOrderTrackingOpen} centered>
        <ModalHeader
          close={
            <CloseButton
              onClick={() => {
                setIsOrderTrackingOpen(false);
                setOrder(null);
              }}
            />
          }
        />
        <ModalBody>
          <div id='order-detail-content'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Beställningskod</th>
                  <th>Orderstatus</th>
                  <th>Orderdatum</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{order?.trackingCode}</td>
                  <td>{t(order?.orderStatusName)}</td>
                  <td>
                    {moment(order?.creationTime).format("YYYY-MM-DD HH:mm")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ModalBody>
      </Modal>
      <ContactUs ref={refContactUs} />
    </>
  );
};

export default React.memo(Footer);
