import { HIDE_LOADING, SHOW_LOADING } from "./loadingActionTypes";

/* eslint-disable import/no-anonymous-default-export */
export const initialState = {
  isLoadingVisible: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_LOADING:
      return { ...state, ...payload };

    case HIDE_LOADING:
      return { ...state, ...payload };

    default:
      return state;
  }
};
