import React, { useLayoutEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useLoadingStore } from "../store/loading/loadingContext";
import { hideLoading, showLoading } from "../store/loading/loadingActions";
import { makeVerifyUserRequest } from "../services/userService";
import LoginModal from "./LoginModal";
import { useUserStore } from "../store/user/userContext";
import { useBasketStore } from "../store/basket/basketContext";
import { useProductStore } from "../store/product/productContext";
import { toJSON } from "../utility/convert";

export default function EmailVerification() {
  const [, loadingDispatch] = useLoadingStore();
  const [, userDispatch] = useUserStore();
  const [, basketDispatch] = useBasketStore();
  const [, productDispatch] = useProductStore();
  const { t } = useTranslation();
  const params = useParams();
  const [status, setStatus] = useState(0); //0-Loading 1-Succeeded 2-Failed
  const refLogin = useRef();

  useLayoutEffect(() => {
    loadingDispatch(showLoading());
    async function fetchData() {
      const response = await makeVerifyUserRequest(userDispatch, basketDispatch, productDispatch, {
        data: params.code,
        basketItems: toJSON(localStorage.getItem("basket")),
      });

      if (response?.success === true) {
        setStatus(1);
      } else {
        setStatus(2);
      }

      loadingDispatch(hideLoading());
    }
    fetchData();
  }, []);

  return (
    <>
      {status === 1 && (
        <>
          <div className='vh-75 d-flex justify-content-center align-items-center'>
            <div className='col-10'>
              <div className='border border-3 border-success'></div>
              <div className='card  bg-white shadow p-5'>
                <div className='mb-4 text-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='75'
                    height='75'
                    fill='currentColor'
                    className='bi bi-check-circle text-success'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
                    <path d='M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z' />
                  </svg>
                </div>
                <div className='text-center'>
                  <h1>{t("Thank You")} !</h1>
                  <p>
                    Välkommen till Buket Present. Ditt konto har blivit
                    verifierat.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {status === 2 && (
        <>
          <div className='vh-75 d-flex justify-content-center align-items-center'>
            <div clclassNamess='col-10'>
              <div className='border border-3 border-danger'></div>
              <div className='card  bg-white shadow p-5'>
                <div className='mb-4 text-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='75'
                    height='75'
                    fill='currentColor'
                    className='bi bi-x-circle text-danger'
                    viewBox='0 0 16 16'
                  >
                    <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16' />
                    <path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708' />
                  </svg>
                </div>
                <div class='text-center'>
                  <h1>{t("Failed")}</h1>
                  <p>
                    {t(
                      "The link is expired or already used. Please try with a new link."
                    )}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <LoginModal ref={refLogin} />
    </>
  );
}
