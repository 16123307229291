import React, { useEffect, useMemo } from "react";
import { Container } from "reactstrap";
import { NavMenu } from "./NavMenu";
import ScrollToTop from "react-scroll-to-top";
import Footer from "./Footer";
import { useLocation, useNavigationType } from "react-router";
import BasketNavMenu from "./BasketNavMenu";

const Layout = ({ children, isFooterHidden = false }) => {
  const location = useLocation();
  const navigationType = useNavigationType();

  const NavMenuSection = useMemo(() => {
    if (location.pathname.startsWith("/basket")) {
      return <BasketNavMenu />;
    } else {
      return <NavMenu />;
    }
  }, [location.pathname]);

  useEffect(() => {
    if (navigationType.startsWith("POP")) {
      return;     
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }, [location.pathname]);

  return (
    <div className='container-fluid'>
      {NavMenuSection}
      <Container style={{ paddingTop: "8rem", minHeight: "100dvh" }} tag='main'>
        <ScrollToTop
          style={{ padding: 0, zIndex: 9999 }}
          smooth
          color='rgb(190, 117, 117)'
        />
        {children}
      </Container>
      <Footer />
    </div>
  );
};

export default React.memo(Layout);