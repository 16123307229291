import { makeApiRequest } from "../api";

export const makeCreatePaypalPaymentRequest = async ({
  addressId,
  note,
  shipmentOptionId,
  promotionCode,
  basketItems,
  fullName,
  address,
  email,
  phone,
  isDeliveryAddressSame,
}) => {
  const response = await makeApiRequest(
    "/users/current/basket/payment/paypal",
    "POST",
    {
      addressId,
      note,
      shipmentOptionId,
      promotionCode,
      basketItems,
      fullName,
      address,
      email,
      phone,
      isDeliveryAddressSame,
    },
    true
  );
  return response;
};

export const makeCapturePaypalPaymentRequest = async ({ orderId }) => {
  const response = await makeApiRequest(
    "/users/current/basket/payment/paypal/capture",
    "POST",
    { orderId },
    true
  );
  return response;
};
