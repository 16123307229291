import React, { useLayoutEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
  CloseButton,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { useUserStore } from "../store/user/userContext";
import {
  makeGetSpecialDaysRequest,
  makeInsertSpecialDayRequest,
  makeUpdateSpecialDayRequest,
  makeDeleteSpecialDayRequest,
} from "../services/userService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faPencil } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ConfirmModal from "./ConfirmModal";
import { MONTHS } from "../constants/dateConstants";

export default function SpecialDays() {
  const [{ specialDays }, userDispatch] = useUserStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDayDropdownOpen, setIsDayDropdownOpen] = useState(false);
  const [isMonthDropdownOpen, setIsMonthDropdownOpen] = useState(false);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useForm();

  const onUpdateSpecialDayClickHandler = (id) => {
    const specialDay = specialDays.find((x) => x.id === id);
    onOpenModalClickHandler(specialDay);
  };

  const onSaveSpecialDayClickHandler = async (data) => {
    try {
      if (data.id === undefined) {
        await makeInsertSpecialDayRequest(data);
      } else {
        await makeUpdateSpecialDayRequest(data);
      }

      toast.success(t("Saved successfully"));
      setIsModalOpen(false);
      await makeGetSpecialDaysRequest(userDispatch);
    } catch (error) {}
  };

  const onOpenModalClickHandler = (data = null) => {
    if (data) {
      setSelectedItem(data);
      reset({ ...data });
    } else {
      const obj = {
        name: null,
        day: null,
        month: null,
        isRemindingConfirmed: true,
      };
      setSelectedItem(obj);
      reset(obj);
    }

    setIsModalOpen(true);
  };

  const OnToggleDayDropdownClickHandler = () => {
    setIsDayDropdownOpen((x) => !x);
  };

  const OnToggleMonthDropdownClickHandler = () => {
    setIsMonthDropdownOpen((x) => !x);
  };

  const onCloseModalClickHandler = () => {
    setIsModalOpen(false);
  };

  const onDeleteModalOpenClickHandler = (id) => {
    const specialDay = specialDays.find((x) => x.id === id);
    setSelectedItem(specialDay);
    setIsDeleteConfirmModalOpen(true);
  };

  const onDeleteConfirmClickHandler = async () => {
    await makeDeleteSpecialDayRequest(selectedItem.id);
    toast.success(t("Deleted successfully"));
    setIsDeleteConfirmModalOpen(false);
    setIsModalOpen(false);
    await makeGetSpecialDaysRequest(userDispatch);
  };

  const onDeleteRejectClickHandler = () => {
    setSelectedItem(null);
    setIsDeleteConfirmModalOpen(false);
  };

  const renderCardRows = (items) => {
    const rows = [];

    for (let index = 0; index < items.length; index += 3) {
      const chunk = items.slice(index, index + 3);

      rows.push(<Row style={{ marginTop: 20 }}>{renderCards(chunk)}</Row>);
    }

    return rows;
  };

  const renderCards = (chunk) => {
    const cols = [];
    for (let cIndex = 0; cIndex < chunk.length; cIndex++) {
      const element = chunk[cIndex];

      cols.push(
        <Col md={4}>
          <Card>
            <CardBody>
              <CardTitle tag='h5'>{element.name}</CardTitle>
              <CardText>
                {element.day?.toString().padStart(2, "0")}{" "}
                {t(MONTHS.find((x) => x.index === element.month)?.name)}
              </CardText>
              <button
                onClick={() => onDeleteModalOpenClickHandler(element.id)}
                className='btn btn-outline-danger address-card-button left'
              >
                <FontAwesomeIcon style={{ marginRight: 5 }} icon={faTrash} />
                {t("Delete")}
              </button>
              <button
                onClick={() => onUpdateSpecialDayClickHandler(element.id)}
                className='btn btn-outline-primary address-card-button right'
              >
                <FontAwesomeIcon style={{ marginRight: 5 }} icon={faPencil} />
                {t("Update")}
              </button>
            </CardBody>
          </Card>
        </Col>
      );
    }

    return cols;
  };

  useLayoutEffect(() => {
    async function fetchData() {
      await makeGetSpecialDaysRequest(userDispatch);
    }

    fetchData();
  }, []);

  return (
    <>
      <Container style={{ textAlign: "center", marginTop: 50 }}>
        <Row md={3}>
          <Col>
            <Card
              style={{ cursor: "pointer" }}
              onClick={() => onOpenModalClickHandler()}
            >
              <CardBody className='btn btn-outline-primary'>
                <CardTitle tag='h5'>
                  <FontAwesomeIcon style={{ marginRight: 10 }} icon={faPlus} />{" "}
                  {t("Add New Special Day")}
                </CardTitle>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {(() => {
          if (specialDays?.length > 0) {
            return renderCardRows(specialDays);
          }
        })()}
      </Container>
      <Modal zIndex={10050} isOpen={isModalOpen}>
        <Form onSubmit={handleSubmit(onSaveSpecialDayClickHandler)}>
          <ModalHeader
            close={<CloseButton onClick={onCloseModalClickHandler} />}
          ></ModalHeader>
          <ModalBody>
            <Row>
              <Col md={9}>
                <FormGroup>
                  <Label for='txtSpecialDayName'>{t("Special Day Name")}</Label>
                  <Input
                    id='txtSpecialDayName'
                    maxLength={32}
                    {...register("name", {
                      required: {
                        value: true,
                        message: t("Special day name is required"),
                      },
                      onChange: (x) => setValue("name", x.target.value),
                    })}
                    defaultValue={selectedItem?.name}
                  />
                  {errors.name?.message && (
                    <p class='alert'>{errors.name?.message}</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <FormGroup>
                  <Dropdown
                    id='ddDay'
                    toggle={OnToggleDayDropdownClickHandler}
                    isOpen={isDayDropdownOpen}
                    {...register("day", {
                      required: {
                        value: true,
                        message: t("Day is required"),
                      },
                    })}
                  >
                    <DropdownToggle caret>
                      {getValues("day")?.toString().padStart(2, "0") ??
                        t("Day")}
                    </DropdownToggle>
                    <DropdownMenu
                      style={{ maxHeight: 200, overflow: "scroll" }}
                    >
                      {(() => {
                        const days = [];
                        for (let day = 1; day <= 31; day++) {
                          days.push(
                            <DropdownItem
                              value={day}
                              onClick={(x) => setValue("day", x.target.value)}
                            >
                              {day.toString().padStart(2, "0")}
                            </DropdownItem>
                          );
                        }

                        return days;
                      })()}
                    </DropdownMenu>
                  </Dropdown>

                  {errors.day?.message && (
                    <p class='alert'>{errors.day?.message}</p>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Dropdown
                    id='ddMonth'
                    toggle={OnToggleMonthDropdownClickHandler}
                    isOpen={isMonthDropdownOpen}
                    {...register("month", {
                      required: {
                        value: true,
                        message: t("Month is required"),
                      },
                    })}
                  >
                    <DropdownToggle caret>
                      {getValues("month")
                        ? t(
                            MONTHS.find(
                              (x) =>
                                x.index.toString() ===
                                getValues("month").toString()
                            )?.name
                          )
                        : t("Month")}
                    </DropdownToggle>
                    <DropdownMenu
                      style={{ maxHeight: 200, overflow: "scroll" }}
                    >
                      {(() => {
                        const months = [];
                        MONTHS.forEach((x) => {
                          months.push(
                            <DropdownItem
                              value={x.index}
                              onClick={(y) => setValue("month", y.target.value)}
                            >
                              {t(x.name)}
                            </DropdownItem>
                          );
                        });

                        return months;
                      })()}
                    </DropdownMenu>
                  </Dropdown>

                  {errors.month?.message && (
                    <p class='alert'>{errors.month?.message}</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
            {false && (
              <Row>
                <Col>
                  <FormGroup>
                    <input
                      class='form-check-input'
                      type='checkbox'
                      value=''
                      id='chkConfirmReminding'
                      checked={selectedItem?.isRemindingConfirmed}
                      onChange={(x) => {
                        setValue("isRemindingConfirmed", x.target.checked);
                        setSelectedItem((y) => ({
                          ...y,
                          isRemindingConfirmed: x.target.checked,
                        }));
                      }}
                    />

                    <Label style={{ marginLeft: 5 }} for='chkConfirmReminding'>
                      {t("Remind me for this date as e-mail before 1 week")}
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            <Button type='submit' className='btn-primary'>
              {t("Save")}
            </Button>
            {selectedItem?.id && (
              <Button
                onClick={() => onDeleteModalOpenClickHandler(selectedItem.id)}
                className='btn-danger'
              >
                {t("Delete")}
              </Button>
            )}
            <Button type="button" onClick={onCloseModalClickHandler}>{t("Close")}</Button>
          </ModalFooter>
        </Form>
      </Modal>
      <ConfirmModal
        show={isDeleteConfirmModalOpen}
        message={t("Are you sure to delete this special day ?")}
        onYesClick={onDeleteConfirmClickHandler}
        onNoClick={onDeleteRejectClickHandler}
        onCloseClick={onDeleteRejectClickHandler}
      />
    </>
  );
}
