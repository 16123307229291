import React, {
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Klarna from "./Klarna";
import Paypal from "./Paypal";
import KlarnaLogo from "../KlarnaLogo";
import PaypalLogo from "../PaypalLogo";
import $ from "jquery";
import { isMobile } from "react-device-detect";
import { useBasketStore } from "../../store/basket/basketContext";

const PaymentMethods = ({ onPaymentSuccess, orderDataSource }, ref) => {
  const refKlarna = useRef();
  const refPaypal = useRef();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [{ isBasketInProgress }] = useBasketStore();

  const reload = useCallback(async () => {
    if (selectedPaymentMethod === "klarna") {
    } else if (selectedPaymentMethod === "paypal") {
      refPaypal.current.updateButtons();
    }
  }, [selectedPaymentMethod, refKlarna, refPaypal]);

  useImperativeHandle(ref, () => ({
    reload,
  }));

  const onPaymentSelectHandler = useCallback(
    async (paymentMethod) => {
      if (selectedPaymentMethod === paymentMethod) return;
      setSelectedPaymentMethod(paymentMethod);

      if (paymentMethod === "klarna") {
        refPaypal.current.destroy();
        await refKlarna.current.createOrder({
          ...orderDataSource,
        });
      } else if (paymentMethod === "paypal") {
        refKlarna.current.destroy();
        await refPaypal.current.createButtons();

        if (isMobile) {
          $("html, body").animate(
            { scrollTop: $("#payment-method-paypal").offset().top - 200 },
            1000
          );
        }
      }
    },
    [orderDataSource, selectedPaymentMethod]
  );

  return (
    <>
      <h5>Betalningsmetod</h5>
      <small>
        För att betala med Klarna eller Paypal behöver du inte ett konto. Välj
        kortbetalning efter att ha gjort ditt val.
      </small>
      <section class='radio-section'>
        <div class='radio-list'>
          <div class='radio-item payment-method card'>
            <fieldset
              id='payment-option-fieldset'
              className='d-flex flex-nowrap flex-column'
              onClick={async () => onPaymentSelectHandler("klarna")}
              disabled={isBasketInProgress}
            >
              <div>
                <input
                  name={"pmethod"}
                  type='radio'
                  disabled
                  checked={selectedPaymentMethod === "klarna"}
                />
                <label
                  style={{
                    display: "flex",
                    gap: 10,
                    fontSize: "0.9em",
                  }}
                >
                  <div style={{ maxWidth: 100, height: 25, width: 100 }}>
                    <KlarnaLogo />
                  </div>
                </label>
                <div className='d-inline-flex gap-2 pb-3 pb-md-0 px-3 px-md-2 justify-content-between align-items-center w-100'></div>
              </div>

              <div className='text-center'>
                <Klarna
                  ref={refKlarna}
                  orderDataSource={orderDataSource}
                  onCancel={() => setSelectedPaymentMethod(null)}
                />
              </div>
            </fieldset>
          </div>
          <div
            id='payment-method-paypal'
            class='radio-item payment-method card'
          >
            <fieldset
              id='payment-option-fieldset'
              className='d-flex flex-nowrap flex-column'
              onClick={async () => onPaymentSelectHandler("paypal")}
            >
              <div>
                <input
                  name={"pmethod"}
                  type='radio'
                  disabled
                  checked={selectedPaymentMethod === "paypal"}
                />
                <label
                  style={{
                    display: "flex",
                    gap: 10,
                    fontSize: "0.9em",
                  }}
                >
                  <div style={{ maxWidth: 100, height: 25, width: 100 }}>
                    <PaypalLogo />
                  </div>
                </label>
                <div className='d-inline-flex gap-2 pb-3 pb-md-0 px-3 px-md-2 justify-content-between align-items-center w-100'></div>
              </div>
              <Paypal
                ref={refPaypal}
                orderDataSource={orderDataSource}
                onPaymentSuccess={onPaymentSuccess}
              />
            </fieldset>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(React.forwardRef(PaymentMethods));
