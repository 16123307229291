import React, { useCallback, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { makeResetPasswordRequest } from "../services/userService";
import { toast } from "react-toastify";
import { useUserStore } from "../store/user/userContext";
import { useBasketStore } from "../store/basket/basketContext";
import { logoutAction } from "../store/user/userActions";
import { clearBasketAction } from "../store/basket/basketActions";

export default function ResetPassword() {
  const [userInfo, userDispatch] = useUserStore();
  const [basketInfo, basketDispatch] = useBasketStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [token, setToken] = useState(params.code);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  const submit = useCallback(async (data) => {
    const response = await makeResetPasswordRequest({
      token,
      ...data
    });

    if (response && response.errorMessage == null) {
      toast.success("Password has been changed successfully");
      navigate('/');
    }
  }, [token]);

  useLayoutEffect(() => {
    userDispatch(logoutAction());
    basketDispatch(clearBasketAction());
  }, []);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <section className='row justify-content-center'>
        <div className='col-12 col-lg-6'>
          <div className='card'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-12'>
                  <div className='form-group p-2'>
                    <label>{t("New Password")}</label>
                    <input
                      className='form-control'
                      type='password'
                      autocomplete='new-password'
                      name='password'
                      {...register("password", {
                        required: {
                          value: true,
                          message: t("Password is required"),
                        },
                        pattern: {
                          value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                          message:
                            "Password must be min 8 chars and contain at least one letter and one number",
                        },
                      })}
                      onChange={(x) => setValue("password", x.target.value)}
                    />
                    {errors.password?.message && (
                      <p class='alert'>{errors.password?.message}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='form-group p-2'>
                    <label>{t("New Password Again")}</label>
                    <input
                      className='form-control'
                      type='password'
                      autocomplete='new-password-again'
                      name='passwordAgain'
                      {...register("passwordAgain", {
                        required: {
                          value: true,
                          message: t("Password again is required"),
                        },
                        validate: (value) => {
                          const { password } = getValues();
                          return (
                            value === password || t("Passwords don't match")
                          );
                        }
                      })}
                      onChange={(x) => setValue("passwordAgain", x.target.value)}
                    />
                     {errors.passwordAgain?.message && (
                      <p class='alert'>{errors.passwordAgain?.message}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='form-group p-2 text-end'>
                    <button type="submit" className='btn btn-secondary'>
                      {t("Reset Password")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  );
}
