import axios from "axios";
import _ from 'lodash';

let fnCallback;

export const makeApiRequest = (
  url,
  method,
  data = null,
  authorize = false,
  contentType = "application/json",
  responseType = "json"
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const instance = axios.create({
        headers: {
          "Content-Type": contentType,
        },
        responseType: responseType,
      });

      /*if (authorize) {
        instance.interceptors.request.use((config) => {
          const strAuthInfo = localStorage.getItem("authInfo");
          if (strAuthInfo?.length > 0) {
            const authInfo = JSON.parse(strAuthInfo);
            config.headers.Authorization = `Bearer ${authInfo.token}`;
          }

          return config;
        });
      }*/

      instance.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (fnCallback) fnCallback(error.response);

          return error.response;
        }
      );

      const response = await instance.request({
        method,
        url,
        data,
        contentType,
      });
      const rData = response?.data != null ? _.isString(response.data) ? {} : response.data : {};
      rData.success = response?.status.toString().startsWith("2") ?? false;
      resolve(rData);
    } catch (error) {
      const rData = error.response?.data ?? {};
      rData.success = false;
      reject(rData);
    }
  });
};

export const makeWsRequest = (url, onMessage, token = null) => {
  const reqUrl = token?.length > 0 ? `${url}?token=${token}` : url;
  const ws = new WebSocket(reqUrl);
  ws.onmessage = onMessage;
}

export const registerResponseInterceptorCallback = (callback) => {
  fnCallback = callback;
};
