import { SHOW_LOADING, HIDE_LOADING } from "./loadingActionTypes";

export const showLoading = () => ({
    type: SHOW_LOADING,
    payload: { isLoadingVisible: true }
});

export const hideLoading = () => ({
    type: HIDE_LOADING,
    payload: { isLoadingVisible: false }
});