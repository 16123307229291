import React, { useEffect, useRef, useState } from "react";
import ImageContainer from "./ImageContainer";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import $ from "jquery";

const MainCategoryWithImage = ({ mainCategory }) => {
  const { t } = useTranslation();
  const refContainer = useRef();
  const [shimmerWidth, setShimmerWidth] = useState(0);

  useEffect(() => {
    $(() => {
      setShimmerWidth($(refContainer.current).width());
    });
  }, []);

  return (
    <div
      ref={refContainer}
      className='main-category-container col-12 col-lg-4 mb-2 mb-lg-0'
      style={{ float: "left", maxHeight: 500 }}
    >
      <div>
        <ImageContainer
          shimmerStyle={{ width: shimmerWidth, height: 500 }}
          src={
            mainCategory.image?.imageUrl
              ? `${mainCategory.image?.imageUrl}_original.webp`
              : null
          }
        />
        <div className='lbl-main-cat'>
          <span>{mainCategory.name}</span>
          <Link to={`/category/${mainCategory.urlName}/products`}>{t("Buy Now")}</Link>
        </div>
      </div>
    </div>
  );
};

export default React.memo(MainCategoryWithImage);
