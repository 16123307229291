import React, { useLayoutEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";
import { makeGetFavoriteProductsRequest } from "../services/productService";
import { useProductStore } from "../store/product/productContext";
import Products from "./Products";

export default function Favorites() {
  const [, productDispatch] = useProductStore();
  const [favoriteProductsInternal, setFavoriteProductsInternal] = useState([]);
  const { t } = useTranslation();

  useLayoutEffect(() => {
    async function fetchData() {
      const response = await makeGetFavoriteProductsRequest(productDispatch);
      setFavoriteProductsInternal(response?.favoriteProducts ?? []);
    }
    fetchData();
  }, []);

  return (
    <>
      <div className='row pt-3 pt-md-0'>
        <section class='section-products'>
          <h4>{t("Favourites")}</h4>
          <Products products={favoriteProductsInternal} />
        </section>
      </div>
    </>
  );
}
