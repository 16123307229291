/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useMemo,
  useRef,
  useCallback,
} from "react";
import {
  faHeart,
  faShoppingCart,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  makeInsertFavoriteProductRequest,
  makeDeleteFavoriteProductRequest,
} from "../services/productService";
import { useProductStore } from "../store/product/productContext";
import { useUserStore } from "../store/user/userContext";
import { useBasketStore } from "../store/basket/basketContext";
import { Link } from "react-router-dom";
import ImageContainer from "./ImageContainer";
import { BrowserView, isMobile } from "react-device-detect";
import { toDiscountPercentage } from "../utility/convert";
import { toast } from "react-toastify";
import useMessenger from "./Messenger";
import { useBasketService } from "../store/basket/basketContext";
import { useNavigate } from "react-router-dom";

export default function ProductCard({
  productId,
  productTitle,
  productPrice,
  productOldPrice: productDiscountPrice,
  productImage,
  productFavoriteId,
  productUrlName,
  style,
  className,
  soldOut,
  hasImageUpload,
  containerStyle,
  compact = false,
  hasVariants,
}) {
  const [{ favoriteProducts }, productDispatch] = useProductStore();
  const [{ user }] = useUserStore();
  const [, basketDispatch] = useBasketStore();
  const refSingleProduct = useRef();
  const { showAddedToBasketMessage } = useMessenger();
  const { addToBasket } = useBasketService();
  const navigate = useNavigate();

  const onInsertFavoriteProductClickHandler = async () => {
    await makeInsertFavoriteProductRequest(productDispatch, { productId });
  };

  const onDeleteFavoriteProductClickHandler = async (id) => {
    await makeDeleteFavoriteProductRequest(productDispatch, id);
  };

  const onAddProductToBasketClickHandler = useCallback(async () => {
    try {
      addToBasket({
        id: productId,
        productId,
        quantity: 1,
      });
    } catch (error) {
      console.error(error);
      toast.error("Något gick fel");
    }
  }, [productId, user]);

  const FavoriteContent = useMemo(() => {
    if (!user || isMobile) return null;

    if (favoriteProducts.some((pid) => pid === productId)) {
      return (
        <span
          className='btn-favorite d-none d-md-block liked'
          onClick={(e) => {
            e.preventDefault();
            onDeleteFavoriteProductClickHandler(productId);
          }}
        >
          <FontAwesomeIcon icon={faHeart} />
        </span>
      );
    } else {
      return (
        <span
          className='d-none d-md-block'
          onClick={(e) => {
            e.preventDefault();
            onInsertFavoriteProductClickHandler();
          }}
        >
          <FontAwesomeIcon icon={faHeart} />
        </span>
      );
    }
  }, [user, favoriteProducts, productId, isMobile]);

  const AddToBasketContent = useMemo(() => {
    if (!soldOut) {
      return (
        <span
          className='d-none d-md-block'
          onClick={(e) => {
            e.preventDefault();
            if (hasImageUpload || hasVariants) {
              navigate(`/product/${productUrlName}`);
            } else {
              onAddProductToBasketClickHandler();
            }
          }}
        >
          <FontAwesomeIcon icon={faShoppingCart} />
        </span>
      );
    } else {
      return <></>;
    }
  }, [hasImageUpload, soldOut, hasVariants]);

  return (
    <Link
      to={`/product/${productUrlName}`}
      className={`text-dark no-underlined ${className}`}
      title={productTitle}
      role='button'
      style={{ ...style }}
    >
      <div
        ref={refSingleProduct}
        id={`product-${productId}`}
        className='single-product'
        style={containerStyle ?? {}}
      >
        <div class='part-1'>
          <ImageContainer
            className='product-card-image'
            src={`${productImage}_medium.webp`}
          />
          <BrowserView>
            <ul>
              <li>{AddToBasketContent}</li>
              <li>{FavoriteContent}</li>
            </ul>
          </BrowserView>
          {!soldOut && productDiscountPrice?.length > 0 && (
            <div className='discount-icon'>
              <span>{`-${toDiscountPercentage(
                productPrice,
                productDiscountPrice
              )}%`}</span>
            </div>
          )}
        </div>
        <div class='part-2 p-2 pb-0'>
          <div className='mb-2'>
            <h3 class='product-title mb-1 fw-bold font-size-1-2'>
              {productTitle}
            </h3>
            {!soldOut ? (
              <div className='font-size-0-7 opacity-0-6'>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{ color: "#25cb15", marginRight: 2 }}
                />
                <span className='text-uppercase'>Finns i lager</span>
              </div>
            ) : (
              <div className='font-size-0-7 opacity-0-6 '>
                <span className='text-uppercase'>Slutsåld</span>
              </div>
            )}
          </div>
          <div className='d-flex flex-wrap justify-content-between'>
            <h4 class='text-nowrap product-price'>
              {!soldOut && productDiscountPrice?.length > 0 ? (
                <span className='product-discount-price'>{`${productDiscountPrice} kr`}</span>
              ) : (
                <span className='fw-bold font-size-1-1'>{`${productPrice} kr`}</span>
              )}
            </h4>
            {!soldOut && productDiscountPrice?.length > 0 && (
              <div className='product-old-price'>
                {!compact && <span>Ord.pris</span>}
                <h4 className='my-0'>{`${productPrice} kr`}</h4>
                <span>{`(-${toDiscountPercentage(
                  productPrice,
                  productDiscountPrice
                )}%)`}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}
