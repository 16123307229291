import React, { useMemo } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import ImageContainer from "./ImageContainer";

const ProductSlider = ({ title }) => {
  const slideImages = useMemo(() => [
    {
      url: require("../images/banner3.webp"),
    },
    {
      url: require("../images/banner1.webp"),
    },
    {
      url: require("../images/banner2.webp"),
    },
  ], []);

  const divStyle = useMemo(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 300,
  }), []);

  return (
    <div className='slide-container'>
      <Slide
        canSwipe={slideImages.length > 1}
        autoplay={slideImages.length > 1}
        arrows={false}
      >
        {slideImages.map((slideImage, index) => (
          <div key={index}>
            <ImageContainer
              style={{ ...divStyle }}
              shimmerStyle={{
                height: divStyle.height,
              }}
              src={slideImage.url}
            />
          </div>
        ))}
      </Slide>
    </div>
  );
}

export default React.memo(ProductSlider);