import ReactGA from "react-ga4";
import { acceptedCategory } from "vanilla-cookieconsent";

export const addToCart = (product, quantity) => {
  if (acceptedCategory("analytics")) {
    ReactGA.event("add_to_cart", {
      items: [
        {
          item_id: product.productCode,
          item_name: product.name,
          quantity: quantity,
          price: product.discountPrice ?? product.price,
        },
      ],
    });
  }
};

export const removeFromCart = (items, quantity = null) => {
  if (acceptedCategory("analytics")) {
    ReactGA.event("remove_from_cart", {
      currency: "SEK",
      value: items.reduce(
        (total, item) =>
          total +
          (item.product.discountPrice ?? item.product.price) *
            (quantity ?? item.quantity),
        0
      ),
      items: items.map((item) => ({
        item_id: item.product.productCode,
        item_name: item.product.name,
        quantity: quantity ?? item.quantity,
        price: item.product.discountPrice ?? item.product.price,
      })),
    });
  }
};

export const beginCheckout = (items) => {
  if (acceptedCategory("analytics")) {
    ReactGA.event("begin_checkout", {
      currency: "SEK",
      value: items.reduce(
        (total, item) =>
          total +
          (item.product.discountPrice ?? item.product.price) * item.quantity,
        0
      ),
      items: items.map((item) => ({
        item_id: item.product.productCode,
        item_name: item.product.name,
        quantity: item.quantity,
        price: item.product.discountPrice ?? item.product.price,
      })),
    });
  }
};

export const addShippingInfo = (shipping, items) => {
  if (acceptedCategory("analytics")) {
    ReactGA.event("add_shipping_info", {
      currency: "SEK",
      value: items.reduce(
        (total, item) =>
          total +
          (item.product.discountPrice ?? item.product.price) * item.quantity,
        0
      ),
      items: items.map((item) => ({
        item_id: item.product.productCode,
        item_name: item.product.name,
        quantity: item.quantity,
        price: item.product.discountPrice ?? item.product.price,
      })),
      shipping_tier: shipping,
    });
  }
};

export const purchase = (orderId, amount, tax, shipping, products) => {
  if (acceptedCategory("analytics")) {
    ReactGA.event("purchase", {
      transaction_id: orderId,
      value: amount,
      currency: "SEK",
      shipping: shipping,
      tax: tax,
      items: products.map((product) => ({
        item_id: product.id,
        item_name: product.name,
        quantity: product.quantity,
        price: product.discountPrice ?? product.price,
      })),
    });
  }
};

export const viewProduct = (product) => {
  if (acceptedCategory("analytics")) {
    ReactGA.event("view_item", {
      currency: "SEK",
      value: product.discountPrice ? product.discountPrice : product.price,
      items: [
        {
          item_id: product.productCode,
          item_name: product.name,
          price: product.discountPrice ? product.discountPrice : product.price,
        },
      ],
    });
  }
};

export const viewCategory = (categoryUrl, categoryName) => {
  if (acceptedCategory("analytics")) {
    ReactGA.event("view_item_list", {
      item_list_id: categoryUrl,
      item_list_name: categoryName,
    });
  }
};
