import React, { useState, useCallback, useEffect } from "react";
import Products from "./Products";
import { makeGetProductByMainCategoryRequest } from "../services/productService";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { viewCategory } from "../utility/googleEvents";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Skeleton from "react-loading-skeleton";

export default function CategoryProducts() {
  const params = useParams();
  const [products, setProducts] = useState(null);
  const [category, setCategory] = useState(null);
  const [sortOptions, setSortOptions] = useState(null);
  const [selectedSortOption, setSelectedSortOption] = useState(null);
  const [ddSortByOpen, setDdSortByOpen] = useState(false);
  const [isSkeletonVisible, setIsSkeletonVisible] = useState(false);
  const { t } = useTranslation();

  const getProductByMainCategory = useCallback(
    async (sort = null) => {
      const response = await makeGetProductByMainCategoryRequest(
        params.urlName,
        sort
      );
      setProducts(response?.products);
      setCategory(response?.mainCategory);
      setSortOptions(response?.sortOptions);
      setSelectedSortOption(response?.sort);

      return response;
    },
    [params.urlName]
  );

  useEffect(() => {
    setProducts(null);
    const skeletonTimeout = setTimeout(() => {
      setIsSkeletonVisible(true);
    }, 1000);

    async function fetchData() {
      const response = await getProductByMainCategory();
      document.title = `${
        response?.mainCategory?.name ? `${response?.mainCategory?.name} - ` : ""
      }Buket Present`;
      viewCategory(
        response?.mainCategory?.urlName,
        response?.mainCategory?.name
      );
      clearTimeout(skeletonTimeout);
      setIsSkeletonVisible(false);
    }
    fetchData();
  }, [params.urlName]);

  return (
    <>
      <Helmet>
        <title>
          {category?.name ? `${category?.name} - ` : ""}Buket Present
        </title>
        <meta
          rel='canonical'
          href={`https://buketpresent.com/category/${category?.urlName}/products`}
        />
        <meta
          name='description'
          content={`Smycken - Dekoration - Personliga Produkter - Handgjorda Presenter - Presentförslag för Födelsedag - Present till Mig Själv - Örhängen i Vintagestil - Presenter till Det Nya Hemmet - Present till Valentine - Smycken i Stål - Rökelsbrännare`}
        />
      </Helmet>
      <section class='section-products pt-4 pt-md-0'>
        <div className='category-title pb-2 pb-md-0'>
          <h4 className='mb-0 mb-md-1'>{t(category?.name ?? "")}</h4>
          {sortOptions && (
            <Dropdown
              isOpen={ddSortByOpen}
              toggle={() => setDdSortByOpen((x) => !x)}
              className='pt-1 pb-1'
            >
              <DropdownToggle
                id='dd-sort-by'
                tag={"button"}
                size='sm'
                className='btn btn-link'
                caret
              >
                {sortOptions[selectedSortOption]}
              </DropdownToggle>
              <DropdownMenu>
                {Object.keys(sortOptions).map((key) => (
                  <DropdownItem
                    active={key == selectedSortOption}
                    onClick={async () => await getProductByMainCategory(key)}
                  >
                    {sortOptions[key]}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
        {products ? (
          <Products products={products} />
        ) : (
          <>
            {isSkeletonVisible && (
              <div className='row'>
                <Skeleton
                  count={5}
                  height={416}
                  className='mb-5'
                  containerClassName={"col-12 col-md-6 col-lg-4"}
                />
                <Skeleton
                  count={5}
                  height={416}
                  className='mb-5'
                  containerClassName={"col-12 col-md-6 col-lg-4"}
                />
                <Skeleton
                  count={5}
                  height={416}
                  className='mb-5'
                  containerClassName={"col-12 col-md-6 col-lg-4"}
                />
              </div>
            )}
          </>
        )}
      </section>
    </>
  );
}
