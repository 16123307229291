import { makeApiRequest } from "../api";

export const makeGetImageRequest = async (url) => {
  try {
    const response = await makeApiRequest(url, "GET", null, true, "application/json", "arraybuffer");
    var blob = new Blob([response], { type: "image/png" });
    var urlCreator = window.URL || window.webkitURL;
    var imageUrl = urlCreator.createObjectURL( blob );

    return imageUrl;
  } catch (e) {
    return e;
  }
};

export const makeUpdateImageRequest = async (data, basketId, imageId) => {
  try {
    const response = await makeApiRequest(
      `/users/current/basket/${basketId}/images/${imageId}`,
      "PUT",
      data,
      true,
      'multipart/form-data'
    );
    
    return response;
  } catch (e) {
    return e;
  }
};
