import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import {
  PayPalScriptProvider,
  PayPalButtons,
  FUNDING
} from "@paypal/react-paypal-js";
import { makeCapturePaypalPaymentRequest } from "../../services/paypalService";
import { makeCreatePaypalPaymentRequest } from "../../services/paypalService";
import { useBasketStore } from "../../store/basket/basketContext";
import { useUserStore } from "../../store/user/userContext";
import { v4 as uuidv4 } from "uuid";

const Paypal = ({ orderDataSource, onPaymentSuccess }, ref) => {
  const [{ language, user }] = useUserStore();
  const [{ basket }] = useBasketStore();
  const paypalLocale = useMemo(
    () => "sv_SE",
    [language]
  );
  const [buttonsAreVisible, setButtonsAreVisible] = useState(false);
  const [reRenderValue, setReRenderValue] = useState(uuidv4());

  const onCreateOrderHandler = useCallback(async () => {
    try {
      let basketItems = null;
      if (!user) {
        basketItems = basket;
      }

      const response = await makeCreatePaypalPaymentRequest({
        ...orderDataSource,
        basketItems,
      });

      if (response?.success && response?.orderId?.length > 0) {
        return response.orderId;
      }
    } catch (error) {
      return null;
    }
  }, [orderDataSource, user, basket]);

  const createButtons = useCallback(() => {
    setButtonsAreVisible(true);
  }, []);

  const updateButtons = useCallback(() => {
    setReRenderValue(uuidv4());
  }, []);

  const destroy = useCallback(() => {
    setButtonsAreVisible(false);
  }, []);

  useImperativeHandle(ref, () => ({
    createButtons,
    destroy,
    updateButtons,
  }));

  useEffect(() => {
    if (buttonsAreVisible) {
      setReRenderValue(uuidv4());
    }
  }, [basket])

  return (
    <div>
      <PayPalScriptProvider
        options={{
          clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
          enableFunding: [
            FUNDING.CARD,
            FUNDING.CREDIT,
            FUNDING.PAYPAL,
          ],
          dataSdkIntegrationSource: "integrationbuilder_sc",
          locale: paypalLocale,
          currency: "SEK",
          components: "buttons",
        }}
      >
        <PayPalButtons         
          forceReRender={[
            reRenderValue,
            buttonsAreVisible,
          ]}
          style={{
            layout: "vertical",
            shape: "rect",
            disableMaxWidth: true,
            label: "buynow",
            color: "gold",
          }}
          className='paypal-buttons-custom px-xl-5'
          createOrder={onCreateOrderHandler}
          disabled={!buttonsAreVisible}
          onApprove={async (data, actions) => {
            const response = await makeCapturePaypalPaymentRequest({
              orderId: data.orderID,
            });

            if (response?.success) {
              onPaymentSuccess(response);
            }
          }}         
        />
      </PayPalScriptProvider>
    </div>
  );
};

export default React.memo(React.forwardRef(Paypal));
