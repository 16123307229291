import React from "react";
import { Button } from "reactstrap";
import * as cookieConsent from "vanilla-cookieconsent";
import { Helmet } from "react-helmet";

export default function CookiePolicy({
  onSettingsClick = () => cookieConsent.showPreferences(),
}) {
  return (
    <div id={"cookie-policy"}>
      <Helmet>
        <title>Cookiepolicy - Buket Present</title>
        <meta name='robots' content='noindex, nofollow' />
        <meta name='googlebot' content='noindex, nofollow' />
      </Helmet>
      <h1>Cookiepolicy</h1>
      <p>
        Denna Cookiepolicy ger information om de cookies och liknande
        teknologier som används på buketpresent.se, som drivs av Buket Present.
        Här förklarar vi vad cookies är, vilka typer av cookies vi använder, hur
        vi använder dem och hur du kan hantera dina cookieinställningar.
      </p>

      <h2>Vad är Cookies?</h2>
      <p>
        Cookies är små datafiler som placeras på din enhet när du besöker en
        webbplats. Cookies används för att göra webbplatsen mer effektiv och för
        att utföra vissa funktioner. De kan också användas för att analysera
        webbplatstrafik och anpassa användarupplevelsen.
      </p>

      <h2>Typer av Cookies vi använder</h2>

      <h3>1. Nödvändiga Cookies</h3>
      <p>
        Nödvändiga cookies är oumbärliga för att vår webbplats ska fungera
        korrekt. Dessa cookies säkerställer grundläggande funktioner som
        säkerhet, språk- och regioninställningar. Utan dessa cookies kan vissa
        delar av vår webbplats inte fungera korrekt.
      </p>
      <ul>
        <li>
          <strong>Sessionscookies:</strong> Lagrar användarsessionsinformation.
        </li>
        <li>
          <strong>Säkerhetscookies:</strong> Används för att säkerställa
          säkerhet och förebygga bedrägeri.
        </li>
        <li>
          <strong>Preferenscookies:</strong> Kommer ihåg användarens språk- och
          regioninställningar.
        </li>
      </ul>

      <h3>2. Prestanda och Analytiska Cookies</h3>
      <p>
        Dessa cookies hjälper oss att förstå hur besökare använder vår
        webbplats, förbättra prestandan och analysera användarbeteenden. Till
        exempel använder vi Google Analytics för att spåra besökstrafik och
        samla in anonym data.
      </p>
      <ul>
        <li>
          <strong>Google Analytics Cookies:</strong> Dessa cookies används av
          Google för att övervaka och analysera webbplatsens prestanda. De
          insamlade uppgifterna är anonyma och hjälper oss att förstå
          användarbeteenden.
        </li>
      </ul>

      <h2>Användningsändamål för Cookies</h2>
      <p>Vi använder cookies för följande ändamål:</p>
      <ul>
        <li>För att säkerställa grundläggande funktioner på vår webbplats.</li>
        <li>För att analysera och förbättra webbplatsens prestanda.</li>
        <li>För att förbättra och anpassa användarupplevelsen.</li>
      </ul>

      <h2>Hantera Cookies</h2>
      <p>
        Du kan när som helst ändra dina cookiepreferenser. Besök sidan{" "}
        <Button
          id={"cookie-settings-link"}
          className={"btn-link p-0 link-secondary"}
          onClick={onSettingsClick}
        >
          <span>Cookie Inställningar</span>
        </Button>{" "}
        för att hantera dina inställningar. Du kan också hantera eller
        inaktivera cookies via din webbläsares inställningar. Observera att
        vissa delar av vår webbplats kanske inte fungerar korrekt om cookies
        inaktiveras.
      </p>

      <h2>Tredjepartscookies</h2>
      <p>
        Vår webbplats kan också använda cookies som placeras av
        tredjepartsleverantörer. Till exempel använder vi cookies från Google
        Analytics för att övervaka webbplatsens prestanda. För mer information
        om tredjepartscookies, vänligen läs de berörda
        tredjepartsleverantörernas cookiepolicyer.
      </p>

      <h2>Ändringar i vår Cookiepolicy</h2>
      <p>
        Denna Cookiepolicy kan uppdateras från tid till annan. Eventuella
        ändringar kommer att publiceras på vår webbplats. Vi rekommenderar att
        du regelbundet kontrollerar denna sida för att hålla dig informerad om
        eventuella uppdateringar.
      </p>

      <h2>Kontakt</h2>
      <p>
        Om du har några frågor om denna Cookiepolicy eller vill utöva dina
        rättigheter, vänligen kontakta oss:
      </p>
      <ul>
        <li>
          <strong>E-post:</strong>{" "}
          <a href='mailto:info@buketpresent.se'>info@buketpresent.se</a>
        </li>
        <li>
          <strong>Adress:</strong>Pantbrevsgatan 1A 22643 Lund
        </li>
      </ul>
    </div>
  );
}
