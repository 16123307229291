import React, { useMemo } from "react";
import { useProductStore } from "../store/product/productContext";
import MainCategoryWithImage from "./MainCategoryWithImage";

const MainCategoriesWithImage = () => {
  const [{ mainCategoriesWithImage }, productDispatch] = useProductStore();
  
  const content = useMemo(
    () =>
      mainCategoriesWithImage?.map((x) => (
        <MainCategoryWithImage mainCategory={x} />
      )),
    [mainCategoriesWithImage]
  );

  return <>{content}</>;
}

export default React.memo(MainCategoriesWithImage);