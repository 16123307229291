import { faAngleLeft, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { Nav, Navbar, NavbarBrand, NavItem } from "reactstrap";

const BasketNavMenu = () => {
  return (
    <header>
      <Navbar
        className='navbar-expand-md navbar-toggleable-md ng-white border-bottom'
        container
        light
        fixed='top'
        id='nav-main'
      >
        <Nav className='justify-content-between w-100 align-items-center'>
          <NavItem className='col-4 d-none d-md-flex'>
            <Link to={"/"} className='nav-link btn-buketpresent-link'>
              <FontAwesomeIcon icon={faAngleLeft} />
              <span className="px-1">Fortsätt handla</span>
            </Link>
          </NavItem>
          <NavItem className='d-flex align-items-center justify-content-center col-12 col-md-4'>
            <NavbarBrand data-toggle='collapse' tag={Link} to='/'>
              <div
                className='logo'
                style={{ backgroundImage: 'url("/images/logo.png")' }}
              ></div>
            </NavbarBrand>
          </NavItem>
          <NavItem className='col-4 d-none d-md-flex flex-column align-items-end'>
            <span>Behöver du hjälp?</span>
            <a href='mailto:info@buketpresent.se'>kontakta oss</a>
          </NavItem>
        </Nav>
      </Navbar>
    </header>
  );
};

export default React.memo(BasketNavMenu);
