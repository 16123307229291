import React, { useReducer } from 'react';
import loadingStore, { initialState } from './loadingStore';

export const LoadingContext = React.createContext();

export const useLoadingStore = () => React.useContext(LoadingContext);

export default function LoadingProvider(props) {
    const [state, dispatch] = useReducer(loadingStore, initialState);
    return <LoadingContext.Provider value={[state, dispatch]}>{props.children}</LoadingContext.Provider>;
}