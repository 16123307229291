import { makeApiRequest } from "../api";
import {
  addProductToBasketAction,
  getBasketAction,
  updateQuantityAction,
  deleteProductFromBasketAction,
  clearBasketAction,
  getShipmentOptionsAction,
  basketInProgressAction,
} from "../store/basket/basketActions";
import { dispatcher } from "../store/dispatcher";

export const makeGetBasketRequest = async (dispatch, items = null) => {
  try {
    const response = await makeApiRequest(
      `/users/current/basket${items?.length > 0 ? `?items=${items}` : ""}`,
      "GET",
      null,
      true
    );
    dispatcher(dispatch, getBasketAction, response);
    dispatcher(dispatch, getShipmentOptionsAction, response);

    return response;
  } catch (e) {
    return e;
  }
};

export const makeGetBasketWithOptionsRequest = async (
  dispatch,
  shipmentOptionId = null,
  promotionCode = null,
  items = null
) => {
  dispatcher(dispatch, basketInProgressAction);
  try {
    const response = await makeApiRequest(
      `/users/current/basket`,
      "PATCH",
      {
        shipmentOptionId,
        promotionCode,
        items,
      },
      true
    );
    dispatcher(dispatch, getBasketAction, response);
    dispatcher(dispatch, getShipmentOptionsAction, response);

    return response;
  } catch (e) {
    return e;
  }
  finally {
    dispatch(basketInProgressAction(false));
  }
};

export const makeSaveBasketItemRequest = async (dispatch, data) => {
  try {
    const response = await makeApiRequest(
      "/users/current/basket",
      "POST",
      data,
      true,
      "multipart/form-data"
    );
    dispatcher(dispatch, addProductToBasketAction, response);

    return response;
  } catch (e) {
    return e;
  }
};

export const makeUpdateQuantityRequest = async (
  dispatch,
  id,
  quantity,
  shipmentOptionId,
  promotionCode
) => {
  try {
    const response = await makeApiRequest(
      `/users/current/basket/${id}/quantity`,
      "PATCH",
      {
        quantity,
        shipmentOptionId,
        promotionCode,
      },
      true
    );
    dispatcher(dispatch, updateQuantityAction, response);
    dispatcher(dispatch, getShipmentOptionsAction, response);

    return response;
  } catch (e) {
    return e;
  }
};

export const makeDeleteBasketItemRequest = async (
  dispatch,
  id,
  shipmentOptionId,
  promotionCode
) => {
  try {
    const response = await makeApiRequest(
      `/users/current/basket/${id}`,
      "PUT",
      {
        shipmentOptionId,
        promotionCode,
      },
      true
    );
    dispatcher(dispatch, deleteProductFromBasketAction, response);
    dispatcher(dispatch, getShipmentOptionsAction, response);

    return response;
  } catch (e) {
    return e;
  }
};

export const makeClearBasketRequest = async (dispatch) => {
  try {
    const response = await makeApiRequest(
      `/users/current/basket/clear`,
      "DELETE",
      null,
      true
    );
    dispatcher(dispatch, clearBasketAction);

    return response;
  } catch (e) {
    return e;
  }
};

export const makeGetShipmentOptionsRequest = async ({ items = null }) => {
  try {
    const response = await makeApiRequest(
      `/users/current/basket/shipmentoptions${
        items?.length > 0 ? `?items=${items}` : ""
      }`,
      "GET",
      null,
      false
    );

    return response;
  } catch (e) {
    return e;
  }
};
