/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "react-i18next";
import {
  faExternalLink,
  faMinus,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { rawStringToUploadImage } from "../utility/convert";
import ImageContainer from "./ImageContainer";
import _ from "lodash";
import $ from "jquery";
import { Link } from "react-router-dom";
import {
  useBasketService,
  useBasketStore,
} from "../store/basket/basketContext";
import { addToCart, removeFromCart } from "../utility/googleEvents";
import { toDiscountPercentage } from "../utility/convert";

export default function BasketItem({
  item,
  onImageFilePickHandler,
  onUploadImageClickHandler,
  onProductClickCallback = () => {},
}) {
  const [{ isBasketInProgress }] = useBasketStore();
  const { updateQuantity, deleteBasketItem } = useBasketService();
  const [uploadImages] = useState(
    item.product.uploadImages?.map(rawStringToUploadImage)
  );
  const [shimmerWidth, setShimmerWidth] = useState(0);
  const refImgDiv = useRef();

  const onProductClickHandler = useCallback(() => {
    onProductClickCallback();
  }, []);

  useEffect(() => {
    $(() => {
      setShimmerWidth($(refImgDiv.current).width());
    });
  }, []);

  return (
    <div class='row d-flex justify-content-left align-items-center'>
      <div className='d-flex justify-content-between p-4 w-100'>
        <div ref={refImgDiv} class='d-flex col-2'>
          <Link
            className='d-block w-100 align-content-top pt-2'
            to={`/product/${item.product.urlName}${
              item.product.hasVariants
                ? `?variant=${item.product.variant.toLowerCase()}`
                : ""
            }`}
            onClick={onProductClickHandler}
            title={item.product.name}
          >
            <ImageContainer
              class='img-fluid rounded-3'
              src={`${_.first(item.product?.imageUrls)?.imageUrl}_small.webp`}
              shimmerStyle={{
                width: shimmerWidth,
                height: 75,
              }}
              style={{ maxHeight: 280 }}
            />
          </Link>
        </div>
        <div className='d-flex flex-column col-10 gap-2 justify-content-evenly'>
          <div className='d-flex justify-content-between col-12'>
            <div className='px-2 align-content-center font-size-1-2 d-flex flex-column'>
              <Link
                title={item.product.name}
                className='text-decoration-none fw-bold text-dark'
                to={`/product/${item.product.urlName}${
                  item.product.hasVariants
                    ? `?variant=${item.product.variant.toLowerCase()}`
                    : ""
                }`}
                onClick={onProductClickHandler}
              >
                {item.product.name}
              </Link>
              <div className="d-flex gap-2">
                {item.product.variant && (
                  <span className='font-size-0-9'>{item.product.variant}</span>
                )}
                {item.size && (
                  <span className='badge bg-secondary font-size-0-8'>{item.size}</span>
                )}
              </div>
            </div>
            <div class='text-end'>
              <button
                className='btn btn-link text-danger'
                onClick={async () => await deleteBasketItem(item)}
                disabled={isBasketInProgress}
                title='Ta bort föremål från varukorgen'
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
          <div className='d-flex justify-content-between col-12'>
            <div className='px-2 align-content-center'>
              {item.product.discountPrice ? (
                <span className='product-discount-price'>{`${item.product.discountPrice.toFixed(
                  2
                )} kr`}</span>
              ) : (
                <span className='font-size-1-2'>{`${item.product.price.toFixed(
                  2
                )} kr`}</span>
              )}

              {item.product.discountPrice && (
                <div className='product-old-price font-size-0-9'>
                  <span className='d-none d-md-inline opacity-0-7'>
                    Ord.pris
                  </span>
                  <span class='text-decoration-line-through px-0 px-md-1 margin-right-5 opacity-0-7'>
                    {`${item.product.price.toFixed(2)} kr`}
                  </span>
                  <span>
                    <small>
                      {`(-${toDiscountPercentage(
                        item.product.price,
                        item.product.discountPrice
                      )}%)`}
                    </small>
                  </span>
                </div>
              )}
            </div>
            <div class='d-flex align-items-center'>
              <button
                class='btn btn-link px-2'
                onClick={async () => {
                  await updateQuantity(item, item.quantity - 1);
                  removeFromCart([item], 1);
                }}
                disabled={
                  item.quantity <= 0 ||
                  item.isStockInSufficient ||
                  isBasketInProgress
                }
              >
                <FontAwesomeIcon icon={faMinus} />
              </button>

              <input
                min={0}
                max={10}
                name='quantity'
                value={item.quantity}
                type='number'
                className='form-control form-control-sm text-center'
                style={{ width: 55, height: 30 }}
                readOnly
                disabled={
                  item.quantity >= item.maxQuantity || item.isStockInSufficient
                }
              />

              <button
                class='btn btn-link px-2'
                onClick={async () => {
                  await updateQuantity(item, item.quantity + 1);
                  addToCart(item.product, 1);
                }}
                disabled={
                  item.quantity >= item.maxQuantity ||
                  item.hasError ||
                  isBasketInProgress
                }
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
          </div>

          {!item.hasError && item.basketItemImages?.length > 0 && (
            <div
              className='px-2'
              style={{
                display: "inline-flex",
                gap: 5,
                flexFlow: "wrap",
              }}
            >
              {item.basketItemImages?.map((y) => {
                const uImg = uploadImages?.find((u) => u.id === y.imageId);
                return (
                  <>
                    {uImg && (
                      <>
                        <button
                          className='btn btn-outline-secondary btn-sm'
                          style={{
                            fontSize: 10,
                            whiteSpace: "nowrap",
                          }}
                          onClick={() => onUploadImageClickHandler(y)}
                        >
                          <span style={{ marginRight: 5 }}>
                            {uImg.label ?? y.fileName}
                          </span>
                          <FontAwesomeIcon icon={faExternalLink} />
                        </button>
                        <input
                          id={y.id}
                          type='file'
                          onChange={(y) =>
                            onImageFilePickHandler(y.target.files, uImg)
                          }
                          hidden
                          accept='image/png,image/jpeg'
                        />
                      </>
                    )}
                  </>
                );
              })}
            </div>
          )}
          {item.hasError && (
            <p className='alert'>
              <Trans
                i18nKey={item.errorMessage}
                values={{
                  maxQuantity: item.maxQuantity,
                }}
              />
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
