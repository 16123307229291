import Addresses from "./components/Addresses";
import Basket from "./components/Basket";
import Favorites from "./components/Favorites";
import Home from "./components/Home";
import PaymentConfirm from "./components/Payment/PaymentConfirm";
import Product from "./components/Product";
import Profile from "./components/Profile";
import SpecialDays from "./components/SpecialDays";
import AuthorizeMiddleware from "./middleware/AuthorizeMiddleware";
import Orders from "./components/Orders";
import CategoryProducts from "./components/CategoryProducts";
import EmailVerification from "./components/EmailVerification";
import ResetPassword from "./components/ResetPassword";
import ReturnPolicy from "./components/ReturnPolicy";
import TopBanner from "./components/TopBanner";
import CookiePolicy from "./components/CookiePolicy";

const AppRoutes = [
  {
    index: true,
    element: (
      <>
        <TopBanner />
        <Home />
      </>
    ),
    footer: true,
  },
  {
    path: "/profile",
    element: (
      <AuthorizeMiddleware>
        <Profile />
      </AuthorizeMiddleware>
    ),
    footer: true,
  },
  {
    path: "/specialdays",
    element: (
      <AuthorizeMiddleware>
        <SpecialDays />
      </AuthorizeMiddleware>
    ),
    footer: true,
  },
  {
    path: "/favorites",
    element: (
      <AuthorizeMiddleware>
        <Favorites />
      </AuthorizeMiddleware>
    ),
    footer: true,
  },
  {
    path: "/basket",
    element: <><TopBanner /><Basket /></>,
    footer: true,
  },
  {
    path: "/paymentconfirm",
    element: (
      <AuthorizeMiddleware>
        <PaymentConfirm />
      </AuthorizeMiddleware>
    ),
    footer: true,
  },
  {
    path: "/orders",
    element: (
      <AuthorizeMiddleware>
        <Orders />
      </AuthorizeMiddleware>
    ),
    footer: true,
  },
  {
    path: "/product/:urlName",
    element: (
      <>
        <TopBanner />
        <Product />
      </>
    ),
    footer: true,
  },
  {
    path: "/category/:urlName/products",
    element: (
      <>
        <TopBanner />
        <CategoryProducts />
      </>
    ),
    footer: false,
  },
  {
    path: "/verification/:code",
    element: <EmailVerification />,
    footer: true,
  },
  {
    path: "/resetpassword/:code",
    element: <ResetPassword />,
    footer: true,
  },
  {
    path: "/returnpolicy",
    element: (
      <>
        <TopBanner />
        <ReturnPolicy />
      </>
    ),
    footer: true,
  },
  {
    path: "/paymentconfirmation",
    element: (
      <>
        <TopBanner />
        <PaymentConfirm />
      </>
    ),
    footer: false,
  },
  {
    path: "/cookie-policy",
    element: (
      <>
        <CookiePolicy />
      </>
    ),
    footer: true,
  },
];

export default AppRoutes;
