import { makeApiRequest } from "../api";
import { basketInProgressAction } from "../store/basket/basketActions";

export const makeCreateKlarnaSessionRequest = async ({
  shipmentOptionId,
  promotionCode,
  basketItems,
}) => {
  const response = await makeApiRequest(
    "/users/current/basket/payment/klarna/session",
    "POST",
    {
      shipmentOptionId,
      promotionCode,
      basketItems,
    },
    true
  );
  return response;
};

export const makeUpdateKlarnaSessionRequest = async (sessionId, {
  shipmentOptionId,
  promotionCode,
  basketItems,
}) => {
  const response = await makeApiRequest(
    `/users/current/basket/payment/klarna/session/${sessionId}`,
    "PUT",
    {
      shipmentOptionId,
      promotionCode,
      basketItems,
    },
    true
  );
  return response;
};

export const makeCreateKlarnaOrderRequest = async (basketDispatch, {
  addressId,
  note,
  shipmentOptionId,
  promotionCode,
  basketItems,
  isDeliveryAddressSame,
  authorizationToken
}) => {
  basketDispatch(basketInProgressAction(true));
  const response = await makeApiRequest(
    "/users/current/basket/payment/klarna/order",
    "POST",
    {
      addressId,
      note,
      shipmentOptionId,
      promotionCode,
      basketItems,
      isDeliveryAddressSame,
      authorizationToken
    },
    true
  );
  basketDispatch(basketInProgressAction(false));
  return response;
};


export const makeConfirmKlarnaOrderRequest = async ({ orderId }) => {
  const response = await makeApiRequest(
    `/users/current/basket/payment/klarna/confirm?klarna_order_id=${orderId}`,
    "GET"
  );
  return response;
};
