import * as actionTypes from "./basketActionTypes";

export const getBasketAction = (payload) => {
  localStorage.setItem("basketCount", payload.itemCount);

  return {
    type: actionTypes.GET_BASKET,
    payload,
  };
};

export const reloadBasketAction = () => {
  const strBasketCount = localStorage.getItem("basketCount");
  let basketCount = 0;

  if (!isNaN(strBasketCount)) {
    basketCount = Number(strBasketCount);
  }

  return {
    type: actionTypes.RELOAD_BASKET,
    payload: { basket: null, itemCount: basketCount },
  };
};

export const clearBasketAction = () => {
  localStorage.removeItem("basketCount");
  localStorage.removeItem("basket");

  return {
    type: actionTypes.CLEAR_BASKET,
    payload: { itemCount: 0, basket: null, totalPrice: 0 },
  };
};

export const addProductToBasketAction = (payload) => ({
  type: actionTypes.ADD_PRODUCT_TO_BASKET,
  payload,
});

export const addProductToBasketAnnonymousAction = (payload) => ({
  type: actionTypes.ADD_PRODUCT_TO_BASKET_ANONYMOUS,
  payload,
});

export const updateProductAnnonymousAction = (payload) => ({
  type: actionTypes.UPDATE_PRODUCT_ANONYMOUS,
  payload,
});

export const deleteProductFromBasketAction = (payload) => ({
  type: actionTypes.DELETE_PRODUCT_FROM_BASKET,
  payload,
});

export const updateQuantityAction = (payload) => ({
  type: actionTypes.UPDATE_QUANTITY,
  payload,
});

export const getShipmentOptionsAction = (payload) => ({
  type: actionTypes.GET_SHIPMENT_OPTIONS,
  payload,
});

export const basketInProgressAction = (inProgress = true) => ({
  type: actionTypes.BASKET_IN_PROGRESS,
  payload: { isBasketInProgress: inProgress },
});
