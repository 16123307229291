import React, { useCallback, useState } from "react";
import BasketItem from "./BasketItem";
import { useBasketStore } from "../store/basket/basketContext";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ImageEditModal from "./ImageEditModal";
import ImageViewModal from "./ImageViewModal";
import _ from "lodash";
import { makeUpdateImageRequest } from "../services/mediaService";

const BasketItems = ({
  onProductClickCallback = () => {},
}) => {
  const [{ basket }] =
    useBasketStore();
  const [currentUploadImage, setCurrentUploadImage] = useState(null);
  const [isImageEditModalOpen, setIsImageEditModalOpen] = useState(false);
  const [currentBasketItemImage, setCurrentBasketItemImage] = useState(null);
  const [isImageViewModalOpen, setIsImageViewModalOpen] = useState(false);
  const { t } = useTranslation();

  const onImageViewModalCloseClickHandler = useCallback(
    (isEdit) => {
      setIsImageViewModalOpen(false);

      if (isEdit) {
        document.getElementById(currentBasketItemImage?.id)?.click();
      }
    },
    [currentBasketItemImage]
  );

  const onImageEditModalCloseClickHandler = useCallback(() => {
    setIsImageEditModalOpen(false);
  }, []);

  const onImageFilePickCompleteHandler = useCallback(
    async (blob) => {
      try {
        setIsImageEditModalOpen(false);
        const formData = new FormData();
        formData.append("file", blob);
        await makeUpdateImageRequest(
          formData,
          currentBasketItemImage.basketId,
          currentBasketItemImage.imageId
        );
        toast.success(t("Updated successfully"));
      } catch (error) {}
    },
    [currentBasketItemImage]
  );
  const onImageFilePickHandler = useCallback((files, uploadImage) => {
    setCurrentUploadImage({
      id: uploadImage.id,
      filePath: URL.createObjectURL(files[0]),
      width: uploadImage.width,
      height: uploadImage.height,
    });
    setIsImageEditModalOpen(true);
  }, []);

  const onUploadImageClickHandler = useCallback((basketItemImage) => {
    setCurrentBasketItemImage(basketItemImage);
    setIsImageViewModalOpen(true);
  }, []);


  return (
    <div className='pt-3'>
      {basket?.map((x) => (
        <div className='basket-item'>
          {x.product && (
            <BasketItem
              item={x}
              onImageFilePickHandler={onImageFilePickHandler}
              onUploadImageClickHandler={onUploadImageClickHandler}
              onProductClickCallback={onProductClickCallback}
            />
          )}
        </div>
      ))}
      <ImageViewModal
        isOpen={isImageViewModalOpen}
        imageUrl={currentBasketItemImage?.fileUrl}
        onModalCloseClick={onImageViewModalCloseClickHandler}
      />
      <ImageEditModal
        isOpen={isImageEditModalOpen}
        imagePath={currentUploadImage?.filePath}
        width={currentUploadImage?.width}
        height={currentUploadImage?.height}
        onModalCloseClick={onImageEditModalCloseClickHandler}
        onImagePickComplete={onImageFilePickCompleteHandler}
      />
    </div>
  );
};

export default BasketItems;
