import { SET_LOGIN_REF } from "./elementActionTypes";

/* eslint-disable import/no-anonymous-default-export */
export const initialState = {
  loginRef: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOGIN_REF:
      return { ...state, loginRef: payload};

    default:
      return state;
  }
};
