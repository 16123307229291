import React, { useMemo } from "react";
import ProductCard from "./ProductCard";
import _ from "lodash";

export default function Products({ products }) {
  const productsRendered = useMemo(
    () =>
      products?.map((product) => (
        <ProductCard
          productId={product.id}
          productTitle={product.name}
          productOldPrice={product.discountPrice?.toFixed(2)}
          productPrice={product.price.toFixed(2)}
          productImage={_.first(product.imageUrls)?.imageUrl}
          productFavoriteId={product.favoriteId}
          className={"col-12 col-md-6 col-lg-4"}
          productUrlName={product.urlName}
          soldOut={!product.isStockAvailable}
          hasImageUpload={product.uploadImages?.length > 0}
          hasVariants={product.hasVariants}
        />
      )),
    [products]
  );

  return (<div className='row'>{productsRendered}</div>);
}
