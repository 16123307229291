import * as userActions from "../store/user/userActions";
import * as basketActions from "../store/basket/basketActions";
import * as productActions from "../store/product/productActions";
import { makeApiRequest } from "../api";

export const makeLoginRequest = async (
  userDispatch,
  basketDispatch,
  productDispatch,
  { email, password, basketItems = null }
) => {
  try {
    const response = await makeApiRequest("/users/auth", "POST", {
      email,
      password,
      basketItems,
    });

    if (response?.success === true) {
      localStorage.removeItem("basket");
      userDispatch(userActions.loginAction(response));
      basketDispatch(
        basketActions.getBasketAction({
          basket: null,
          itemCount: response.basketItemCount,
        })
      );
      userDispatch(userActions.getSpecialDaysAction(response));
      productDispatch(productActions.getFavoriteProductsAction(response));
    }

    return response;
  } catch (e) {
    return e;
  }
};

export const makeGetSessionRequest = async (
  userDispatch,
  basketDispatch,
) => {
  try {
    const response = await makeApiRequest("/users/auth", "GET");

    if (response?.success === true) {
      userDispatch(userActions.loginAction(response));
    } else {
      userDispatch(userActions.logoutAction());
      basketDispatch(basketActions.clearBasketAction());
    }

    return response;
  } catch (e) {
    userDispatch(userActions.logoutAction());
    basketDispatch(basketActions.clearBasketAction());
    return e;
  }
};

export const makeLogoutRequest = async (userDispatch, basketDispatch) => {
  try {
    const response = await makeApiRequest("/users/current/logout", "POST");
    userDispatch(userActions.logoutAction());
    basketDispatch(basketActions.clearBasketAction());

    return response;
  } catch (e) {
    return e;
  }
};

export const makeInsertUserRequest = async (data) => {
  try {
    const response = await makeApiRequest("/users", "POST", {
      ...data,
    });

    return response;
  } catch (e) {
    return e;
  }
};

export const makeUpdateUserRequest = async (userDispatch, data) => {
  try {
    const response = await makeApiRequest(
      "/users/current",
      "PUT",
      {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        birthday: data.birthday,
      },
      true
    );

    if (response?.success) userDispatch(userActions.updateUserAction(response));

    return response;
  } catch (e) {
    return e;
  }
};

export const makeGetCurrentUserRequest = async () => {
  try {
    const response = await makeApiRequest("/users/current", "GET", null, true);

    return response;
  } catch (e) {
    return e;
  }
};

export const makeChangePasswordRequest = async (data) => {
  try {
    const response = await makeApiRequest(
      "/users/current/changepassword",
      "PATCH",
      {
        ...data,
      },
      true
    );

    return response;
  } catch (e) {
    return e;
  }
};

export const makeGetUserAddressesRequest = async (dispatch) => {
  try {
    const response = await makeApiRequest(
      "/users/current/addresses",
      "GET",
      null,
      true
    );
    dispatch(userActions.getUserAddressesAction(response));

    return response;
  } catch (e) {
    return e;
  }
};

export const makeInsertUserAddressRequest = async (data) => {
  try {
    const response = await makeApiRequest(
      "/users/current/addresses",
      "POST",
      data,
      true
    );

    return response;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const makeUpdateUserAddressRequest = async (data) => {
  try {
    const response = await makeApiRequest(
      "/users/current/addresses",
      "PUT",
      data,
      true
    );

    return response;
  } catch (e) {
    return e;
  }
};

export const makeDeleteUserAddressRequest = async (id) => {
  try {
    const response = await makeApiRequest(
      "/users/current/addresses",
      "DELETE",
      { userAddressId: id },
      true
    );

    return response;
  } catch (e) {
    return e;
  }
};

export const makeGetSpecialDaysRequest = async (dispatch) => {
  try {
    const response = await makeApiRequest(
      "/users/current/specialdays",
      "GET",
      null,
      true
    );
    dispatch(userActions.getSpecialDaysAction(response));

    return response;
  } catch (e) {
    return e;
  }
};

export const makeInsertSpecialDayRequest = async (data) => {
  try {
    const response = await makeApiRequest(
      "/users/current/specialdays",
      "POST",
      data,
      true
    );

    return response;
  } catch (e) {
    return e;
  }
};

export const makeUpdateSpecialDayRequest = async (data) => {
  try {
    const response = await makeApiRequest(
      "/users/current/specialdays",
      "PUT",
      data,
      true
    );

    return response;
  } catch (e) {
    return e;
  }
};

export const makeDeleteSpecialDayRequest = async (id) => {
  try {
    const response = await makeApiRequest(
      "/users/current/specialdays",
      "DELETE",
      { specialDayId: id },
      true
    );

    return response;
  } catch (e) {
    return e;
  }
};

export const makeVerifyUserRequest = async (
  userDispatch,
  basketDispatch,
  productDispatch,
  { data, basketItems = null }
) => {
  try {
    const response = await makeApiRequest("/users/verify", "PUT", {
      data,
      basketItems,
    });

    if (response?.success === true) {
      localStorage.removeItem("basket");
      userDispatch(userActions.loginAction(response));
      basketDispatch(
        basketActions.getBasketAction({
          basket: null,
          itemCount: response.basketItemCount,
        })
      );
      userDispatch(userActions.getSpecialDaysAction(response));
      productDispatch(productActions.getFavoriteProductsAction(response));
    }

    return response;
  } catch (e) {
    return e;
  }
};

export const makeForgotPasswordRequest = async (data) => {
  try {
    const response = await makeApiRequest(
      "/users/forgotpassword",
      "POST",
      data
    );

    return response;
  } catch (e) {
    return e;
  }
};

export const makeResetPasswordRequest = async (data) => {
  try {
    const response = await makeApiRequest(
      "/users/resetpassword",
      "PATCH",
      data
    );

    return response;
  } catch (e) {
    return e;
  }
};
