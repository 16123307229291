/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  Suspense,
  useRef,
} from "react";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink as NavItemLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
} from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import "./NavMenu.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faHeart,
  faShoppingBasket,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { useUserStore } from "../store/user/userContext";
import { switchLanguageAction } from "../store/user/userActions";
import "react-calendar/dist/Calendar.css";
import { useBasketStore } from "../store/basket/basketContext";
import { useProductStore } from "../store/product/productContext";
import _ from "lodash";
import SearchBar from "./SearchBar";
import { useNavigate } from "react-router-dom";
import { makeLogoutRequest } from "../services/userService";
import {
  BrowserView,
  MobileView,
  isMobile,
  MobileOnlyView,
  isMobileOnly,
} from "react-device-detect";
import $ from "jquery";
import { useElementStore } from "../store/element/elementContext";
import ShoppingCart from "./ShoppingCart";

export function NavMenu() {
  const [{ user, specialDays, language }, userDispatch] = useUserStore();
  const [{ itemCount }, basketDispatch] = useBasketStore();
  const [{ mainCategories }] = useProductStore();
  const [{ loginRef }] = useElementStore();
  const { t, i18n } = useTranslation();
  const [menuCollapsed, setMenuCollapsed] = useState(true);
  const [categoriesCollapsed, setCategoriesCollapsed] = useState(false);
  const navigate = useNavigate();
  const refShoppingCart = useRef();
  const Calendar = React.lazy(() => import("react-calendar"));

  const CalendarMemo = useMemo(() => {
    if (isMobile || !specialDays || specialDays.length === 0) {
      return <></>;
    } else {
      return (
        <Calendar
          locale={language}
          maxDate={
            new Date(
              new Date().getFullYear() + 1,
              new Date().getMonth(),
              new Date().getDate()
            )
          }
          minDate={new Date(new Date().setMonth(new Date().getMonth() - 2))}
          tileContent={({ date, view }) => {
            if (view !== "month") return null;
            let item = specialDays
              ?.map((x) => {
                const d = new Date(`${date.getFullYear()}-${x.month}-${x.day}`);
                return {
                  ...x,
                  day: d.getDate(),
                  month: d.getMonth() + 1,
                };
              })
              .find(
                (x) =>
                  x.day === date.getDate() && x.month === date.getMonth() + 1
              );
            if (!item) return null;

            return (
              <div
                style={{
                  fontSize: 9,
                  whiteSpace: "break-spaces",
                  lineHeight: 1,
                }}
              >
                {item.name}
              </div>
            );
          }}
        />
      );
    }
  }, [specialDays, isMobile]);

  const environment = useMemo(() => {
    if (process.env.REACT_APP_ENV !== "production") {
      return (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            height: 30,
            backgroundColor: "lightpink",
          }}
        >
          <span className='text-uppercase'>{process.env.REACT_APP_ENV}</span>-
          <span>{process.env.REACT_APP_VERSION}</span>
        </div>
      );
    } else {
      return <></>;
    }
  }, [process.env.REACT_APP_ENV]);

  const toggleNavbar = () => {
    setMenuCollapsed((x) => !x);
  };

  const onLoginClickHandler = () => {
    loginRef.current.open();
  };

  const onMenuLinkClickHandler = useCallback(
    (e, url) => {
      if (user) {
        navigate(url);
      } else {
        e.preventDefault();
        loginRef.current.open(url, window.location.pathname);
      }
    },
    [user, loginRef]
  );

  const onLogoutClickHandler = useCallback(async () => {
    const response = await makeLogoutRequest(userDispatch, basketDispatch);
    if (response?.success === true) {
      navigate("/");
    }
  }, []);

  const onSwitchLanguageClickHandler = useCallback((lng) => {
    userDispatch(switchLanguageAction(lng));
    i18n.changeLanguage(lng);
  }, []);

  const isLinkActive = useCallback(
    ({ isActive }) =>
      isActive ? "dropdown-item-active no-underlined" : "no-underlined",
    []
  );

  const onBasketClickHandler = useCallback(() => {
    refShoppingCart.current.open();
  }, []);

  useEffect(() => {
    if (window.innerWidth < 768) {
      $(function () {
        $(document).on("click", "*[data-toggle]", null, function () {
          setMenuCollapsed(true);
        });
      });
    }
  }, []);

  return (
    <>
      <header>
        <Navbar
          className='navbar-expand-md navbar-toggleable-md ng-white border-bottom'
          container
          light
          fixed='top'
          id='nav-main'
        >
          <NavbarBrand className='m-0' data-toggle='collapse' tag={Link} to='/'>
            <div
              className='logo'
              style={{ backgroundImage: 'url("/images/logo.png")' }}
            ></div>
            {environment}
          </NavbarBrand>
          <MobileOnlyView>
            <Nav className='h3'>
              <NavItem>
                <NavItemLink className='text-dark'>
                  <Link
                    to={"/profile"}
                    onClick={(e) => onMenuLinkClickHandler(e, "/")}
                  >
                    <FontAwesomeIcon icon={faUser} />
                  </Link>
                </NavItemLink>
              </NavItem>
              <NavItem>
                <NavItemLink className='text-dark'>
                  <Link
                    to={"/favorites"}
                    onClick={(e) => onMenuLinkClickHandler(e, "/favorites")}
                  >
                    <FontAwesomeIcon icon={faHeart} />
                  </Link>
                </NavItemLink>
              </NavItem>
              <NavItem>
                <NavItemLink className='text-dark'>
                  <Link onClick={onBasketClickHandler}>
                    <div className='button d-inline' style={{ paddingLeft: 0 }}>
                      <FontAwesomeIcon icon={faShoppingBasket} />
                      <span class='button__badge'>{itemCount}</span>
                    </div>
                  </Link>
                </NavItemLink>
              </NavItem>
              <NavItem>
                <NavbarToggler onClick={toggleNavbar} className='mr-2' />
              </NavItem>
            </Nav>
          </MobileOnlyView>

          <Collapse
            className='d-md-inline-flex flex-md-row-reverse'
            isOpen={!menuCollapsed}
            navbar
          >
            {!menuCollapsed && (
              <style>
                {`body {
            overflow: hidden
          }`}
              </style>
            )}
            <Nav
              className='me-auto col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-sm-10 align-items-center'
              navbar
            >
              <NavItem className='d-none d-md-block col-sm-9 text-start'>
                <SearchBar id={"search"} />
              </NavItem>
              {!user && (
                <NavItem
                  className={
                    "d-none col-sm-1 col-md-1 col-lg-1 col-xl-1 d-sm-block text-center text-nowrap align-content-center"
                  }
                >
                  <NavItemLink
                    className='text-dark d-inline-flex flex-column align-items-center'
                    style={{ cursor: "pointer" }}
                    onClick={onLoginClickHandler}
                  >
                    <FontAwesomeIcon icon={faUser} />
                    <span className='d-inline d-md-none d-xl-inline font-size-0-9'>
                      {t("Login")}
                    </span>
                  </NavItemLink>
                </NavItem>
              )}
              {user && (
                <NavItem
                  className={
                    "col-12 col-md-1 col-lg-1 col-xl-1 text-sm-start text-md-center text-xl-end text-nowrap"
                  }
                >
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle
                      nav
                      className='text-dark overflow-hidden text-truncate d-lg-flex flex-column align-items-center'
                    >
                      <FontAwesomeIcon icon={faUser} />
                      <span
                        className={`d-inline d-md-none d-xl-inline no-underlined ${
                          isMobile ? "h4" : "font-size-0-9"
                        }`}
                      >
                        Mig
                      </span>
                    </DropdownToggle>
                    <DropdownMenu className='right-0 left-unset'>
                      <div className='d-flex'>
                        <div>
                          <DropdownItem>
                            <NavLink
                              data-toggle='collapse'
                              className={({ isActive }) =>
                                `${isLinkActive({
                                  isActive,
                                })} ${isMobileOnly ? "h4" : ""}`
                              }
                              to='/orders'
                            >
                              {t("My Orders")}
                            </NavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <NavLink
                              data-toggle='collapse'
                              className={({ isActive }) =>
                                `${isLinkActive({
                                  isActive,
                                })} ${isMobileOnly ? "h4" : ""}`
                              }
                              to={"/profile"}
                            >
                              {t("My Profile")}
                            </NavLink>
                          </DropdownItem>
                          <DropdownItem>
                            <NavLink
                              className={({ isActive }) =>
                                `${isLinkActive({
                                  isActive,
                                })} ${isMobileOnly ? "h4" : ""}`
                              }
                              to={"/specialdays"}
                              data-toggle='collapse'
                            >
                              {t("Special Days")}
                            </NavLink>
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem
                            data-toggle='collapse'
                            onClick={onLogoutClickHandler}
                          >
                            {t("Sign Out")}
                          </DropdownItem>
                        </div>
                        <div
                          className='d-none d-xxl-block'
                          onClick={(e) => e.preventDefault()}
                        >
                          <Suspense>{CalendarMemo}</Suspense>
                        </div>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavItem>
              )}

              <NavItem
                className={
                  "d-none col-sm-1 col-md-1 col-lg-1 col-xl-1 d-md-block text-center text-nowrap align-content-center"
                }
              >
                <NavItemLink
                  onClick={(e) => onMenuLinkClickHandler(e, "/favorites")}
                  className='text-dark d-inline-flex flex-column align-items-center'
                  role='button'
                >
                  <FontAwesomeIcon icon={faHeart} />
                  <span className='d-inline d-md-none d-xl-inline font-size-0-9'>
                    {t("Favourites")}
                  </span>
                </NavItemLink>
              </NavItem>
              <NavItem
                className={
                  "d-none col-sm-1 col-md-1 col-lg-1 col-xl-1 d-sm-block text-center text-nowrap align-content-center"
                }
              >
                <NavItemLink
                  onClick={onBasketClickHandler}
                  className='text-dark d-inline-flex flex-column align-items-center'
                  role='button'
                >
                  <div
                    className='button d-inline-flex pb-0'
                    style={{ paddingLeft: 0 }}
                  >
                    <FontAwesomeIcon icon={faShoppingBasket} />
                    <span class='button__badge'>{itemCount}</span>
                  </div>
                  <span className='d-inline d-md-none d-xl-inline font-size-0-9'>
                    {t("Basket")}
                  </span>
                </NavItemLink>
              </NavItem>
              <MobileView className='w-100'>
                <NavItem className='d-block d-md-none'>
                  <Navbar
                    className='col-12 d-flex'
                    container={null}
                    onClick={() => setCategoriesCollapsed(!categoriesCollapsed)}
                  >
                    <div
                      className='col-12 d-inline dropdown-toggle'
                      role='button'
                    >
                      <FontAwesomeIcon icon={faList} />
                      <span
                        className='h4 text-uppercase'
                        style={{ marginLeft: 10 }}
                      >
                        {"Kategorier"}
                      </span>
                    </div>

                    <Collapse
                      isOpen={!categoriesCollapsed}
                      className='col-12 mt-2'
                    >
                      <Nav className='col-12 list-group'>
                        {mainCategories?.map((x) => (
                          <NavItem
                            className='list-group-item'
                            data-toggle='collapse'
                            role='button'
                          >
                            <NavItemLink className='text-uppercase'>
                              <NavLink
                                className={({ isActive }) =>
                                  `${isLinkActive({
                                    isActive,
                                  })} h4 no-underlined`
                                }
                                to={`/category/${x.urlName}/products`}
                              >
                                {x.name}
                              </NavLink>
                            </NavItemLink>
                          </NavItem>
                        ))}
                      </Nav>
                    </Collapse>
                  </Navbar>
                </NavItem>
              </MobileView>
            </Nav>
          </Collapse>
        </Navbar>
        <Navbar
          id='navbar-main-categories'
          container
          className='ng-white border-bottom box-shadow d-none d-md-flex'
          fixed='top'
          light
        >
          <Nav className='parent-category-container me-auto col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            {mainCategories?.map((x) => (
              <NavItem>
                <NavItemLink className='fw-bold text-uppercase px-0 px-xl-5'>
                  <NavLink
                    className={isLinkActive}
                    to={`/category/${x.urlName}/products`}
                  >
                    {x.name}
                  </NavLink>
                </NavItemLink>
              </NavItem>
            ))}
          </Nav>
        </Navbar>

        <MobileView>
          <Navbar
            container
            className='ng-white border-bottom box-shadow d-flex d-md-none'
            fixed='top'
            style={{ top: 72, zIndex: 10010 }}
          >
            <Nav className='col-12'>
              <NavItem className='col-12'>
                <SearchBar id={"search-mobile"} />
              </NavItem>
            </Nav>
          </Navbar>
        </MobileView>
        <ShoppingCart ref={refShoppingCart} />
      </header>
    </>
  );
}
