/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useCallback, useEffect } from "react";
import { Input } from "reactstrap";
import { makeSearchRequest } from "../services/searchService";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
import { toDiscountPercentage } from "../utility/convert";

export default function SearchBar({ id }) {
  const [searchText, setSearchText] = useState(null);
  const [searchResult, setSearchResult] = useState(null);
  const [searchAction, setSearchAction] = useState(null);
  const [closeKeyboardAction, setCloseKeyboardAction] = useState(null);
  const refSearchInput = useRef();
  const refSearchResult = useRef();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSearchTextChangeHandler = useCallback(
    async (e) => {
      setSearchText(e.target.value);
      if (searchAction) {
        clearTimeout(searchAction);
        setSearchAction(null);
      }
      if (closeKeyboardAction) {
        clearTimeout(closeKeyboardAction);
        setCloseKeyboardAction(null);
      }

      if (e.target.value?.length > 1) {
        const actionId = setTimeout(async () => {
          const response = await makeSearchRequest(e.target.value);
          setSearchResult(response?.userSearchResults);
          setSearchAction(null);

          if (isMobile) {
            const closeKeyboardActionId = setTimeout(() => {
              setCloseKeyboardAction(null);
              if (response?.userSearchResults?.length > 0)
                $(e.target).trigger("blur");
            }, 2500);
            setCloseKeyboardAction(closeKeyboardActionId);
          }
        }, 1000);
        setSearchAction(actionId);
      } else {
        setSearchResult(null);
        setSearchAction(null);
      }
    },
    [searchAction, closeKeyboardAction, refSearchInput]
  );

  const onSearchTextKeyDownHandler = useCallback(
    (e) => {
      //if pressed escape key clear search text
      if (e.keyCode === 27) {
        e.preventDefault();
        if (searchAction) {
          clearTimeout(searchAction);
          setSearchAction(null);
        }
        setSearchResult(null);
        setSearchText("");
        return;
      }
    },
    [searchAction]
  );

  const onSearchTextFocusHandler = useCallback(async (e) => {
    if (e.target.value?.length < 2) return;

    const response = await makeSearchRequest(e.target.value);
    setSearchResult(response?.userSearchResults);
  }, []);

  const onSearchResultClickHandler = useCallback((url) => {
    setSearchText("");
    navigate(url);
  }, []);

  const onClearSearchTextClearHandler = useCallback(() => {
    setSearchText("");
  }, []);

  window.addEventListener("click", function (e) {
    if (!searchResult) return;

    if (!document.getElementById(id)?.contains(e.target)) {
      setSearchResult(null);
    }
  });

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      $(refSearchResult.current).width(
        _.first(_.first(entries)?.borderBoxSize)?.inlineSize ?? 500
      );
    });
    observer.observe(_.first($(`#${id}`)));
  }, [id]);

  return (
    <>
      <div className='form-group d-inline-flex w-100 position-relative'>
        <Input
          id={id}
          ref={refSearchInput}
          type='search'
          className='navbar-search'
          placeholder={t("Search product or category")}
          value={searchText}
          onChange={onSearchTextChangeHandler}
          onKeyDown={onSearchTextKeyDownHandler}
          onFocus={onSearchTextFocusHandler}
          maxLength={32}
          autoComplete='off'
        ></Input>
        {searchText?.length > 0 && (
          <FontAwesomeIcon
            onClick={onClearSearchTextClearHandler}
            style={{
              right: 10,
              position: "absolute",
              alignSelf: "center",
              fontSize: 20,
              cursor: "pointer",
              zIndex: 1001,
            }}
            icon={faTimes}
          />
        )}
      </div>
      <div
        id={`${id}-result-container`}
        ref={refSearchResult}
        className='search-result-container mt-0'
      >
        {searchResult?.length === 0 && (
          <div className='card my-0 px-1 py-2'>
            <span>{`${t("No results for")} "${searchText}"`}</span>
          </div>
        )}
        {searchResult?.length > 0 && (
          <>
            <style>
              {`body {
            overflow-y: hidden;
            position: fixed;
            width: 100%;
          }
          .search-result-container::after{
            content:'';
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: #000;
            opacity: 0.4;
            top: 0;
            left: 0;
            z-index: 998;
          }`}
            </style>
            {searchResult.map((x) => (
              <Link
                to={`/product/${x.urlName}`}
                title={x.name}
              >
                <div className='card search-result mt-0'>
                  <div
                    style={{
                      maxWidth: 115,
                      backgroundImage: `url("${x.imageUrl}_small.webp")`,
                      backgroundSize: "cover",
                      aspectRatio: 1,
                      backgroundPosition: "center",
                    }}
                    className='col-3 col-sm-4 col-md-3 col-lg-3 col-xl-2 search-result-content-left'
                  ></div>
                  <div className='col-9 col-sm-8 col-md-9 col-lg-9 col-xl-10 search-result-content-right d-inline-flex flex-column gap-1 justify-content-between'>
                    <span className='fw-bold font-size-1-3 py-1'>{x.name}</span>
                    <span className="py-1 font-size-0-9">{x.description}</span>
                    <div className='d-inline-flex justify-content-between py-1'>
                      {x.discountPrice != null ? (
                        <>
                          <span className='fw-bold text-color-alert font-size-1'>
                            {x.discountPrice.toFixed(2)} kr
                          </span>
                          <div className='d-inline-flex'>
                            <span className='p-0 font-size-0-8 opacity-0-6'>Ord.pris</span>
                            <span className='text-decoration-line-through font-size-0-8 opacity-0-6'>
                              {x.price.toFixed(2)} kr
                            </span>
                            <span className='text-color-alert font-size-0-8 p-0'>{`(-${toDiscountPercentage(
                              x.price,
                              x.discountPrice
                            )}%)`}</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <span className='fw-bold font-size-1'>
                            {x.price.toFixed(2)} kr
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </>
        )}
      </div>
    </>
  );
}
