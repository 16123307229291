/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  CloseButton,
  ModalFooter,
} from "reactstrap";
import { Tooltip } from "reactstrap";
import { toast } from "react-toastify";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../utility/canvasUtils";

export default function ImageEditModal({
  imagePath,
  isOpen,
  width,
  height,
  onModalCloseClick,
  onImagePickComplete,
}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [completedCrop, setCompletedCrop] = useState();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const cropperRef = useRef();
  const hiddenAnchorRef = useRef();

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCompletedCrop(croppedAreaPixels);
  };

  const onOkClickHandler = async () => {
    if (!completedCrop) {
      toast.warn("Beskär bilden för att fortsätta");
      return;
    }
    const croppedImage = await getCroppedImg(
      imagePath,
      completedCrop,
      rotation
    );

    onImagePickComplete(croppedImage);
  };

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Modal zIndex={10050} isOpen={isOpen} fullscreen>
      <ModalHeader
        close={
          <CloseButton
            onClick={() => {
              if (onModalCloseClick) {
                onModalCloseClick();
              }
            }}
          />
        }
      ></ModalHeader>
      <ModalBody
        id='imgModalBody'
        style={{ overflowY: "hidden", paddingTop: 5 }}
      >
        <Cropper
          ref={cropperRef}
          image={imagePath}
          zoom={scale}
          onZoomChange={setScale}
          aspect={width / height}
          crop={crop}
          onCropChange={setCrop}
          rotation={rotation}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onMediaLoaded={() => {
            setCrop({ x: 0, y: 0 });
            setScale(1);
            setRotation(0);
            setCompletedCrop(null);
          }}
        />
      </ModalBody>
      <ModalFooter className='px-5'>
        <div className='row col-12'>
          <div className='controls col-12 pb-4 d-flex flex-wrap col-lg-10 pb-lg-0 justify-content-center'>
            <div className='col-12 py-2 col-lg-6 py-lg-0 px-lg-1'>
              <label htmlFor='scale'>Zoom</label>
              <input
                id='scale'
                type='range'
                value={scale}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby='Zoom'
                onChange={(e) => {
                  setScale(e.target.value);
                }}
                className='zoom-range'
              />
            </div>
            <div className='col-12 py-2 col-lg-6 py-lg-0 px-lg-1'>
              <label htmlFor='rotate'>Rotate</label>
              <input
                id='rotate'
                type='range'
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby='Rotate'
                onChange={(e) => {
                  setRotation(e.target.value);
                }}
                className='zoom-range'
              />
            </div>
          </div>
          <div className='col-12 d-flex justify-content-end gap-1 col-lg-2'>
            <Tooltip
              autohide
              flip
              isOpen={tooltipOpen && !completedCrop}
              target='btnOk'
              toggle={toggle}
              placement='bottom-start'
            >
              Beskär bilden för att fortsätta
            </Tooltip>
            <button
              id='btnOk'
              className='btn btn-primary'
              style={{ zIndex: 9999, minWidth: 100 }}
              onClick={onOkClickHandler}
            >
              Ok
            </button>
            <button
              className='btn btn-danger'
              style={{ zIndex: 9999, minWidth: 100 }}
              onClick={onModalCloseClick}
            >
              Cancel
            </button>
            <a
              rel="nofollow"
              href='#hidden'
              ref={hiddenAnchorRef}
              download
              style={{
                position: "absolute",
                top: "-200vh",
                visibility: "hidden",
              }}
            >
              Hidden download
            </a>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
}