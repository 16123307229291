import React, { useReducer } from 'react';
import productStore, { initialState } from './productStore';

export const ProductContext = React.createContext();

export const useProductStore = () => React.useContext(ProductContext);

export default function ProductProvider(props) {
    const [state, dispatch] = useReducer(productStore, initialState);
    return <ProductContext.Provider value={[state, dispatch]}>{props.children}</ProductContext.Provider>;
}