import * as actionTypes from "./productActionTypes";

export const getProductsAction = (payload) => ({
  type: actionTypes.GET_PRODUCTS,
  payload,
});

export const getMainCategoriesAction = (payload) => ({
  type: actionTypes.GET_MAIN_CATEGORIES,
  payload,
});

export const getFavoriteProductsAction = (payload) => ({
  type: actionTypes.GET_FAVORITE_PRODUCTS,
  payload,
});

export const insertFavoriteProductAction = (payload) => ({
  type: actionTypes.INSERT_FAVORITE_PRODUCT,
  payload: { ...payload.favoriteProduct },
});

export const deleteFavoriteProductAction = (id) => ({
  type: actionTypes.DELETE_FAVORITE_PRODUCT,
  payload: { id: id },
});

export const reloadFavoriteProductsAction = () => {
  const strFavoriteProducts = localStorage.getItem("favoriteProducts");
  const favoriteProducts = JSON.parse(strFavoriteProducts ?? '[]');
  return {
    type: actionTypes.RELOAD_FAVORITE_PRODUCTS,
    payload: { favoriteProducts },
  };
};
