import { makeApiRequest } from "../api";
import {
  deleteFavoriteProductAction,
  getProductsAction,
  insertFavoriteProductAction,
  getFavoriteProductsAction,
  getMainCategoriesAction,
} from "../store/product/productActions";
import { toJSON } from "../utility/convert";

export const makeGetProductsRequest = async (dispatch) => {
  try {
    const response = await makeApiRequest("/products/main", "GET", null, false);
    dispatch(getProductsAction(response));

    return response;
  } catch (e) {
    return e;
  }
};

export const makeGetProductByIdRequest = async (id, variant = null) => {
  try {
    const response = await makeApiRequest(
      `/products/${id}${variant ? `?variant=${variant}` : ""}`,
      "GET",
      null,
      false
    );

    return response;
  } catch (e) {
    return e;
  }
};

export const makeGetMainCategoriesRequest = async (dispatch) => {
  try {
    const response = await makeApiRequest(`/categories`, "GET", null, false);
    dispatch(getMainCategoriesAction(response));

    return response;
  } catch (e) {
    return e;
  }
};

export const makeGetProductByMainCategoryRequest = async (urlName, sort = null) => {
  try {
    const response = await makeApiRequest(
      `/categories/${urlName}/products${sort ? `?sort=${sort}` : ""}`,
      "GET",
      null,
      false
    );

    return response;
  } catch (e) {
    return e;
  }
};

export const makeGetFavoriteProductsRequest = async (dispatch) => {
  try {
    const response = await makeApiRequest(
      "/products/favorites",
      "GET",
      null,
      true
    );
    dispatch(
      getFavoriteProductsAction({
        favoriteProducts: response?.favoriteProducts?.map((x) => x.id),
      })
    );

    return response;
  } catch (e) {
    return e;
  }
};

export const makeInsertFavoriteProductRequest = async (dispatch, data) => {
  try {
    const response = await makeApiRequest(
      "/products/favorites",
      "POST",
      data,
      true
    );
    dispatch(insertFavoriteProductAction(response));

    return response;
  } catch (e) {
    return e;
  }
};

export const makeDeleteFavoriteProductRequest = async (dispatch, id) => {
  try {
    const response = await makeApiRequest(
      "/products/favorites/" + id,
      "DELETE",
      null,
      true
    );
    dispatch(deleteFavoriteProductAction(id));

    return response;
  } catch (e) {
    return e;
  }
};

export const makeProductVisitLogRequest = async (urlName) => {
  try {
    const visitedProducts = toJSON(
      sessionStorage.getItem("visitedProducts"),
      []
    );

    if (!visitedProducts.includes(urlName)) {
      const response = await makeApiRequest(
        `/products/${urlName}/visit`,
        "PATCH",
        null,
        false
      );

      if (response?.success === true) {
        visitedProducts.push(urlName);
        sessionStorage.setItem(
          "visitedProducts",
          JSON.stringify(visitedProducts)
        );
      }

      return response;
    }

    return null;
  } catch (e) {
    return e;
  }
};
