import React, { useEffect, useState } from "react";

const ImageContainer = (props) => {
  const [src, setSrc] = useState(null);
  const [className, setClassName] = useState("");

  useEffect(() => {
    if (!props.src) {
      return;
    }
    const imageLoader = new Image();
    imageLoader.src = props.src;

    imageLoader.decode().then(() => {
      setSrc(props.src);
    })
    .catch(() => {
      setSrc(require("../images/img-error.png"));
      setClassName(" img-error");
    });
  }, [props.src]);

  return (
    <div
      {...props}
      className={`img-container${className}`}
      style={{
        ...props.style,
        background: src ? "url('" + src + "') no-repeat center center" : "",
        backgroundColor: "#f5f5f5",
      }}
      title={props.alt}
    />
  );
};

export default React.memo(ImageContainer);
