import React, { useReducer } from 'react';
import userStore, { initialState } from './userStore';

export const UserContext = React.createContext();

export const useUserStore = () => React.useContext(UserContext);

export default function UserProvider(props) {
    const [state, dispatch] = useReducer(userStore, initialState);
    return <UserContext.Provider value={[state, dispatch]}>{props.children}</UserContext.Provider>;
}