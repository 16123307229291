/* eslint-disable import/no-anonymous-default-export */
import * as actionTypes from "./productActionTypes";
import _ from "lodash";

export const initialState = {
  productGroups: null,
  categories: null,
  favoriteProducts: [],
  mainCategories: null,
  mainCategoriesWithImage: [{}, {}, {}],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_PRODUCTS:
      const productGroups = {};

      payload?.categories?.forEach((x) => {
        productGroups[x.id] = [];

        x.productIds?.forEach((pId) => {
          let product = payload.products.find((p) => p.id === pId);

          if (product) {
            productGroups[x.id].push(product);
          }
        });
      });

      return {
        ...state,
        productGroups,
        categories: payload.categories,
      };

    case actionTypes.GET_MAIN_CATEGORIES:
      return {
        ...state,
        ...payload,
      };

    case actionTypes.GET_FAVORITE_PRODUCTS:
      localStorage.setItem(
        "favoriteProducts",
        JSON.stringify(payload?.favoriteProducts ?? [])
      );
      return {
        ...state,
        ...payload,
      };

    case actionTypes.INSERT_FAVORITE_PRODUCT:
      const favoriteProductsInternal = _.clone(state.favoriteProducts);
      favoriteProductsInternal.push(payload.productId);
        localStorage.setItem(
          "favoriteProducts",
          JSON.stringify(favoriteProductsInternal));

      return { ...state, favoriteProducts: favoriteProductsInternal};

    case actionTypes.DELETE_FAVORITE_PRODUCT:
      state.favoriteProducts = state.favoriteProducts.filter(x => x !== payload.id);
        localStorage.setItem(
          "favoriteProducts",
          JSON.stringify(state.favoriteProducts));

      return { ...state };

    case actionTypes.RELOAD_FAVORITE_PRODUCTS:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};
