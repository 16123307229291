import React, {
  useCallback,
  useImperativeHandle,
  useState,
  useRef,
} from "react";
import {
  Modal,
  ModalBody,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  CardLink,
  ModalHeader,
  CloseButton,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  makeForgotPasswordRequest,
  makeInsertUserRequest,
  makeLoginRequest,
} from "../services/userService";
import { useUserStore } from "../store/user/userContext";
import { useBasketStore } from "../store/basket/basketContext";
import { toast } from "react-toastify";
import * as validationConsts from "../constants/validationConstants";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useProductStore } from "../store/product/productContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { toJSON } from "../utility/convert";
import $ from "jquery";

const LoginModal = (props, ref) => {
  const [{ language }, userDispatch] = useUserStore();
  const [, basketDispatch] = useBasketStore();
  const [, productDispatch] = useProductStore();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isSignUpVisible, setIsSignUpVisible] = useState(false);
  const [isSignUpSuccessVisible, setIsSignUpSuccessVisible] = useState(false);
  const [isResetPwdVisible, setIsResetPwdVisible] = useState(false);
  const [forgotPwdMail, setForgotPwdMail] = useState(null);
  const [isSignUpButtonEnabled, setIsSignUpButtonEnabled] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [redirectFallbackUrl, setRedirectFallbackUrl] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const refReCaptcha = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();

  const open = (
    redirectUrl = null,
    redirectFallbackUrl = null,
    signUpVisible = false,
    defaultEmailAddress = ""
  ) => {
    setIsSignUpVisible(signUpVisible);
    setIsSignUpSuccessVisible(false);
    setIsOpen(true);
    setRedirectUrl(redirectUrl);
    setRedirectFallbackUrl(redirectFallbackUrl);
    setEmail(defaultEmailAddress);

    if (signUpVisible === false) {
      setTimeout(() => {
        $("#btnLogin").trigger('focus');
      }, 500);
    }
  };

  const close = () => {
    setIsOpen(false);
    if (redirectFallbackUrl)
      navigate(redirectFallbackUrl, { state: new Date().getTime() });
    else if (redirectUrl)
      navigate(redirectUrl, { state: new Date().getTime() });
  };

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  const onLoginClickHandler = async () => {
    const basketItems = toJSON(localStorage.getItem("basket"));
    const response = await makeLoginRequest(
      userDispatch,
      basketDispatch,
      productDispatch,
      {
        email,
        password,
        basketItems,
      }
    );

    if (response.success === true) {
      close();
      if (redirectUrl) navigate(redirectUrl, { state: new Date().getTime() });
    } else toast.warn(t(response.errorMessage));
  };

  const onShowSignUpClickHandler = () => {
    setIsSignUpVisible(true);
  };

  const onSignUpClickHandler = async (data) => {
    const response = await makeInsertUserRequest({
      ...data,
      code: refReCaptcha.current.getValue(),
    });
    if (response.success === true) {
      setIsSignUpSuccessVisible(true);
    } else {
      refReCaptcha.current.reset();
      setIsSignUpButtonEnabled(false);
    }
  };

  const onResetPwdClickHandler = useCallback(async () => {
    const response = await makeForgotPasswordRequest({
      email: forgotPwdMail,
      language,
    });
    setForgotPwdMail(null);
    if (response.success === true) {
      toast.success(t("A link has been sent to your e-mail address"));
      setIsResetPwdVisible(false);
    }
  }, [forgotPwdMail, language]);

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      await onLoginClickHandler();
    }
  };

  return (
    <Modal zIndex={10050}  centered isOpen={isOpen}>
      <ModalHeader
        className='p-2'
        close={<CloseButton onClick={() => close()} />}
      >
        {!isSignUpVisible ? "" : t("Register")}
      </ModalHeader>
      <ModalBody className='px-0 py-1'>
        {!isSignUpVisible && (
          <Container>
            <Row>
              <Col className='px-1'>
                <Card className='my-1'>
                  <CardBody>
                    <FormGroup className='pb-2 mr-sm-2 mb-sm-0'>
                      <Label for='txtEmail' className='mr-sm-2'>
                        {t("E-Mail")}
                      </Label>
                      <Input
                        type='email'
                        name='email'
                        id='txtEmail'
                        onChange={(ev) => setEmail(ev.currentTarget.value)}
                        required
                        onKeyDown={handleKeyDown}
                      />
                    </FormGroup>
                    <FormGroup className='pb-2 mr-sm-2 mb-sm-0'>
                      <Label for='txtPassword' className='mr-sm-2'>
                        {t("Password")}
                      </Label>
                      <Input
                        type='password'
                        name='password'
                        id='txtPassword'
                        onChange={(ev) => setPassword(ev.currentTarget.value)}
                        maxLength={16}
                        required
                        onKeyDown={handleKeyDown}
                      />
                    </FormGroup>
                    <Button
                      id="btnLogin"
                      type='submit'
                      color='primary'
                      onClick={onLoginClickHandler}
                    >
                      {t("Login")}
                    </Button>
                    <CardLink
                      style={{
                        float: "right",
                        cursor: "pointer",
                        marginLeft: 10,
                      }}
                      onClick={() => setIsResetPwdVisible((x) => !x)}
                    >
                      {t("Forgot Password")}
                    </CardLink>
                    <CardLink
                      style={{ float: "right", cursor: "pointer" }}
                      onClick={onShowSignUpClickHandler}
                    >
                      {t("Sign Up")}
                    </CardLink>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
        {isSignUpVisible && (
          <>
            {!isSignUpSuccessVisible && (
              <Form
                autoComplete='off'
                onSubmit={handleSubmit(onSignUpClickHandler)}
              >
                <Container>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='txtEmail'>{t("E-Mail")}</Label>
                        <Input
                          type='email'
                          name='email'
                          id='txtEmail'
                          autoComplete='off'
                          defaultValue={email}
                          {...register("email", {
                            required: {
                              value: true,
                              message: t("E-Mail is required"),
                            },
                            pattern: {
                              value: validationConsts.EMAIL_REGEX,
                              message: t("E-Mail is invalid"),
                            },
                          })}
                          onChange={(x) => setValue("email", x.target.value)}
                        ></Input>
                        {errors.email?.message && (
                          <p class='alert'>{errors.email?.message}</p>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='txtPassword'>{t("Password")}</Label>
                        <Input
                          type='password'
                          name='password'
                          id='txtPassword'
                          autoComplete='off'
                          {...register("password", {
                            required: {
                              value: true,
                              message: t("Password is required"),
                            },
                            minLength: {
                              value: 8,
                              message: t(
                                "Your password must be at least 8 characters long, contain at least one number and have a mixture of uppercase and lowercase letters"
                              ),
                            },
                            maxLength: {
                              value: 16,
                              message: t(
                                "Your password must be maximum 16 characters long, contain at least one number and have a mixture of uppercase and lowercase letters"
                              ),
                            },
                          })}
                          onChange={(x) => setValue("password", x.target.value)}
                        ></Input>
                        {errors.password?.message && (
                          <p class='alert'>{errors.password?.message}</p>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='txtPasswordAgain'>
                          {t("Password Again")}
                        </Label>
                        <Input
                          type='password'
                          name='passwordAgain'
                          id='txtPasswordAgain'
                          autoComplete='off'
                          {...register("passwordAgain", {
                            required: {
                              value: true,
                              message: t("Password again is required"),
                            },

                            validate: (value) => {
                              const { password } = getValues();
                              return (
                                value === password || t("Passwords don't match")
                              );
                            },
                          })}
                          onChange={(x) =>
                            setValue("passwordAgain", x.target.value)
                          }
                        ></Input>
                        {errors.passwordAgain?.message && (
                          <p class='alert'>{errors.passwordAgain?.message}</p>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col></Col>
                    <Col></Col>
                    <Col>
                      <ReCAPTCHA
                        ref={refReCaptcha}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={() => {
                          setIsSignUpButtonEnabled(true);
                        }}
                        onExpired={() => setIsSignUpButtonEnabled(false)}
                        hl={language}
                      />
                    </Col>
                  </Row>
                  <p></p>
                  <Row>
                    <Col></Col>
                    <Col></Col>
                    <Col>
                      {" "}
                      <Button
                        type='submit'
                        disabled={!isSignUpButtonEnabled}
                        className='btn-success btn-register'
                      >
                        {t("Save")}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        className='btn-danger btn-register'
                        onClick={() => close()}
                      >
                        {t("Cancel")}
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Form>
            )}
            {isSignUpSuccessVisible && (
              <div className='w-100'>
                <div className='w-100 d-flex flex-column align-items-center text-center'>
                  <div
                    class='pc-card-success'
                    style={{
                      position: "relative",
                      display: "block",
                      top: "auto",
                      left: "auto",
                    }}
                  >
                    <FontAwesomeIcon style={{ height: "2em" }} icon={faCheck} />
                  </div>
                  <div>
                    <p className='fs-2'>
                      Registrering lyckad. Ett verifieringsmeddelande har
                      skickats.
                    </p>
                    <p className='fs-5'>
                      Vänligen kontrollera din skräppostmapp om du inte får
                      någon
                    </p>
                  </div>
                </div>
                <div className='w-100'>
                  <Button
                    className='btn-primary m-2 float-end'
                    onClick={() => close()}
                  >
                    {t("Close")}
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
        <Modal zIndex={10051} centered isOpen={isResetPwdVisible}>
          <ModalHeader
            close={<CloseButton onClick={() => setIsResetPwdVisible(false)} />}
          >
            <h5>{t("Reset your password")}</h5>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label>{t("E-Mail")}</Label>
                  <Input
                    type='email'
                    autoComplete='off'
                    value={forgotPwdMail}
                    onChange={(x) => setForgotPwdMail(x.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button onClick={onResetPwdClickHandler}>
                  {t("Send Link")}
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </ModalBody>
    </Modal>
  );
};

export default React.forwardRef(LoginModal);
