import React, { useEffect, useState } from "react";
import {
  run,
  acceptedCategory,
  showPreferences,
  eraseCookies,
} from "vanilla-cookieconsent";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import ReactGA from "react-ga4";
import $ from "jquery";
import { CloseButton, Modal, ModalBody, ModalHeader } from "reactstrap";
import CookiePolicy from "./CookiePolicy";
import { NewRelicAgent } from "./NewRelicBrowser";

const CookieConsent = () => {
  const [isOpen, setIsOpen] = useState(false);

  const init = ({ changedCategories = ["analytics", "performance"] }) => {
    if (
      acceptedCategory("analytics") &&
      changedCategories.includes("analytics")
    ) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
        testMode: process.env.REACT_APP_ENV === "development",
        gaOptions: {
          allowAdFeatures: false,
          allowAdPersonalizationSignals: false,
        },
      });
    } else if (!acceptedCategory("analytics")) {
      ReactGA.reset();
      window[`ga-disable-${process.env.REACT_APP_GA_TRACKING_ID}`] = true;
      $('script[src*="gtag/js"]').remove();
    }

    if (
      acceptedCategory("performance") &&
      changedCategories.includes("performance")
    ) {
      NewRelicAgent.use();
    } else if (!acceptedCategory("performance")) {
      NewRelicAgent.destroy();
    }
  };

  const change = ({ changedCategories }) => {
    init(changedCategories);
  };

  useEffect(() => {
    run({
      disablePageInteraction: true,
      revision: 1,
      onConsent: init,
      onChange: change,
      onFirstConsent: () => {
        eraseCookies(/^cookie.*$/); //TODO: Remove this line
      },
      onModalReady: function () {
        $(() => {
          $(document).on("click", ".link-cookie-policy-modal", () => {
            setIsOpen(true);
          });
        });
      },
      guiOptions: {
        consentModal: {
          layout: "box",
          position: "middle center",
          equalWeightButtons: true,
          flipButtons: false,
        },
        preferencesModal: {
          layout: "box",
          position: "middle center",
          equalWeightButtons: true,
          flipButtons: false,
        },
      },
      categories: {
        necessary: {
          readOnly: true,
          enabled: true,
        },
        analytics: {
          autoClear: {
            cookies: [
              {
                name: /^_g.*$/,
                domain: process.env.REACT_APP_GA_COOKIE_DOMAIN,
              },
            ],
            reloadPage: false,
          },
        },
        performance: {
          autoClear: {
            reloadPage: true,
          },
        },
      },
      language: {
        default: "sv",

        translations: {
          sv: {
            consentModal: {
              title: "Vi använder oss av cookies",
              description:
                "<p>Vi använder cookies och andra spårningsteknologier för att förbättra din surfupplevelse på vår webbplats, för att visa dig personligt innehåll och riktade annonser, för att analysera vår webbplatstrafik och för att förstå var våra besökare kommer ifrån.</p>" +
                '<p>Genom att klicka på "Acceptera alla" godkänner du att vi använder cookies och andra spårningsteknologier enligt vår <a class="link-cookie-policy-modal">Cookie Policy</a>. Du kan också välja att justera dina inställningar.</p>',
              acceptAllBtn: "Acceptera alla",
              acceptNecessaryBtn: "Acceptera nödvändiga",
              showPreferencesBtn: "Hantera inställningar",
            },
            preferencesModal: {
              title: "Cookie-preferenser",
              acceptAllBtn: "Acceptera alla",
              acceptNecessaryBtn: "Acceptera nödvändiga",
              savePreferencesBtn: "Spara inställningar",
              closeIconLabel: "Stänga",
              sections: [
                {
                  title: "Cookieanvändning",
                  description:
                    "Vår webbplats använder cookies för att ge dig en bättre användarupplevelse, analysera webbplatstrafik och visa personligt anpassat innehåll och annonser. För mer information om cookies, besök vår <a class='link-cookie-policy-modal'>Cookie Policy</a>.",
                },
                {
                  title: "Nödvändiga Cookies",
                  description:
                    "Dessa cookies är nödvändiga för att vår webbplats ska fungera korrekt. De är obligatoriska och kan inte stängas av i våra system.",
                  linkedCategory: "necessary",
                },
                {
                  title: "Analytics Cookies",
                  linkedCategory: "analytics",
                  cookieTable: {
                    headers: {
                      name: "Namn",
                      domain: "Service",
                      description: "Beskrivning",
                      expiration: "Utgång",
                    },
                    body: [
                      {
                        name: "_ga",
                        domain: "Google Analytics",
                        description: "Används för att skilja mellan användare.",
                        expiration: "2 år",
                      },
                      {
                        name: "_ga_id",
                        domain: "Google Analytics",
                        description:
                          "Används för att bevara sessionens status.",
                        expiration: "2 år",
                      },
                    ],
                  },
                },
                {
                  title: "Prestandadataanvändning",
                  description:
                    "För att förbättra vår webbplats prestanda och användarupplevelse använder vi spårningsverktyg från tredje part, såsom New Relic. Dessa verktyg samlar in anonym data för att övervaka webbplatsens prestanda, identifiera problem och optimera din användarupplevelse. Dessa spårningsverktyg kräver ditt samtycke för att kunna fungera. Utan ditt medgivande kommer de inte att aktiveras. För mer information, vänligen besök vår Integritetspolicy. Om du accepterar prestandaspårning, vänligen markera alternativet 'Prestandadataanvändning'.",
                  linkedCategory: "performance",
                },
                {
                  title: "Mer information",
                  description:
                    "För eventuella frågor angående vår policy för cookies och dina val, vänligen kontakta oss.",
                },
              ],
            },
          },
        },
      },
    });
  }, []);

  return (
    <>
      <Modal
        zIndex={2147483647}
        id="cookie-policy-modal"
        isOpen={isOpen}
        size="lg"
      >
        <ModalHeader>
          <CloseButton onClick={() => setIsOpen(false)} />
        </ModalHeader>
        <ModalBody>
          <CookiePolicy
            onSettingsClick={() => {
              setIsOpen(false);
              showPreferences();
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default CookieConsent;
